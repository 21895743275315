import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  List,
  ListItem,
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import ButtonRightIcon from "../components/buttonRighticon";
const GizlilikSozlesmesi = ({ setState }) => {
  const stateUpdate = () => {
    setState();
  };

  return (
    <Modal size={"xl"} isOpen={true}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "100%", md: "65%" }}
        backgroundColor="#112B29"
        pointerEvents="all">
        <ModalHeader color="#fff" textAlign="center">
          GİZLİLİK SÖZLEŞMESİ
        </ModalHeader>
        <ModalBody px="40px" pointerEvents="all">
          <Box
            maxH="500px"
            px="10px"
            backgroundColor="#153639"
            border="1px"
            borderColor="#01B6A2">
            <Box
              maxH="500px"
              overflow="scroll"
              px="20px"
              py="30px"
              overflowX="hidden !important">
              <Text fontSize={{ base: "12px", md: "16px" }}>
                <p style={{ color: "#fff" }} dir="ltr">
                  Gizlilik Politikası
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Son güncellenme: 08/12/2021
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Güvenliğiniz bizim için önemli. Bu sebeple bizimle
                  paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Biz, Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve Film
                  Yapım A. Ş., veri sorumlusu olarak, bu gizlilik ve kişisel
                  verilerin korunması politikası ile, hangi kişisel
                  verilerinizin hangi amaçla işleneceği, işlenen verilerin
                  kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve
                  hukuki sebeplerimiz ile; işlenen verilerinize ilişkin
                  haklarınızın neler olduğu hususunda sizleri aydınlatmayı
                  amaçlıyoruz.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Toplanan Kişisel Verileriniz, Toplanma Yöntemi ve Hukuki
                  Sebebi
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  IP adresiniz ve kullanıcı aracısı bilgileriniz, sadece analiz
                  yapmak amacıyla ve çerezler (cookies) vb. teknolojiler
                  vasıtasıyla, otomatik veya otomatik olmayan yöntemlerle ve
                  bazen de analitik sağlayıcılar, reklam ağları, arama bilgi
                  sağlayıcıları, teknoloji sağlayıcıları gibi üçüncü taraflardan
                  elde edilerek, kaydedilerek, depolanarak ve güncellenerek,
                  aramızdaki hizmet ve sözleşme ilişkisi çerçevesinde ve
                  süresince, meşru menfaat işleme şartına dayanılarak
                  işlenecektir.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Kişisel Verilerinizin İşlenme Amacı
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak
                  suretiyle; sunduğumuz hizmetlerin gerekliliklerini en iyi
                  şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan
                  ulaşılabilmesini ve maksiumum düzeyde faydalanılabilmesini
                  sağlamak, hizmetlerimizi, ihtiyaçlarınız doğrultusunda
                  geliştirebilmek ve sizleri daha geniş kapsamlı hizmet
                  sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek
                  ve kanundan doğan zorunlulukların (kişisel verilerin talep
                  halinde adli ve idari makamlarla paylaşılması) yerine
                  getirilebilmesi amacıyla, sözleşme ve hizmet süresince,
                  amacına uygun ve ölçülü bir şekilde işlenecek ve
                  güncellenecektir.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Toplanan Kişisel Verilerin Kimlere ve Hangi Amaçlarla
                  Aktarılabileceği
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Bizimle paylaştığınız kişisel verileriniz; faaliyetlerimizi
                  yürütmek üzere hizmet aldığımız ve/veya verdiğimiz,
                  sözleşmesel ilişki içerisinde bulunduğumuz,iş birliği
                  yaptığımız, yurt içi ve yurt dışındaki 3. şahıslar ile kurum
                  ve kuruluşlara ve talep halinde adli ve idari makamlara,
                  gerekli teknik ve idari önlemler alınması koşulu ile
                  aktarılabilecektir.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Kişisel Verileri İşlenen Kişi Olarak Haklarınız
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  KVKK madde 11 uyarınca herkes, veri sorumlusuna başvurarak
                  aşağıdaki haklarını kullanabilir:
                </p>
                <ol>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel veri işlenip işlenmediğini öğrenme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel verilerin işlenme amacını ve bunların amacına
                      uygun kullanılıp kullanılmadığını öğrenme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Yurt içinde veya yurt dışında kişisel verilerin
                      aktarıldığı üçüncü kişileri bilme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel verilerin eksik veya yanlış işlenmiş olması
                      hâlinde bunların düzeltilmesini isteme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel verilerin silinmesini veya yok edilmesini isteme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      (e) ve (f) bentleri uyarınca yapılan işlemlerin, kişisel
                      verilerin aktarıldığı üçüncü kişilere bildirilmesini
                      isteme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      İşlenen verilerin münhasıran otomatik sistemler
                      vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                      aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                      zarara uğraması hâlinde zararın giderilmesini talep etme,
                      haklarına sahiptir.
                    </p>
                  </li>
                </ol>
                <p style={{ color: "#fff" }} dir="ltr">
                  Yukarıda sayılan haklarınızı kullanmak üzere
                  contact@cinegameart.online üzerinden bizimle iletişime
                  geçebilirsiniz.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  İletişim
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Sizlere hizmet sunabilmek amaçlı analizler yapabilmek için,
                  sadece gerekli olan kişisel verilerinizin, işbu gizlilik ve
                  kişisel verilerin işlenmesi politikası uyarınca işlenmesini,
                  kabul edip etmemek hususunda tamamen özgürsünüz. Siteyi
                  kullanmaya devam ettiğiniz takdirde kabul etmiş olduğunuz
                  tarafımızca varsayılacak olup, daha ayrıntılı bilgi için
                  bizimle contact@cinegameart.online e-mail adresi üzerinden
                  iletişime geçmekten lütfen çekinmeyiniz.
                </p>
                <div>
                  <br />
                </div>
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          flexDirection="column"
          pointerEvents="all">
          <Button
            onClick={() => {
              document.getElementsByClassName(
                "chakra-portal"
              )[0].style.display = "none";
              stateUpdate();
            }}
            _hover={{ bg: colors.hover }}
            borderRadius="20px"
            color={colors.text}
            backgroundColor="#121518"
            fontSize="17px"
            textShadow="1px 2px 20px #01B6A2"
            rightIcon={<ButtonRightIcon />}>
            Tamam
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default GizlilikSozlesmesi;
