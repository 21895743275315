import {
  GET_ARTIZT_FAIL,
  GET_ARTIZT_REQUEST,
  GET_ARTIZT_SUCCESS,
  CLEAR_ERRORS,
  GET_ARTIZT_LIST_REQUEST,
  GET_ARTIZT_LIST_SUCCESS,
  GET_ARTIZT_LIST_FAIL,
  CLEAR_ARTIZT_LIST_ERRORS,
  SET_SELECTED_ARTIZT,
  GET_ARTIZT_CATEGORY_REQUEST,
  GET_ARTIZT_CATEGORY_SUCCESS,
  GET_ARTIZT_CATEGORY_FAIL,
  CLEAR_ARTIZT_CATEGORY_ERRORS,
} from "../constants/artiztContants";

const initialState = {
  fetch: null,
  mentors: {},
};
export const artiztReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTIZT_REQUEST:
      return {
        loading: true,
      };

    case GET_ARTIZT_SUCCESS:
      return {
        ...state,
        loading: false,
        artizts: action.payload,
      };

    case GET_ARTIZT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const artiztListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ARTIZT_LIST_REQUEST:
      return {
        loading: true,
      };

    case GET_ARTIZT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        artizts: action.payload,
      };

    case GET_ARTIZT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ARTIZT_LIST_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const artiztCategoryListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ARTIZT_CATEGORY_REQUEST:
      return {
        loading: true,
      };

    case GET_ARTIZT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        artizt_categories: action.payload,
      };

    case GET_ARTIZT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ARTIZT_CATEGORY_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const selArtiztReducer = (state = "", action) => {
  switch (action.type) {
    case SET_SELECTED_ARTIZT:
      return {
        ...state,
        loading: false,
        activeArtizt: action.payload,
      };

    default:
      return state;
  }
};
