const route = {
  homepage: "/anasayfa",
  brands: "/markalarimiz",
  actors: "/oyuncularimiz",
  artizts: "/artiztlerimiz",
  signIn: "/giris",
  verif: "/dogrulama",
  ornekdeneme: "/ornekdeneme",
  faceapi: "/faceapi",
  select: "/secim",
  select2: "/artizt-secim",
  mentor: "/mentor",
  denemecekimi: "/denemecekimi",
  progress: "/yukleme",
  requestForm: "/istek-formu",
  userRecord: "/user-record",
};

export default route;
