import axioss from 'axios'
import { API_BASE, CHAT_API } from "../config/config"
import {
    CLEAR_ERRORS,
    POST_CHAT_FAIL,
    POST_CHAT_REQUEST,
    POST_CHAT_SUCCESS
} from '../constants/chatContants'

const axios = axioss.create({
    // withCredentials: true,
    baseURL: CHAT_API,

})


export const postChat = (formText) => async (dispatch) => {
    try {

        dispatch({ type: POST_CHAT_REQUEST })
        
        const { data } = await axios.post("/api/messages/", { message: formText }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })

        dispatch({
            type: POST_CHAT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: POST_CHAT_FAIL,
            payload: error
        })
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}