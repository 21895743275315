import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import btnimage from "../assets/images/btn.png";
import logo2 from "../assets/images/logo2.png";
import {
  Text,
  Box,
  Image,
  List,
  ChakraProvider,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import font from "../theme/font";
import route from "../constants/route";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertList,
  setSelectedAdvert,
  clearErrors,
} from "../actions/advertAction";
import Card from "../components/card2";
import Slider from "react-slick";

const AdvertSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { advertList, error } = useSelector((state) => state);
  const [loop, setLoop] = useState(true);
  const [infiniteStatus, setInfiniteStatus] = useState(false);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: infiniteStatus ? "block" : "none !important" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ display: infiniteStatus ? "none !important" : "none" }}
        onClick={onClick}
      />
    );
  }

  const settings = {
    className: "center",
    centerMode: true,
    focusOnSelect: true,
    infinite: infiniteStatus,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const settings2 = {
    centerMode: true,
    centerPadding: "10px",
    focusOnSelect: true,
    slidesToShow: 2,
    speed: 500,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    nextArrow: infiniteStatus && <SampleNextArrow />,
    prevArrow: infiniteStatus && <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    console.log('advertlist', advertList)
    if (advertList && advertList.adverts && advertList.adverts.length > 3)
      setInfiniteStatus(true);
  }, [advertList]);

  useEffect(() => {
    if (window.location.pathname == route.homepage) {
      if (loop) {
        const text = () => {
          speech("hoş geldin");
          document.querySelector("html").onclick = () => undefined;
        };
        document.querySelector("html").onclick = () => text();
      } else {
        document.querySelector("html").onclick = () => undefined;
      }
    }
    return () => console.log();
  }, [loop]);

  useEffect(() => {
    dispatch(getAdvertList());
  }, []);


  const selectAuditions = (videoId) => {
    history.push({ pathname: route.denemecekimi, state: { videoId } });
  };


  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  const speech = (text) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);
    const startFunction = () => {
      // document.querySelector("html").style.pointerEvents = "none"
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      window.removeEventListener("start", startFunction);
      window.removeEventListener("end", stopFunction);
    };
  };

  const slider = React.useRef(null);

  return (
    <Box maxW="80%" margin="auto" width="100%">
      <Box mb="10px" pt="15px" d="flex" justifyContent="center">
        <Image style={{ width: "10%" }} alt="img" src={logo2} onClick={() => history.push(route.homepage)} />
      </Box>
      <Box
        mb="35px"
        display={{ base: "none", sm: "none", lg: "flex" }}
        justifyContent="center"
      >
        <Text
          color={colors.text}
          textShadow={colors.textShadow}
          fontSize="20px"
          textAlign={"center"}
        >
          İSTEDİĞİN MARKAYI SEÇ VE DENEME ÇEKİMİNE BAŞLA
        </Text>
      </Box>
      <Box
        display={{ base: "none", sm: "none", lg: "flex" }}
        width="100%"
        justifyContent="center"
      >
        {infiniteStatus && (
          <>
            <button
              className="prevArrow"
              onClick={() => slider?.current?.slickPrev()}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"></path>
              </svg>
            </button>
            <button
              className="prevNext"
              onClick={() => slider?.current?.slickNext()}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
              </svg>
            </button>
          </>
        )}
        <Box overflowX="hidden !important" width="100%" position="relative">
          <List>
            {advertList && advertList.adverts && advertList.adverts.map && (
              <ChakraProvider>
                <Slider ref={slider} {...settings}>
                  {advertList.adverts.map((d, k) => (
                    <Card key={k} data={d} selectAuditions={selectAuditions} />
                    
                  ))}
                </Slider>
              </ChakraProvider>
            )}
          </List>
        </Box>
            </Box>
      {/* Mobil */}
      <Box
        alignItems="center"
        d="flex"
        flexDirection="column"
        display={{ base: "flex", sm: "flex", lg: "none" }}
        maxW="100%"
        justifyContent="center"
      >
        <Box
          mb="15px"
          display={{ base: "flex", sm: "flex", lg: "none" }}
          justifyContent="center"
        >
          <Text
            color={colors.text}
            textShadow={colors.textShadow}
            fontSize="8px"
            textAlign={"center"}
          >
            İSTEDİĞİN MARKAYI SEÇ VE DENEME ÇEKİMİNE BAŞLA
          </Text>
        </Box>
        <Box width="100%" position="relative">
          <List>
            {advertList && advertList.adverts && advertList.adverts.map && (
              <ChakraProvider>
                <Slider {...settings2}>
                  {advertList.adverts.map((d, k) => (
                    <Card key={k} data={d} selectAuditions={selectAuditions} />
                  ))}
                </Slider>
              </ChakraProvider>
            )}
          </List>
        </Box>
      </Box>
      {/* Mobil */}
    </Box>
  );
};
export default AdvertSelect;
