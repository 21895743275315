import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_BANNER_STATUS } from "../constants/videoContants";

import DetectModal from "./DetectModal";
import DuelditionChallange from "./DuelditionChallange";

const Home = ({ location }) => {
  const dispatch = useDispatch();
  const detectStatus = useSelector(state => state.bannerVideo.video);


  const setDetectStatus = () => {
    dispatch({
      type: SET_BANNER_STATUS,
      payload: false,
    });
  }


  return (
    <>
      {detectStatus ? (
        <DetectModal detectStatus={detectStatus} setDetectStatus={setDetectStatus} />
      ) : (
        <DuelditionChallange detectStatus={detectStatus} location={location} />
      )}
    </>
  );
};

export default Home;
