import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeStreamVideo,
  clearErrors,
} from "../actions/videoAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import logo from "../assets/images/logo.png";
import dangerComp2 from "../assets/images/dangerComp2.png";
import { Text, Box, Image } from "@chakra-ui/react";
import colors from "../theme/colors";
import Footericon from "../components/footerIcon";
import route from "../constants/route";
import { SET_DUEL_VIDEOS } from "../constants/videoContants";

const Progress = ({ location }) => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const { progress, error } = useSelector((state) => state.videos);
  const duelVideo = useSelector((state) => state.duelVideoDetail);

  const removeVideo = () => {
    if (
      duelVideo &&
      duelVideo.video &&
      Object.keys(duelVideo.video).length > 0
    ) {
      dispatch(removeStreamVideo(duelVideo.video.video_guid));
      dispatch({
        type: SET_DUEL_VIDEOS,
        payload: {},
      });
      history.push(route.userRecord);
    }
  };

  useEffect(() => {
    if (!location.state) {
      history.push(route.homepage);
    }
  }, []);

  useEffect(() => {
    progress && setValue(progress);
    if (value >= 99) {
      setTimeout(() => {
        history.push(route.userRecord);
        // document.getElementById("redirectLink").click()
      }, 3000);
    }
  }, [progress]);

  useEffect(() => {
    if (value == 100) {
      setTimeout(() => {
        history.push(route.userRecord);
        // document.getElementById("redirectLink").click()
      }, 3000);
    }
  }, [value]);

  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  return (
    <Box maxW="80%" margin="auto" width="100%">
      <Box mb="35px" pt="50px" d="flex" justifyContent="center">
        <Image alt="img" src={logo} />
      </Box>
      <a
        id="redirectLink"
        href="https://launcher.theargeofeverything.com/user/login?duelditionSubmited"
      ></a>
      <Box d="flex" justifyContent="center">
        <Box
          height="150px"
          w={{ base: "275px", md: "unset" }}
          mt={{ base: "40pxgit ", md: "unset" }}
        >
          <Image alt="img" src={dangerComp2} />
          {value == 100 && (
            <Text
              top={{ base: "-73px", md: "-150px" }}
              left={{ base: "70px", md: "225px" }}
              position="relative"
              color={colors.text}
              fontSize={{ base: "15px", md: "25px" }}
              maxW="250px"
              textAlign="center"
            >
              Tamamlandı
            </Text>
          )}
          {value == !100 && (
            <Text
              top={{ base: "-73px", md: "-150px" }}
              left={{ base: "70px", md: "225px" }}
              position="relative"
              color={colors.text}
              fontSize={{ base: "15px", md: "25px" }}
              maxW="250px"
              textAlign="center"
            >
              YÜKLENİYOR....
            </Text>
          )}
          <Text
            top={{ base: "-73px", md: "-150px" }}
            left={{ base: "70px", md: "260px" }}
            position="relative"
            color={colors.text}
            fontSize={{ base: "15px", md: "25px" }}
            maxW="200px"
            textAlign="center"
          >
            {value}%
          </Text>
        </Box>
      </Box>
      <div className="progress">
        <div style={{ width: `${value}%` }} className="bar bars"></div>
      </div>
      <Footericon
        d="flex"
        pt={{ base: "100px", md: "70px" }}
        w={{ base: "144px", md: "100%" }}
        margin="auto"
      />
    </Box>
  );
};
export default Progress;
