import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadVideo,
  getVideoDetail,
  clearErrors,
} from "../actions/videoAction";
import {
  sendDuelditionRequest,

  sendMentorEducationRequest,
} from "../actions/auditionAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import btnimage from "../assets/images/btn.png";
import camBox2 from "../assets/images/camBox2.png";
import logo from "../assets/images/logo.png";
import grid1 from "../assets/images/grid1.png";
import recBtn from "../assets/images/recBtn.png";
import mobilBtn from "../assets/mobilimages/mobilBtn.png";
import Timer from "../components/Backwardtimer";
import ButtonRightIcon from "../components/buttonRighticon";
import QRCode from "qrcode";
import {
  Text,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import route from "../constants/route";
import useRecorder from "react-hook-recorder";
import font from "../theme/font";
import ReactHlsPlayer from "react-hls-player";
import DuelditionRecordWithFaceAPI from "../components/dueldition-record-face-api";
import {
  loadTinyFaceDetectorModel,
  loadFaceExpressionModel,
} from "face-api.js";
import { getQrcodeURL } from "../actions/generalAction";

const DuelditionChallange = ({ location, detectStatus }) => {
  let zaman;
  let zaman2;
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [start, setStart] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [loop, setLoop] = useState(true);
  const [videoDuration, setVideoDuration] = useState();
  const [timerVisable, setTimervisable] = useState(false);
  const [timerVisable2, setTimervisable2] = useState(false);
  const [thumbnailStatus, setThumbnailStatus] = useState(true);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [continues, setContinues] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOnay2,
    onOpen: isOnay2Open,
    onClose: isOnay2Close,
  } = useDisclosure();
  const {
    isOpen: isOnay3,
    onOpen: isOnay3Open,
    onClose: isOnay3Close,
  } = useDisclosure();
  const {
    isOpen: isOnay4,
    onOpen: isOnay4Open,
    onClose: isOnay4Close,
  } = useDisclosure();

  const videoDetail = useSelector((state) => state.videoDetail);
  const { videos, progress, error, loading } = useSelector(
    (state) => state.videos
  );


  const { stream, startRecording, stopRecording, register, status } =
    useRecorder(); //!!!!!!!!!!! stream added.
  const user = JSON.parse(sessionStorage.getItem("userData"));
  let htmlObj = document.querySelector("html");

  const reactPlayer = useRef();
  const reactPlayer2 = useRef();

  useEffect(() => {
    if (!location.state) {
      history.push(route.homepage);
    }
  }, []);
  const [qrUrl, setQrUrl] = useState("");
  const [src, setSrc] = useState("");
  const [qrCodeVis, serQrCodeVis] = useState(false);

  const getQrcode = () => {
    getQrcodeURL().then((res) => {
      console.log('qrurl', res)
      if (res && res.data && res.data.Attr != null) {
        setQrUrl(res.data.Attr.url);
      }
    });
  };

  useEffect(() => {
    qrUrl && QRCode.toDataURL(qrUrl).then(setSrc);
    qrUrl && serQrCodeVis(true);
  }, [qrUrl]);

  useEffect(() => {

    console.log('kwdkalskdlaskd', location.state)
    if (thumbnailStatus && location.state && location.state.videoId) {
      dispatch(getVideoDetail(location.state.videoId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      videoDetail &&
      videoDetail.video &&
      videoDetail.video.thumbnailFileName
    ) {
      setThumbnailUrl(videoDetail.video.thumbnailFileName);
    }
  }, [videoDetail]);

  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);

  const activateButtons = () => {
    speech(
      "Dueldişın ekranına hoş geldin. Dueldişınını bu ekrandan çekip bize iletebilirsin. Hazır olduğunda kırmızı butona tıklayarak çekime başla.",
      1
    );
    
    if (window.location.pathname == route.denemecekimi) {
      if (loop) {
        if (!sessionStorage.getItem("listenText")) {
          htmlObj.onClick = () => undefined;
          htmlObj.ontouchstart = () => undefined;
          speech(
            "Dueldişın ekranına hoş geldin. Dueldişınını bu ekrandan çekip bize iletebilirsin. Hazır olduğunda kırmızı butona tıklayarak çekime başla.",
            1
          );
        }
      } else {
        document.querySelector("html").onclick = () => undefined;
        document.querySelector("html").ontouchstart = () => undefined;
      }
    }
  };

  useEffect(() => {
    if(!detectStatus){
      activateButtons();
    }
  }, [detectStatus])

  useEffect(() => {
    let video = document.getElementById("videoPlayer");
    if (toggle) {
      if (video) {
        video.currentTime = 0;
      }
      // document.getElementById("tryagain").classList.remove("disabledbutton");
      document.getElementById("mentor").classList.add("disabledbutton");
      document.getElementById("tamam").classList.add("disabledbutton");
      document.getElementById("dueldition").classList.add("disabledbutton");
      document.getElementById("create_duello").classList.add("disabledbutton");
    } else {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
      document.getElementById("mentor").classList.remove("disabledbutton");
      document.getElementById("tamam").classList.remove("disabledbutton");
      document.getElementById("dueldition").classList.remove("disabledbutton");
      document
        .getElementById("create_duello")
        .classList.remove("disabledbutton");
    }
    return () => {
      console.log("");
    };
  }, [toggle]);

  const [isRecorded, setRecorded] = useState(false);

  const onStop1 = useCallback((blob, blobUrl) => {
    setRecorded(true);
    clearTimeout(zaman);
    clearTimeout(zaman2);
    setStart(false);
    setToggle(false);
    setUrl1(blobUrl);
    document.getElementById("tryagain").classList.remove("disabledbutton");
    setTimervisable(false);
    setTimervisable2(false);
    tryAgain();
  }, []);

  const onStop2 = useCallback((blob, blobUrl) => {
    setRecorded(true);
    clearTimeout(zaman);
    clearTimeout(zaman2);
    setStart(false);
    setToggle(false);
    setUrl2(blobUrl);
    document.getElementById("tryagain").classList.remove("disabledbutton");
    setTimervisable(false);
    setTimervisable2(false);
    tryAgain();
  }, []);

  const onSubmit = async (url) => {
    if (url) {
      let blob = await fetch(url).then((r) => r.blob());
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      dispatch(uploadVideo(blob, user.id));

      const fileVideo = new File([blob], user.id + ".mp4", {
        type: "video/mp4",
      });
      history.push({ pathname: "/yukleme", state: { data: location.state } });
    } else {
      alert.error("Video Kaydı Yapınız");
    }
  };
  const sendRequestForm = (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("actor_name", event.target.actor.value);
    fd.append("category", event.target.category.value);
    fd.append("scenario", event.target.duel_text.value);
    fd.append("user_id", user.id);

    sendDuelditionRequest(fd).then((res) => {
      if (res && res.status === 200) {
        alert.success('İsteğiniz alınmıştır. Teşekkür ederiz.')
        isOnay3Close();
      } else {
        alert.error("İstek gönderilirken bir hata oluştu");
      }
    });
  };
  const sendRequestMentorForm = (event) => {
    event.preventDefault();
    const fd = new FormData();
    fd.append("actor_name", event.target.actor.value);
    fd.append("description", event.target.description.value);
    fd.append("user_id", user.id);

    sendMentorEducationRequest(fd).then((res) => {
      if (res && res.success) {
        isOnay4Close();
      } else {
        alert.error("İstek gönderilirken bir hata oluştu");
      }
    });
  };

  const speech = (text, index) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);

    const startFunction = () => {
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
      if (index == 1) {
        setContinues(true);
        sessionStorage.setItem("listenText", true);
      }
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      ut.removeEventListener("start", startFunction);
      ut.removeEventListener("end", stopFunction);
    };
  };

  const autoPlayVideo = () => {
    let video = document.getElementById("videoPlayer");
    setVideoDuration(Math.floor(video.duration + 10));
    try {
      if (video) {
        video.autoplay = true;
        setTimervisable(true);
        video.play();
        document.getElementById("tryagain").classList.add("disabledbutton");
        zaman = setTimeout(() => {
          if (
            window.location.pathname == route.denemecekimi &&
            document.getElementById("stopBtn")
          ) {
            document.getElementById("stopBtn").click();
            setTimervisable(false);
            getQrcode();
          }
        }, Math.floor(video.duration + 10) * 1000);
      }
    } catch (error) {
      alert.error("Error");
    }
  };

  const autoPlayVideo2 = () => {
    let video = document.getElementById("videoPlayer2");
    setVideoDuration(Math.floor(video.duration + 10));
    try {
      if (video) {
        video.autoplay = true;
        setTimervisable2(true);
        video.play();
        document.getElementById("tryagain").classList.add("disabledbutton");
        zaman2 = setTimeout(() => {
          if (
            window.location.pathname == route.denemecekimi &&
            document.getElementById("stopBtn2")
          ) {
            document.getElementById("stopBtn2").click();
            setTimervisable(false);
          }
        }, Math.floor(video.duration + 10) * 1000);
      }
    } catch (error) {
      alert.error("Error");
    }
  };
  const tryAgain = () => {
    var video = document.getElementById("videoPlayer");
    var video2 = document.getElementById("videoPlayer2");
    var mycam2 = document.getElementById("mycam2");
    if (
      location.state &&
      location.state.videoId &&
      location.state.videoId != "banner"
    ) {
      if (video) {
        video.currentTime = 0;
      }
      if (video2) {
        video2.currentTime = 0;
      }
    } else {
      reactPlayer.current.seekTo(0, "second");
      reactPlayer2.current.seekTo(0, "second");
    }
  };

  const onayModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
          <ModalHeader
            fontSize={{ base: "10px", lg: "20px" }}
            style={{ textShadow: "1px 2px 2px red" }}
            textAlign="center"
          >
            DUELDİTİON'U TAMAMLAYIP KARŞIYA YÜKLE
          </ModalHeader>
          <ModalFooter
            justifyContent="center"
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Button
              onClick={() => {
                onSubmit(url1 == "" ? url2 : url1);
                onClose();
              }}
              _hover={{ bg: colors.hover }}
              mr={{ base: "unset", lg: "20px" }}
              mb={{ base: "10px", lg: "unset" }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              ONAYLIYORUM
            </Button>
            <Button
              onClick={() => {
                onClose();
              }}
              _hover={{ bg: colors.hover }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              İPTAL
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  const onayModal2 = (route) => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOnay2}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
          <ModalHeader
            fontSize={{ base: "10px", lg: "20px" }}
            style={{ textShadow: "1px 2px 2px red" }}
            textAlign="center"
          >
            BAŞKA SAYFAYA YÖNLENDİRİLECEKSİN VİDEONU KAYDETMEDEN DEVAM ETMEYİ
            ONAYLIYOR MUSUN?
          </ModalHeader>
          <ModalFooter
            justifyContent="center"
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Button
              onClick={() => {
                history.push(currentUrl);
                onClose();
              }}
              _hover={{ bg: colors.hover }}
              mr={{ base: "unset", lg: "20px" }}
              mb={{ base: "10px", lg: "unset" }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              ONAYLIYORUM
            </Button>
            <Button
              onClick={isOnay2Close}
              _hover={{ bg: colors.hover }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              İPTAL
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };
  const questFormModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOnay3}
        isCentered
      >
        <ModalOverlay />
        <form onSubmit={sendRequestForm}>
          <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
            <ModalHeader
              fontSize={{ base: "10px", lg: "20px" }}
              style={{ textShadow: "1px 2px 2px red" }}
              textAlign="center"
            >
              KENDİ DUELLONU YARAT
            </ModalHeader>
            <ModalBody>
              <Box>
                <label style={{ color: "white" }}>OYUNCU İSMİ</label>
                <Input
                  type="text"
                  placeholder="İsteğiniz oyuncu ismini giriniz"
                  name="actor"
                  style={{ color: "white" }}
                />
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <label style={{ color: "white" }}>KATEGORİ BİLGİSİ</label>
                <Input
                  type="text"
                  placeholder="Çekmek istediğiniz kategoriyi belirtiniz"
                  name="category"
                  style={{ color: "white" }}
                />
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <label style={{ color: "white" }}>DUELLO METNİ</label>
                <Textarea
                  name="duel_text"
                  style={{ color: "white" }}
                ></Textarea>
              </Box>
            </ModalBody>
            <ModalFooter
              justifyContent="center"
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Button
                type="submit"
                _hover={{ bg: colors.hover }}
                mr={{ base: "unset", lg: "20px" }}
                mb={{ base: "10px", lg: "unset" }}
                borderRadius="20px"
                color={colors.text}
                backgroundColor="#121518"
                fontSize={{ base: "10px", lg: "17px" }}
                textShadow="1px 2px 20px #01B6A2"
                rightIcon={<ButtonRightIcon />}
              >
                GÖNDER
              </Button>
              <Button
                onClick={isOnay3Close}
                _hover={{ bg: colors.hover }}
                borderRadius="20px"
                color={colors.text}
                backgroundColor="#121518"
                fontSize={{ base: "10px", lg: "17px" }}
                textShadow="1px 2px 20px #01B6A2"
                rightIcon={<ButtonRightIcon />}
              >
                İPTAL
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    );
  };
  const questMentorFormModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOnay4}
        isCentered
      >
        <ModalOverlay />
        <form onSubmit={sendRequestMentorForm}>
          <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
            <ModalHeader
              fontSize={{ base: "10px", lg: "20px" }}
              style={{ textShadow: "1px 2px 2px red" }}
              textAlign="center"
            >
              NASIL BİR EĞİTİM ALMAK İSTERSİN ?
            </ModalHeader>
            <ModalBody>
              <Box>
                <label style={{ color: "white" }}>OYUNCU İSMİ</label>
                <Input
                  type="text"
                  placeholder="İsteğiniz oyuncu ismini giriniz"
                  name="actor"
                  style={{ color: "white" }}
                />
              </Box>
              <Box style={{ marginTop: "10px" }}>
                <label style={{ color: "white" }}>EĞİTİM DETAYI</label>
                <Textarea
                  name="description"
                  style={{ color: "white" }}
                ></Textarea>
              </Box>
            </ModalBody>
            <ModalFooter
              justifyContent="center"
              flexDirection={{ base: "column", lg: "row" }}
            >
              <Button
                type="submit"
                _hover={{ bg: colors.hover }}
                mr={{ base: "unset", lg: "20px" }}
                mb={{ base: "10px", lg: "unset" }}
                borderRadius="20px"
                color={colors.text}
                backgroundColor="#121518"
                fontSize={{ base: "10px", lg: "17px" }}
                textShadow="1px 2px 20px #01B6A2"
                rightIcon={<ButtonRightIcon />}
              >
                GÖNDER
              </Button>
              <Button
                onClick={isOnay4Close}
                _hover={{ bg: colors.hover }}
                borderRadius="20px"
                color={colors.text}
                backgroundColor="#121518"
                fontSize={{ base: "10px", lg: "17px" }}
                textShadow="1px 2px 20px #01B6A2"
                rightIcon={<ButtonRightIcon />}
              >
                İPTAL
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    );
  };

  useEffect(async () => {
    await loadTinyFaceDetectorModel("models");
    await loadFaceExpressionModel("models");
  }, []);



  return (
    <Box>
          {onayModal()}
          {onayModal2("#") /* */}
          {questFormModal()}
          {questMentorFormModal()}
          <Box pt="50px" display={{ base: "none", sm: "none", lg: "block" }}>
            <Text
              mb="10px"
              fontSize="37px"
              color={colors.text}
              textAlign="center"
            >
              DUELDITION
            </Text>
          </Box>

          {/* Mobile */}
          <Box
            pt="20px"
            d="flex"
            justifyContent="center"
            display={{ base: "flex", sm: "flex", lg: "none", "2xl": "none" }}
          >
            <Image maxW="125px" alt="img" src={logo} />
          </Box>
          {/* Mobile */}
          {window.innerWidth > 991 && (
            <Box display={"block"}>
              <Box
                d="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box d="flex" zIndex="1" alignItems="center">
                  <Box
                    d="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Box d="flex" zIndex="1" alignItems="center">
                      <Box
                        d="flex"
                        alignItems="center"
                        flexDirection="row"
                        justifyContent="center"
                        h="440px"
                      >
                        <Box
                          d="flex"
                          flexDirection="column"
                          position="relative"
                          alignItems="center"
                          h="auto"
                          w={{ lg: "434px", xl: "434px" }}
                          top="0px"
                        >
                          {location.state && location.state.videoId && (
                            <>
                              {thumbnailStatus && thumbnailUrl && (
                                <Image
                                  alt="img"
                                  src={`https://vz-f1e685a6-9a4.b-cdn.net/${location.state.videoId}/${thumbnailUrl}`}
                                  cursor="pointer"
                                  maxH="325px"
                                  maxW="80%"
                                />
                              )}
                              <ReactHlsPlayer
                                id="videoPlayer"
                                src={`https://vz-f1e685a6-9a4.b-cdn.net/${location.state.videoId}/playlist.m3u8`}
                                style={
                                  thumbnailStatus && thumbnailUrl
                                    ? {
                                        position: "relative",
                                        borderRadius: "10px",
                                        maxHeight: "325px",
                                        display: "none",
                                      }
                                    : {
                                        position: "relative",
                                        borderRadius: "10px",
                                        maxHeight: "325px",
                                      }
                                }
                                controls={false}
                                autoPlay={false}
                              />
                            </>
                          )}
                        </Box>
                        <DuelditionRecordWithFaceAPI
                          isMobile={false}
                          id={"canvas1"}
                          register={register}
                          stream={stream}
                          url={url1}
                          setUrl={setUrl1}
                          start={start}
                        ></DuelditionRecordWithFaceAPI>
                        <Image
                          maxH="461px"
                          w="1020px"
                          position="absolute"
                          id="camBox2"
                          alt="img"
                          zIndex="-1"
                          src={camBox2}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                maxW="75px"
                position="relative"
                left="50%"
                transform="translate(-50%, 0px)"
              >
                {timerVisable && (
                  <Timer
                    count={videoDuration}
                    videoTimer={timerVisable}
                    style={{ maxWidth: "135px" }}
                    top={{ base: "-78px", md: "5px" }}
                    left={{ base: "110px", md: "0px" }}
                  />
                )}
              </Box>
              <Box className={continues ? "" : "disabledbutton"}>
                <Box d="flex" justifyContent="center" ml="160px">
                  <Box id="tryagain" d="flex" className="shadow">
                    <Image
                      height="103px"
                      alt="img"
                      src={btnimage}
                      onClick={() => {
                        setStart(true);
                        setUrl1(null);
                      }}
                      cursor="pointer"
                    />
                    <Text
                      w="150px"
                      fontSize={{ lg: "12", xl: font.btnFont }}
                      position="relative"
                      right={{ sm: "155px", xl: "170px" }}
                      top={{ sm: "43px", xl: "38px" }}
                      color={colors.text}
                      cursor="pointer"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      Tekrar Dene
                    </Text>
                  </Box>
                </Box>
                <Box d="flex" justifyContent="center" alignItems="baseline">
                  <Box
                    onClick={() => {
                      setCurrentUrl(route.ornekdeneme);
                      if (isRecorded) isOnay2Open();
                      else history.push(route.ornekdeneme);
                      // history.push(route.ornekdeneme)
                    }}
                    id="dueldition"
                    d="flex"
                    justifyContent="center"
                    flexDirection="column"
                    className="shadow"
                  >
                    <Image
                      //  onClick={() => history.push(route.ornekdeneme)}
                      height="120px"
                      w="320px"
                      alt="img"
                      src={btnimage}
                      cursor="pointer"
                    />
                    <Text
                      // onClick={() => history.push(route.ornekdeneme)}
                      w="265px"
                      fontSize={{ lg: "12", xl: font.btnFont }}
                      top="-72px"
                      left={{ sm: "55px", xl: "40px" }}
                      position="relative"
                      color={colors.text}
                      cursor="pointer"
                    >
                      Örnek DUELDITION Çekimi
                    </Text>
                  </Box>
                  <Box>
                    {!toggle ? (
                      <Image
                        alt="img"
                        src={grid1}
                        className="shadow"
                        onClick={() => {
                          startRecording();
                          setToggle(!toggle);
                          if (location.state && location.state.videoId) {
                            setThumbnailStatus(false);
                            autoPlayVideo();
                          }
                        }}
                        cursor="pointer"
                      />
                    ) : (
                      <Image
                        id="stopBtn"
                        alt="img"
                        src={grid1}
                        className="shadow"
                        onClick={stopRecording(onStop1)}
                        cursor="pointer"
                      />
                    )}
                  </Box>
                  <Box
                    onClick={() => isOnay4Open()}
                    id="mentor"
                    d="flex"
                    justifyContent="center"
                    flexDirection="column"
                    className="shadow"
                  >
                    <Image
                      onClick={() => isOnay4Open()}
                      cursor="pointer"
                      alt="img"
                      src={btnimage}
                      height="103px"
                      w="300px"
                    />
                    <Text
                      onClick={() => isOnay4Open()}
                      top="-62px"
                      left={{ sm: "55px", xl: "40px" }}
                      cursor="pointer"
                      w="265px"
                      fontSize={{ lg: "12", xl: font.btnFont }}
                      position="relative"
                      color={colors.text}
                    >
                      Mentor Eğitimi Al
                    </Text>
                  </Box>
                </Box>
                <Box
                  d="flex"
                  justifyContent="space-around"
                  mt="10px"
                  ml="160px"
                >
                  <Box
                    id="tamam"
                    d="flex"
                    justifyContent="center"
                    className="shadow"
                  >
                    <Image
                      onClick={() => onOpen()}
                      height="103px"
                      alt="img"
                      src={btnimage}
                      cursor="pointer"
                      style={{ position: "relative", left: "184px" }}
                    />
                    <Text
                      onClick={() => onOpen()}
                      w="150px"
                      fontSize={{ lg: "12", xl: font.btnFont }}
                      position="relative"
                      left="40px"
                      top="38px"
                      color={colors.text}
                      cursor="pointer"
                    >
                      Tamamla
                    </Text>
                  </Box>
                  {qrCodeVis && (
                    <Box
                      display={{ base: "none", sm: "none", lg: "block" }}
                      style={{ position: "relative", left: "40px" }}
                      className="shadow"
                    >
                      <Image cursor="pointer" alt="img" src={src} />
                    </Box>
                  )}
                  <Box id="create_duello" d="flex" className="shadow">
                    <Image
                      onClick={() => isOnay3Open()}
                      height="103px"
                      w="320px"
                      alt="img"
                      src={btnimage}
                      cursor="pointer"
                    />
                    <Text
                      onClick={() => isOnay3Open()}
                      w="250px"
                      fontSize={{ lg: "12", xl: font.btnFont }}
                      position="relative"
                      right="280px"
                      top="38px"
                      color={colors.text}
                      cursor="pointer"
                    >
                      Kendi Duellonu Yarat
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {
            //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          }
          {/* Mobile */}
          {window.innerWidth < 992 && (
            <Box
              d="flex"
              background="linear-gradient(#3FC2B3, #000000 50%)"
              borderRadius="20px"
              justifyContent="center"
              display={"block"}
              w={{ sm: "100%", md: "80%" }}
              margin="auto"
            >
              <Box maxW={{ sm: "90%", md: "60%" }} margin="auto" width="100%">
                <Box
                  background="black"
                  borderRadius="20px"
                  mt="50px"
                  alignItems="center"
                  d="flex"
                  flexDirection="column"
                >
                  <Box
                    d="flex"
                    maxW="80%"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box
                      d="flex"
                      w="300px"
                      height="205px"
                      mt="20px"
                      justifyContent="center"
                    >
                      <Box
                        d="flex"
                        h="200px"
                        position="relative"
                        top={{ md: "40px", xl: "49px" }}
                      >
                        {location.state && location.state.videoId && (
                          <>
                            {thumbnailStatus && thumbnailUrl && (
                              <Image
                                alt="img"
                                src={`https://vz-f1e685a6-9a4.b-cdn.net/${location.state.videoId}/${thumbnailUrl}`}
                                cursor="pointer"
                                h="200px"
                                w="575px"
                              />
                            )}
                            <ReactHlsPlayer
                              id="videoPlayer2"
                              src={`https://vz-f1e685a6-9a4.b-cdn.net/${location.state.videoId}/playlist.m3u8`}
                              style={
                                thumbnailStatus && thumbnailUrl
                                  ? {
                                      position: "relative",
                                      borderRadius: "10px",
                                      height: "200px",
                                      width: "575px",
                                      display: "none",
                                    }
                                  : {
                                      position: "relative",
                                      borderRadius: "10px",
                                      height: "200px",
                                      width: "575px",
                                    }
                              }
                              playsInline={true}
                              controls={false}
                              autoPlay={false}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box maxW="75px" mt={!timerVisable2 && "50px"}>
                      {timerVisable2 && (
                        <Timer
                          count={videoDuration}
                          videoTimer={timerVisable2}
                          style={{ maxWidth: "135px" }}
                          top={{ base: "0px", md: "30px" }}
                        />
                      )}
                    </Box>
                    <DuelditionRecordWithFaceAPI
                      id={"canvas2"}
                      register={register}
                      url={url2}
                      setUrl={setUrl2}
                      start={start}
                      isMobile={true}
                    ></DuelditionRecordWithFaceAPI>
                  </Box>
                </Box>
                <Box className={continues ? "" : "disabledbutton"} mt="10px">
                  <Box id="tryagain" d="flex" justifyContent="center">
                    <Box>
                      <Image
                        maxW="150px"
                        alt="img"
                        src={mobilBtn}
                        onClick={() => {
                          setStart(true);
                          setUrl2(null);
                        }}
                        cursor="pointer"
                      />
                      <Text
                        w="75px"
                        fontSize="8px"
                        position="relative"
                        top="-48px"
                        left="45px"
                        color={colors.text}
                        cursor="pointer"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Tekrar Dene
                      </Text>
                    </Box>
                  </Box>
                  <Box d="flex" justifyContent="center" alignItems="baseline">
                    <Text
                      onClick={() => {
                        setCurrentUrl(route.ornekdeneme);
                        if (isRecorded) isOnay2Open();
                        else history.push(route.ornekdeneme);
                      }}
                      id="dueldition"
                      maxW={{ sm: "50px" }}
                      fontSize="8px"
                      position="relative"
                      left="85px"
                      top="-40px"
                      color={colors.text}
                      cursor="pointer"
                    >
                      Örnek DUELDITION
                    </Text>
                    <Image
                      maxW="150px"
                      alt="img"
                      src={mobilBtn}
                      cursor="pointer"
                    />
                    {!toggle ? (
                      <Image
                        maxW="70px"
                        alt="img"
                        src={recBtn}
                        className="shadow"
                        onClick={() => {
                          startRecording();
                          setToggle(!toggle);
                          if (location.state && location.state.videoId) {
                            setThumbnailStatus(false);
                            autoPlayVideo2();
                          }
                        }}
                        cursor="pointer"
                      />
                    ) : (
                      <Image
                        id="stopBtn2"
                        maxW="70px"
                        alt="img"
                        src={recBtn}
                        className="shadow"
                        onClick={stopRecording(onStop2)}
                        cursor="pointer"
                      />
                    )}
                    <Image
                      maxW="150px"
                      onClick={() => isOnay4Open()}
                      cursor="pointer"
                      alt="img"
                      src={mobilBtn}
                    />
                    <Text
                      id="mentor"
                      maxW={{ sm: "59px" }}
                      onClick={() => isOnay4Open()}
                      cursor="pointer"
                      fontSize="8px"
                      position="relative"
                      right="110px"
                      top="-40px"
                      color={colors.text}
                    >
                      Mentor EĞİTİMLERİ
                    </Text>
                  </Box>
                  <Box d="flex" justifyContent="center" mt="10px">
                    <Box
                      id="tamam"
                      onClick={() => {
                        onOpen();
                      }}
                    >
                      <Image
                        maxW="150px"
                        alt="img"
                        src={mobilBtn}
                        cursor="pointer"
                      />
                      <Text
                        w="75px"
                        fontSize="8px"
                        position="relative"
                        top="-42px"
                        left="40px"
                        color={colors.text}
                        cursor="pointer"
                      >
                        Tamamla
                      </Text>
                    </Box>
                    <Box id="create_duello">
                      <Image
                        onClick={() => isOnay3Open()}
                        maxW="150px"
                        alt="img"
                        src={mobilBtn}
                        cursor="pointer"
                      />
                      <Text
                        onClick={() => isOnay3Open()}
                        w="75px"
                        fontSize="8px"
                        position="relative"
                        top="-42px"
                        left="40px"
                        color={colors.text}
                        cursor="pointer"
                      >
                        Kendi Duellonu Yarat
                      </Text>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {/* Mobile */}
        </Box>
  );
};

export default DuelditionChallange;
