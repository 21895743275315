import axioss from "axios";
import { API_BASE, MANAGE_API } from "../config/config";
import {
  CLEAR_ERRORS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  GET_CODE_FAIL,
  GET_CODE_REQUEST,
  GET_CODE_SUCCESS,
  SAVE_USER_ERR
} from "../constants/userContants";

const axios = axioss.create({
  baseURL: API_BASE,
});

const axiosManage = axioss.create({
  baseURL: MANAGE_API
});

export const getCode = (phoneNumber) => async (dispatch) => {
  try {
    phoneNumber = `+${phoneNumber}`;
    console.log(phoneNumber);
    dispatch({ type: GET_CODE_REQUEST });

    const { data } = await axios.post(`/auth/get-code`, { phoneNumber });
    dispatch({
      type: GET_CODE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CODE_FAIL,
      payload: { message: "Giriş yapmak için 90 saniye beklemelisiniz" },
    });
  }
};

export const signIn = (phoneNumber, code) => async (dispatch) => {
  try {
    phoneNumber = `+${phoneNumber}`;
    dispatch({ type: LOGIN_REQUEST });

    const { data } = await axios.post(`/auth/sign-in`, { phoneNumber, code });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });
    sessionStorage.setItem("userData", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: { message: "Hatalı bilgi girdiniz" },
    });
  }
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const saveUserCharacter = (fd) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2Mjc2YjI4MDkzYjhlOTMxNDE0YmQyNTciLCJzdWIiOiIrOTA1MzI2OTQ0NjkzIiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2NjAzNzc1OTksImV4cCI6MTY5MTkzNDUyNX0.54UtXG0vtivFhI-DePRTrsgdfyU6DktXPSKfgaqI_k4",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };
    await axiosManage.post(`/user-register`, fd, config).then(res => {

    });
   
  } catch (error) {
    dispatch({
      type: SAVE_USER_ERR,
      payload: error,
    });
  }
};



