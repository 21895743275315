import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuditions,
  getKvkk,
  postKvkk,
  clearErrors,
} from "../actions/auditionAction";
import { getMentorCategoryList } from "../actions/actorAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import enigmaLogo from "../assets/images/enigmaLogo.png";
import selectComp from "../assets/images/selectComp.png";
import logo from "../assets/images/logo.png";
import person from "../assets/images/person.svg";
import ButtonRightIcon from "../components/buttonRighticon";
import {
  Box,
  Image,
  List,
  ListItem,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import route from "../constants/route";
import KvkkModal from "../components/KvkkModal";

const Select = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loop, setLoop] = useState(true);
  const [onam, setOnam] = useState();
  const [hak, setHak] = useState();
  const [kullanıcıSozlesme, setKullanıcıSozlesme] = useState(false);
  const [gizlilikSozlesme, setGizlilikSozlesme] = useState(false);
  const [onamContinues, setOnamContinues] = useState(false);
  const [continues, setContinues] = useState(false);
  const { auditions, error } = useSelector((state) => state.audition);
  const { kvkk, error: err } = useSelector((state) => state.kvkk);
  const selActor = useSelector((state) => state.activeMentor.activeMentor);
  const categories = useSelector((state) => state.mentorCat.mentor_categories);
  const user = JSON.parse(sessionStorage.getItem("userData"));

  let htmlObj;
  let bodyObj;
  useEffect(() => {
    if (user.idToken) {
      dispatch(getAuditions(user.idToken));
      dispatch(getKvkk(user.idToken, user.id));
    }
  }, []);

  useEffect(() => {
    selActor && dispatch(getMentorCategoryList(selActor));
  }, [selActor]);

  useEffect(() => {
    kvkk && kvkk.kvkkPermission == false && onOpen();
    kvkk && setOnam(kvkk.kvkkPermission);
    kvkk && setHak(kvkk.videoCountPermission);
  }, [kvkk]);

  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
    if (err) {
      alert.error(err.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error, err]);

  const [touchText, setTouchText] = useState("no touch");

  useEffect(() => {
    if (window.location.pathname == route.select) console.log("loop:" + loop);
    {
      if (bodyObj == undefined || bodyObj == null)
        bodyObj = document.querySelector("body");
      if (htmlObj == undefined || htmlObj == null)
        htmlObj = document.querySelector("html");

      if (loop) {
        console.log("onam:" + onam);

        if (onam === false) {
          bodyObj.onclick = () => {
            speech(
              "Başta ses ve görüntü verileriniz olmak üzere KVKK aydınlatma metninde belirtilen verilerin, yine aynı metinde belirtilen durumlarda, tarafımızca kullanılmasına izin veriyor musunuz?"
            );
            bodyObj.onClick = undefined;
          };
          bodyObj.ontouchstart = () => {
            speech(
              "Başta ses ve görüntü verileriniz olmak üzere KVKK aydınlatma metninde belirtilen verilerin, yine aynı metinde belirtilen durumlarda, tarafımızca kullanılmasına izin veriyor musunuz?"
            );
            bodyObj.ontouchstart = undefined;
          };
        } else {
          bodyObj.onclick = () => undefined;
          bodyObj.ontouchstart = () => undefined;
        }
      } else {
        bodyObj.onclick = () => undefined;
        bodyObj.ontouchstart = () => undefined;
      }

      if (loop) {
        // changed by omer ayhan
        document.querySelector("html").onmousemove = () => {
          console.log("on mouse move event");
          if (onam === true && hak > 0) {
            console.log("on mouse move if");
            speech(
              "Bu ekrandan dueldişın vermek istediğin türü seçebilirsin. Kendini rahat ve doğal hissedeceğin alanda dueldişın vermeni tavsiye ederim."
            );
          }
          if (hak < 1) {
            speech("Hakkınız kalmadı.");
          }
          htmlObj.onmousemove = () => undefined;
        };
      } else {
        document.querySelector("html").onmousemove = () => undefined;
      }
      if (loop) {
        // changed by omer ayhan
        document.querySelector("html").ontouchstart = () => {
          if (onam === true && hak > 0) {
            speech(
              "Bu ekrandan dueldişın vermek istediğin türü seçebilirsin. Kendini rahat ve doğal hissedeceğin alanda dueldişın vermeni tavsiye ederim."
            );
          }
          if (hak < 1) {
            speech("Hakkınız kalmadı.");
          }
          htmlObj.ontouchstart = () => undefined;
        };
      } else {
        document.querySelector("html").ontouchstart = () => undefined;
      }
    }
    return () => console.log();
  }, [loop, onam]);

  const speech = (text) => {
    const ut = new SpeechSynthesisUtterance(text);

    window.speechSynthesis.cancel();

    ut.lang = "tr-TR";
    speechSynthesis.speak(ut);

    const startFunction = () => {
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık

      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
      setOnamContinues(true);
      if (onam && hak > 0) {
        setContinues(true);
      }
      if (document.querySelector(".chakra-modal__content-container")) {
        document.getElementsByClassName(
          "chakra-modal__content-container"
        )[0].style.pointerEvents = "all";
        setLoop(true);
      }
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      ut.removeEventListener("start", startFunction);
      ut.removeEventListener("end", stopFunction);
    };
  };

  const selectAuditions = (videoId) => {
    if (hak > 0) {
      history.push({ pathname: route.denemecekimi, state: { videoId } });
    }
  };
  const updateKvkk = () => {
    dispatch(postKvkk(user.idToken, user.id, true));
  };
  const kvkkModal = () => {
    return (
      <KvkkModal
        onClose={onClose}
        isOpen={isOpen}
        kullaniciSozlesme={kullanıcıSozlesme}
        setKullaniciSozlesme={() => setKullanıcıSozlesme(!kullanıcıSozlesme)}
        gizlilikSozlesme={gizlilikSozlesme}
        setGizlilikSozlesme={() => setGizlilikSozlesme(!gizlilikSozlesme)}
        onamContinues={onamContinues}
        updateKvkk={updateKvkk}
      />
    );
  };

  useEffect(() => { console.log('-----', kullanıcıSozlesme, gizlilikSozlesme) }, [kullanıcıSozlesme, gizlilikSozlesme]);

  return (
    <Box>
      {kvkkModal()}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "none", sm: "none", lg: "flex" }}
      >
        <Image alt="img" src={enigmaLogo} onClick={() => history.push(route.homepage)} />
      </Box>
      {/* Mobile */}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}
      >
        <Image maxW="125px" alt="img" src={logo} onClick={() => history.push(route.homepage)} />
      </Box>
      {/* Mobile */}
      <Box display={{ base: "none", sm: "none", lg: "block" }}>
        <Box mt="50px" d="flex" alignItems="center" flexDirection="column">
          <Box>
            <Image alt="img" src={selectComp} />
          </Box>
          <Box top="-430px" left="375px" position="relative">
            <Text
              d={{ base: "none", sm: "none", lg: "block" }}
              textAlign="center"
              textShadow={colors.textShadow}
              color="white"
              fontSize="20px"
            >
              Hak {hak}
            </Text>
          </Box>
          <Box
            overflowX="hidden !important"
            overflow="scroll"
            maxH="280px"
            top="-420px"
            left="390px"
            position="relative"
          >
            <List
              marginRight="17px"
              className={continues ? "" : "disabledbutton"}
            >
              {categories &&
                categories.length > 0 &&
                categories.map &&
                categories.map((data, index) => (
                  <ListItem key={data.video_id} pb="12px">
                    {" "}
                    <Button
                      onClick={() => selectAuditions(data.video_id)}
                      _hover={{ bg: colors.hover }}
                      justifyContent="end"
                      color={colors.text}
                      backgroundColor="#121518"
                      fontSize="17px"
                      textShadow="1px 2px 20px #01B6A2"
                      w="244px"
                      h="32px"
                      rightIcon={<ButtonRightIcon />}
                    >
                      {data.category_name}
                    </Button>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        d="flex"
        background="linear-gradient(#3FC2B3, #000000 70%)"
        borderRadius="20px"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}
      >
        <Box maxW="90%" margin="auto" width="100%">
          <Box
            background="black"
            borderRadius="20px"
            mt="50px"
            alignItems="center"
            d="flex"
            flexDirection="column"
          >
            <Box
              d="flex"
              maxW="80%"
              flexDirection="column"
              alignItems="center"
              mb="20px"
            >
              <Box mt="10px">
                <Image alt="img" src={person} />
              </Box>
              <Box>
                <List mt="20px" className={continues ? "" : "disabledbutton"}>
                  {categories &&
                    categories.length > 0 &&
                    categories.map &&
                    categories.map((data) => (
                      <ListItem key={data.video_id} pb="12px">
                        {" "}
                        <Button
                          onClick={() => selectAuditions(data.video_id)}
                          _hover={{ bg: colors.hover }}
                          justifyContent="space-between"
                          color={colors.text}
                          backgroundColor="#121518"
                          fontSize="15px"
                          textShadow="1px 2px 20px #01B6A2"
                          w="244px"
                          h="32px"
                          rightIcon={<ButtonRightIcon />}
                        >
                          {data.category_name}
                        </Button>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Mobile */}
      <Text
        d={{ base: "block", sm: "block", lg: "none" }}
        textAlign="center"
        textShadow={colors.textShadow}
        color="white"
        fontSize="20px"
        my="20px"
      >
        Hak {hak}
      </Text>
    </Box>
  );
};
export default Select;
