import axios from "axios";
import { API_BASE, VIDEO_API, PANEL_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_AUDITION_FAIL,
  GET_AUDITION_REQUEST,
  GET_AUDITION_SUCCESS,
  GET_KVKK_SUCCESS,
  GET_KVKK_REQUEST,
  GET_KVKK_FAIL,
  POST_KVKK_SUCCESS,
  POST_KVKK_REQUEST,
  POST_KVKK_FAIL,
} from "../constants/auditionContants";

export const getAuditions = (idToken) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        AccessKey: "9594f4bc-363d-409a-a7ff3452ca6f-fc73-4ba0",
      },
    };

    dispatch({ type: GET_AUDITION_REQUEST });

    const { data } = await axios.get(
      `${VIDEO_API}/?page=1&itemsPerPage=100&collection=2e60015c-948e-4454-b691-70e6d1553929&orderBy=date`,
      config
    );
    dispatch({
      type: GET_AUDITION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_AUDITION_FAIL,
      payload: error,
    });
  }
};

export const getKvkk = (idToken, userId) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };

    dispatch({ type: GET_KVKK_REQUEST });

    const { data } = await axios.get(`${API_BASE}/accounts/${userId}`, config);

    dispatch({
      type: GET_KVKK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_KVKK_FAIL,
      payload: error,
    });
  }
};

export const postKvkk = (idToken, userId, boolean) => async (dispatch) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${idToken}` },
    };

    dispatch({ type: POST_KVKK_REQUEST });

    const { data } = await axios.patch(
      `${API_BASE}/accounts/${userId}`,
      { kvkkPermission: boolean },
      config
    );

    dispatch({
      type: POST_KVKK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: POST_KVKK_FAIL,
      payload: error,
    });
  }
};

export const sendDuelditionRequest = async (fd) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
      },
    };

    const data = await axios
      .post(`${PANEL_API}/dueldition-feedbacks`, fd, config)
      .then((response) => response)
      .catch((error) => error.response.data);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const sendMentorEducationRequest = async (fd) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
      },
    };

    const { data } = await axios.post(`${PANEL_API}/education`, fd, config);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

// export const getQrcodeURL = async (fd) => {
//   try {
//     const config = {
//       headers: {
//         Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
//         Accept: "application/json",
//       },
//     };

//     const data = await axios
//       .get(`${PANEL_API}/get-code`, config)
//       .then((response) => response)
//       .catch((error) => error.response.data);
//     return data;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };




// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
