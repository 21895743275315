import {
    GET_AUDITION_FAIL,
    GET_AUDITION_REQUEST,
    GET_AUDITION_SUCCESS,
    GET_AUDITION_RESET,
    GET_KVKK_FAIL,
    GET_KVKK_REQUEST,
    GET_KVKK_RESET,
    GET_KVKK_SUCCESS,
    POST_KVKK_FAIL,
    POST_KVKK_REQUEST,
    POST_KVKK_RESET,
    POST_KVKK_SUCCESS,
    CLEAR_ERRORS
} from '../constants/auditionContants'

const initialState = {
    auditions: {}
}
export const auditionReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_AUDITION_REQUEST:
            return {
                loading: true,
            }

        case GET_AUDITION_SUCCESS:
            return {
                ...state,
                loading: false,
                auditions: action.payload
            }

        case GET_AUDITION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const kvkkReducer = (state = { kvkk: {} }, action) => {
    switch (action.type) {

        case GET_KVKK_REQUEST:
        case POST_KVKK_REQUEST:
            return {
                loading: true,
            }

        case GET_KVKK_SUCCESS:
        case POST_KVKK_SUCCESS:
            return {
                ...state,
                loading: false,
                kvkk: action.payload
            }

        case GET_KVKK_FAIL:
        case POST_KVKK_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}