import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getExampleVideos, clearErrors } from "../actions/videoAction"
import { useAlert } from 'react-alert'
import { useHistory } from "react-router-dom";
import enigmaLogo from "../assets/images/enigmaLogo.png"
import verBox from "../assets/images/verBox.png"
import { Text, Box, Image } from "@chakra-ui/react"
import colors from "../theme/colors"
import boxComp from "../assets/mobilimages/boxComp.png"
import logo from "../assets/images/logo.png"
const Ornekdeneme = () => {

    const alert = useAlert();
    const dispatch = useDispatch();
    const history = useHistory();

    const { videos, error } = useSelector(state => state.videos);
    const user = JSON.parse(sessionStorage.getItem("userData"))

    useEffect(() => {
        if (user.idToken) {
            dispatch(getExampleVideos(0, user.idToken))
        }
    }, [dispatch])

    useEffect(() => {
        if (error) {
            alert.error(error.message);
            dispatch(clearErrors());
        }
    }, [dispatch, error])

    return (
        <Box>

            <Box pt="20px" d="flex" justifyContent="center" display={{ base: "none", md: "flex" }}>
                <Image alt="img" src={enigmaLogo} />
            </Box>

            {/* Mobile */}
            <Box pt="20px" d="flex" justifyContent="center" display={{ base: "flex", md: "none" }}>
                <Image maxW="125px" alt="img" src={logo} />
            </Box>
            {/* Mobile */}

            <Box display={{ base: "none", md: "block" }}>
                <Box d="flex" justifyContent="center" height="200px">
                    <Box>
                        <Text fontSize="19px" position="relative" left="80px" top="95px" color={colors.text}>Örnek DUELDITION Çekimleri</Text>
                        <Image alt="img" src={verBox} />
                    </Box>
                </Box>
                <Box className="overflow" d="flex" margin="auto" flexFlow="wrap" maxW={{ sm: "100%", lg: "1000px" }} maxH="600px" justifyContent="center" >
                    <Text>PEK YAKINDA SENİNLE</Text>
                    {/* <Box height="300px">
                        <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U" style={{ pointerEvents: "none" }}>
                            <Image position="relative" zIndex="2" alt="img" src={videoBox2} />
                            <Text zIndex="3" fontSize="19px" position="relative" left="140px" top="-100px" color={colors.text}>Video İsmi</Text>
                        </a>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="352px" height="198px" style={{ position: "relative", bottom: "340px", left: "60px", zIndex: "1" }} />
                    </Box>
                    <Box height="300px">
                        <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                            <Image position="relative" zIndex="2" alt="img" src={videoBox2} />
                            <Text zIndex="3" fontSize="19px" position="relative" left="140px" top="-100px" color={colors.text}>Video İsmi</Text>
                        </a>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="352px" height="198px" style={{ position: "relative", bottom: "340px", left: "60px", zIndex: "1" }} />
                    </Box>
                    <Box height="300px">
                        <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                            <Image position="relative" zIndex="2" alt="img" src={videoBox2} />
                            <Text zIndex="3" fontSize="19px" position="relative" left="140px" top="-100px" color={colors.text}>Video İsmi</Text>
                        </a>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="352px" height="198px" style={{ position: "relative", bottom: "340px", left: "60px", zIndex: "1" }} />
                    </Box>
                    <Box height="300px">
                        <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                            <Image position="relative" zIndex="2" alt="img" src={videoBox2} />
                            <Text zIndex="3" fontSize="19px" position="relative" left="140px" top="-100px" color={colors.text}>Video İsmi</Text>
                        </a>
                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="352px" height="198px" style={{ position: "relative", bottom: "340px", left: "60px", zIndex: "1" }} />
                    </Box> */}
                </Box>
            </Box>
            {/* Mobile */}
            <Box
                d="flex"
                background="linear-gradient(#3FC2B3, #000000 50%)"
                borderRadius="20px"
                justifyContent="center"
                display={{ base: "flex", md: "none" }}
            >
                <Box
                    maxW="90%"
                    margin="auto"
                    width="100%"
                >
                    <Box background="black" borderRadius="20px" mt="50px" alignItems="center" d="flex" flexDirection="column">
                        <Box mt="20px" d="flex" flexDirection="column" maxW="80%">
                            <Image height="91px" alt="img" src={boxComp} />
                            <Text top="-55px" left="60px" position="relative" maxW="200px" fontSize="15px" color={colors.text} >Örnek Deneme</Text>
                        </Box>
                        <Box d="flex">
                            <Box d="flex" flexFlow="wrap" justifyContent="center">
                                <Text color={colors.text}>PEK YAKINDA SENİNLE</Text>
                                {/* <Box >
                                    <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                                        <Image zIndex="2" position="relative" alt="img" src={videoBox} />
                                        <Text zIndex="3" top="-87px" left="130px" position="relative" maxW="100px" fontSize="10px" color={colors.text} >Video İsmi</Text>
                                    </a>
                                    <Box height="0px">
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="233px" height="134px" style={{ position: "relative", bottom: "251px", left: "51px", zIndex: "1" }} />
                                    </Box>
                                </Box>
                                <Box >
                                    <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                                        <Image zIndex="2" position="relative" alt="img" src={videoBox} />
                                        <Text zIndex="3" top="-87px" left="130px" position="relative" maxW="100px" fontSize="10px" color={colors.text} >Video İsmi</Text>
                                    </a>
                                    <Box height="0px">
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="233px" height="134px" style={{ position: "relative", bottom: "251px", left: "51px", zIndex: "1" }} />
                                    </Box>
                                </Box>
                                <Box >
                                    <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                                        <Image zIndex="2" position="relative" alt="img" src={videoBox} />
                                        <Text zIndex="3" top="-87px" left="130px" position="relative" maxW="100px" fontSize="10px" color={colors.text} >Video İsmi</Text>
                                    </a>
                                    <Box height="0px">
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="233px" height="134px" style={{ position: "relative", bottom: "251px", left: "51px", zIndex: "1" }} />
                                    </Box>
                                </Box>
                                <Box >
                                    <a target="_blank" href="https://www.youtube.com/watch?v=ysz5S6PUM-U">
                                        <Image zIndex="2" position="relative" alt="img" src={videoBox} />
                                        <Text zIndex="3" top="-87px" left="130px" position="relative" maxW="100px" fontSize="10px" color={colors.text} >Video İsmi</Text>
                                    </a>
                                    <Box height="0px">
                                        <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width="233px" height="134px" style={{ position: "relative", bottom: "251px", left: "51px", zIndex: "1" }} />
                                    </Box>
                                </Box> */}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Mobile */}

           
        </Box >

    )
}
export default Ornekdeneme