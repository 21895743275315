import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadVideo,
  getStreamVideo,
  clearErrors,
} from "../actions/videoAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import Footericon from "../components/footerIcon";
import btnimage from "../assets/images/btn.png";
import background from "../assets/images/background.png";
import camBox from "../assets/images/camBox.png";
import camBox2 from "../assets/images/camBox2.png";
import logo from "../assets/images/logo.png";
import grid1 from "../assets/images/grid1.png";
import dalga from "../assets/images/dalga.png";
import recBtn from "../assets/images/recBtn.png";
import mobilBtn from "../assets/mobilimages/mobilBtn.png";
import Timer from "../components/Backwardtimer";
import ButtonRightIcon from "../components/buttonRighticon";
import videRec from "../assets/images/videorec.png";
import { Text, Box, Image } from "@chakra-ui/react";

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

import {
  loadTinyFaceDetectorModel,
  detectSingleFace,
  TinyFaceDetectorOptions,
  resizeResults,
  matchDimensions,
  draw,
  loadFaceLandmarkTinyModel,
  loadFaceExpressionModel,
} from "face-api.js";
import { domAnimation } from "framer-motion";
import { ImSortNumbericDesc } from "react-icons/im";
const DuelditionRecordWithFaceAPI = ({
  register,
  url,
  setUrl,
  start,
  id,
  isMobile,
}) => {
  //*states
  const [video, setVideo] = useState(null);
  const [canvas, setCanvas] = useState();
  const [canvas2, setCanvas2] = useState(null);
  const [detected, setDetected] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [recognitionStarted, setRecognitionStarted] = useState(false);
  //TODO: will be removed before live
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  //*refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasRef2 = useRef(null);
  //*options
  const options = new TinyFaceDetectorOptions({
    inputSize: 416,
    scoreThreshold: 0.5,
  });
  //*reusable constants
  const recognition = { init: () => {}, start: () => {} };

  //*Assgins dom elements to their states consts
  useEffect(() => {
    //!!!!!!!!!!!!!!!!!!!!!!
    setVideo(videoRef.current);
    setCanvas(canvasRef.current);
    register(videoRef.current);
    setCanvas2(canvasRef2.current);
    //!!!!!!!!!!!!!!!!!!!!!!
  }, [videoRef, canvasRef]);

  //*Adds event listener to video camera to automatically start the FACE API after camera is ready.
  useEffect(() => {
    if (!(video == null) && !(video == undefined)) {
      video.addEventListener("loadeddata", function () {
        if (video.readyState == 4) {
          if (!recognitionStarted);
          recognition.start();
        }
      });
    }
  }, [video]);

  //*Assigns Recognition functions to recognition state and initialize context of canvas to ctx const in makeRecognition()
  useEffect(() => {
    if (!(canvas == null || canvas == undefined)) {
      initRecoginiton();
    }
  }, [canvas]);

  //!!!!!!!!!!!!!!!!! newly added
  const initRecoginiton = async () => {
    Object.assign(recognition, makeRecognition());
    recognition.init();
  };

  const makeRecognition = () => {
    let ctx;

    const init = async () => {
      ctx = canvas.getContext("2d");
    };
    const start = async () => {
      await wait(0);
      ////////console.log("-----")
      if (!recognitionStarted) setRecognitionStarted(true);
      if (video.readyState === 4) {
        const faces = await detectSingleFace(video, options) //.withFaceLandmarks(true)
          .withFaceExpressions();

        setLoadingState(false);

        if (faces) {
          //////console.log("face detected")
          setDetected(true);
          const dims = matchDimensions(canvas, video, true);
          const resizedResults = resizeResults(faces, dims);
          if (true) {
            draw.drawDetections(canvas, resizedResults);
          }
          if (true) {
            draw.drawFaceExpressions(canvas, resizedResults);
          }
          // if (true) {
          //     draw.drawFaceLandmarks(canvas, resizedResults);
          // }
        } else {
          setDetected(false);
          if (!ctx === undefined)
            ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
        }
      } else {
        if (!ctx === undefined)
          ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
      }
      start();
    };

    return { init, start };
  };

  if (!isMobile) {
    return (
      <Box
        h="auto"
        position="relative"
        w={{ md: "40%", xl: "40%" }}
        top="0px"
        right="0px"
      >
        {url && (
          <div>
            <p
              style={{
                color: "white",
                position: "absolute",
                top: 13,
                right: 40,
                zIndex: 1000000,
              }}
            >
              Replay
            </p>
            <Image
              alt="img"
              src={videRec}
              cursor="pointer"
              style={{
                position: "absolute",
                top: 10,
                right: 5,
                zIndex: 1000000,
                height: "30px",
                width: "30px",
              }}
            />
            <video
              className="video ust"
              src={url}
              style={{ position: "relative", borderRadius: "10px" }}
              playsInline={true}
              autoPlay
              muted={window.innerWidth > 991 ? false : true}
            />
          </div>
        )}
        {start && (
          <video
            className="desktopCamera"
            style={{ position: "relative", borderRadius: "10px" }}
            autoPlay
            muted
            playsInline={true}
            ref={videoRef}
          />
        )}
        <canvas
          id={id}
          style={{
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            width: "100%",
            maxWidth: "100%",
            height: "auto",
            zIndex: "1",
          }}
          ref={canvasRef}
        />
      </Box>
    );
  } else {
    return (
      <Box mt="10px" position="relative">
        {url && (
          <div>
            <p
              style={{
                color: "white",
                position: "absolute",
                fontSize: "12px",
                top: 10,
                right: 30,
                zIndex: 1000000,
              }}
            >
              Replay
            </p>
            <Image
              alt="img"
              src={videRec}
              cursor="pointer"
              style={{
                position: "absolute",
                top: 10,
                right: 5,
                zIndex: 1000000,
                height: "20px",
                width: "20px",
              }}
            />
            <video
              id="mycam2"
              src={url}
              style={{
                borderRadius: "15px",
                position: "relative",
                width: "300px",
              }}
              playsInline={true}
              autoPlay={true}
              muted={window.innerWidth < 992 ? false : true}
            />
          </div>
        )}
        {start && (
          <video
            className="mobileCamera"
            ref={videoRef}
            style={{
              borderRadius: "15px",
              position: "relative",
              width: "300px",
            }}
            autoPlay
            muted
            playsInline={true}
          />
        )}
        <canvas
          id={id}
          style={{
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            maxHight: "100%",
            zIndex: "12",
            height: "100%",
          }}
          ref={canvasRef}
        />
      </Box>
    );
  }
};
const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));

export default DuelditionRecordWithFaceAPI;
