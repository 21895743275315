import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import enigmaLogo from "../assets/images/enigmaLogo.png";
import videoBox3 from "../assets/images/videoBox3.png";
import titleComp from "../assets/images/titleComp.png";
import videRec from "../assets/images/videorec.png";
import {
  Text,
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import logo from "../assets/images/logo.png";
import boxComp from "../assets/mobilimages/boxComp.png";
import dangerMobile from "../assets/mobilimages/dangerMobile.png";
import route from "../constants/route";
import { Redirect } from "react-router";

import {
  loadTinyFaceDetectorModel,
  detectSingleFace,
  TinyFaceDetectorOptions,
  resizeResults,
  matchDimensions,
  draw,
  loadFaceLandmarkTinyModel,
  loadFaceExpressionModel,
} from "face-api.js";
import ButtonRightIcon from "../components/buttonRighticon";
import { useDispatch } from "react-redux";
import { SET_BANNER_STATUS } from "../constants/videoContants";
const Faceapi = () => {
  const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  const dispatch = useDispatch();

  const [width, height] = useWindowSize();
  const [video, setVideo] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [video2, setVideo2] = useState(null);
  const [canvas2, setCanvas2] = useState(null);
  const [detected, setDetected] = useState(false);
  const [camera, setCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loop, setLoop] = useState(true);
  const [interval,] = useState(null);
  const [disable] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const videoRef2 = useRef(null);
  const canvasRef2 = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onOpen();
  }, []);

  const onayModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
          <ModalHeader
            fontSize={{ base: "10px", lg: "20px" }}
            style={{ color: "white" }}
            textAlign="center"
          >
            Merhaba, ben enigma, ilerlemek için gülümsemen gerekiyor. Hazır
            hissettiğinde butonu
            <div style={{ display:'flex', justifyContent:'center', marginBottom:'5px', marginTop:'5px' }}>
              <Image
                alt="img"
                src={videRec}
                cursor="pointer"
                style={{ textAlign: "center" }}
              />
            </div>
            tıklayabilirsin.
          </ModalHeader>
          <ModalFooter
            justifyContent="center"
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              _hover={{ bg: colors.hover }}
              mr={{ base: "unset", lg: "20px" }}
              mb={{ base: "10px", lg: "unset" }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              HADİ BAŞLAYALIM
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const messageList = [
    "Hadi gül. Göster 32 dişini.",
    "Bu kadar utangaç olma, geniş geniş gülümse!",
    "Temel araba sürerken kırmızı ışıkta geçmiş. Tabii bunu gören polis Temel'i durdurmuş. Polis:  – Ehliyet ve ruhsat beyefendi! Temel:  – Verdunuzda mi isteysunuz...",
    "Aynanın karşısına geçip de gözlerini kapatan Temel’e ne yaptığını sormuşlar;– Hiç, uyurken kendimin nasıl göründüğünü merak ediyorum daa…",
  ];
  let index = 0;
  let waitindex = 0;
  let timerInterval;
  function startText() {
    if (waitindex == index) {
      speech(messageList[index], index);
      index = index + 1;
    }
    if (index == 4) {
      clearInterval(timerInterval);
    }
  }
  useEffect(() => {
    setVideo(videoRef.current);
    setCanvas(canvasRef.current);
    setVideo2(videoRef2.current);
    setCanvas2(canvasRef2.current);
    if (camera) {
      if (!timerInterval) timerInterval = setInterval(startText, 10000);
    }
    return () => {
      clearTimeout(timerInterval);
    };
  }, [camera]);
  if (!disable) {
    clearInterval(interval);
  }

  const speechStart = () => {
    if (window.location.pathname == route.faceapi) {
      if (loop) {
        speech(
          "Merhaba. Devam etmek için kamerayı açıp gülmen gerekiyor. Merak etme, verilerini iznin olmadıkça sunuculara kaydetmeyeceğim."
        );
      }
    }
  };

  const speech = (text, i) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);
    const startFunction = () => {
      // document.querySelector("html").style.pointerEvents = "none"
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
      waitindex = waitindex + 1;
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      ut.removeEventListener("start", startFunction);
      ut.removeEventListener("end", stopFunction);
    };
  };

  const start = async () => {
    if (width > 991) {
      await launchCamera();
    } else {
      await launchCamera2();
    }
    const recognition = makeRecognition();
    await recognition.init();
    recognition.start();
  };
  const options = new TinyFaceDetectorOptions({
    inputSize: 512,
    scoreThreshold: 0.5,
  });
  const makeRecognition = () => {
    let ctx;

    const init = async () => {
      await loadTinyFaceDetectorModel("models");
      await loadFaceLandmarkTinyModel("models");
      await loadFaceExpressionModel("models");
      ctx = canvas.getContext("2d");
    };
    const start = async () => {
      if (width > 991) {
        await wait(0);
        if (video.readyState === 4) {
          const faces = await detectSingleFace(video, options)
            .withFaceLandmarks(true)
            .withFaceExpressions();

          setLoading(false);
          if (faces) {
            setDetected(true);
            const dims = matchDimensions(canvas, video, true);

            const resizedResults = resizeResults(faces, dims);

            if (true) {
              draw.drawDetections(canvas, resizedResults);
            }
            if (true) {
              draw.drawFaceExpressions(canvas, resizedResults);
            }
            if (true) {
              draw.drawFaceLandmarks(canvas, resizedResults);
            }
            if (faces.expressions) {
              const expressions = faces.expressions;
              if (expressions.happy > 0.8) {
                setTimeout(() => {
                  setRedirect(true);
                }, 2000);
              }
            }
          } else {
            setDetected(false);
            ctx.clearRect(0, 0, video.videoWidth, video.videoHeight);
          }
        }
      } else {
        await wait(0);
        if (video2.readyState === 4) {
          const faces = await detectSingleFace(video2, options)
            .withFaceLandmarks(true)
            .withFaceExpressions();

          setLoading(false);
          if (faces) {
            setDetected(true);
            const dims = matchDimensions(canvas2, video2, true);
            const resizedResults = resizeResults(faces, dims);
            if (true) {
              draw.drawDetections(canvas2, resizedResults);
            }
            if (true) {
              draw.drawFaceExpressions(canvas2, resizedResults);
            }
            if (true) {
              draw.drawFaceLandmarks(canvas2, resizedResults);
            }
            if (faces.expressions) {
              const expressions = faces.expressions;
              if (expressions.happy > 0.8) {
                setTimeout(() => {
                  setRedirect(true);
                }, 2000);
              }
            }
          } else {
            setDetected(false);
            ctx.clearRect(0, 0, video2.videoWidth, video2.videoHeight);
          }
        }
      }

      start();
    };

    return { init, start };
  };

  const launchCamera = () => {
    try {
      new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              mandatory: {},
            },
          })
          .then(
            (stream) => {
              video.srcObject = stream;
              video.play();
              setCamera(true);
              resolve();
            },
            () => {}
          );
      });
    } catch (error) {
      console.log(error);
    }
  };
  const launchCamera2 = () => {
    try {
      new Promise((resolve) => {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              mandatory: {},
            },
          })
          .then(
            (stream) => {
              video2.srcObject = stream;
              video2.play();
              setCamera(true);
              resolve();
            },
            () => {}
          );
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      {onayModal()}
      {redirect && <Redirect to={route.homepage} />}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "none", sm: "none", lg: "flex" }}
      >
        <Image alt="img" src={enigmaLogo} />
      </Box>

      {/* Mobile */}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}
      >
        <Image maxW="125px" alt="img" src={logo} />
      </Box>
      {/* Mobile */}

      <Box
        d="flex"
        alignItems="center"
        flexDirection="column"
        display={{ base: "none", sm: "none", lg: "flex" }}
      >
        <Box>
          <Text
            top={{ lg: "60px", xl: "72px" }}
            left={{ lg: "350px", xl: "350px" }}
            position="relative"
            maxW={{ lg: "500px", xl: "635px" }}
            fontSize={{ lg: "20px", xl: "30px" }}
            color={colors.text}
          >
            İLERLEMEK İÇİN GÜLÜMSEYİN
          </Text>
          <Image alt="img" src={titleComp} />
        </Box>
        <Box height="530px">
          <Image
            id="vid1"
            pos="relative"
            zIndex="1"
            alt="img"
            src={videoBox3}
          />
          <video
            playsInline={true}
            ref={videoRef}
            style={{
              position: "relative",
              bottom: "1px",
              left: "13px",
              width: "670px",
              borderRadius: "10px",
            }}
          />
          <canvas
            style={{
              position: "relative",
              top: "-500px",
              left: "13px",
              width: "670px",
            }}
            ref={canvasRef}
          />
        </Box>
        <Box d="flex" flexDirection="column" alignItems="center">
          <Box w="700px">
            <Text
              top="40px"
              left="95px"
              position="relative"
              maxW="1000px"
              fontSize="14px"
              color={colors.text}
            >
              Görüntüleriniz sunucumuza kaydedilmemektedir!
            </Text>
            <Image alt="img" src={titleComp} />
          </Box>
          <Box d="flex" alignItems="end" zIndex="1">
            <Box d="flex" alignItems="center" flexDirection="column" mr="30px">
              <Image
                onClick={() => {
                  speechStart();
                  dispatch({
                    type: SET_BANNER_STATUS,
                    payload: true,
                  });
                  start();
                  document.getElementById("vid1").style.display = "none";
                }}
                alt="img"
                src={videRec}
                cursor="pointer"
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        d="flex"
        background="linear-gradient(#3FC2B3, #000000 50%)"
        borderRadius="20px"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}
      >
        <Box maxW="90%" margin="auto" width="100%">
          <Box mt="10px" d="flex" flexDirection="column" alignItems="center">
            <Image height="91px" alt="img" src={boxComp} width="600px" />
            <Text
              top="-80px"
              left="20px"
              position="relative"
              maxW="235px"
              fontSize="16px"
              color={colors.text}
            >
              Görüntüleriniz sunucumuza kaydedilmektedir!
            </Text>
          </Box>
          <Box d="flex" justifyContent="center">
            <Box>
              <Box height="0px">
                <canvas
                  style={{ width: "100%", position: "relative", zIndex: "1" }}
                  ref={canvasRef2}
                />
              </Box>
              <video
                playsInline={true}
                ref={videoRef2}
                style={{
                  position: "relative",
                  width: "670px",
                  borderRadius: "19px",
                }}
              />
            </Box>
          </Box>
          <Box
            d="flex"
            margin="auto"
            background="#3C9E92"
            borderRadius="4px"
            maxH="49px"
            maxW="144px"
            padding="5px"
            mt="20px"
          >
            <Image alt="img" src={dangerMobile} />
            <Text
              fontSize="9px"
              textShadow={colors.textShadow}
              position="relative"
              top="5px"
              left="10px"
              color={colors.text}
            >
              İLERLEMEK İÇİN GÜLÜMSEYİN
            </Text>
          </Box>
          <Box d="flex" justifyContent="center" alignItems="center">
            <Box d="flex" alignItems="center" flexDirection="column" mt="10px">
              <Image
                onClick={() => {
                  speechStart();
                  dispatch({
                    type: SET_BANNER_STATUS,
                    payload: true,
                  });
                  start();
                  document.getElementById("vid1").style.display = "none";
                }}
                alt="img"
                src={videRec}
                cursor="pointer"
                height={{ base: "60px" }}
              />
            </Box>
            {/* <Box className={disable ? "disabledbutton" : ""} d="flex" alignItems="center" flexDirection="column" >
                            <Text onClick={() => history.push(route.homepage)} fontSize={{ base: "15px", sm: "20px" }} textShadow={colors.textShadow} maxW="100px" position="relative" left={{ base: "-10px", sm: "-20px" }} top={{ base: "58px", sm: "85px" }} color={colors.text}>İLERLE</Text>
                            <Image onClick={() => history.push(route.homepage)} alt="img" src={mobilBtn} maxW={{ base: "200px", sm: "400px" }} />
                        </Box> */}
          </Box>
        </Box>
      </Box>
      {/* Mobile */}
    </Box>
  );
};
const wait = (time) => new Promise((resolve) => setTimeout(resolve, time));
export default Faceapi;
