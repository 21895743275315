import {
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_PROVINCE_REQUEST,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  GET_DISTRICT_REQUEST,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
  CLEAR_ERRORS,
} from "../constants/generalContants";

export const countryListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_COUNTRY_REQUEST:
      return {
        loading: true,
      };

    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        countries: action.payload,
      };

    case GET_COUNTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const provinceListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_PROVINCE_REQUEST:
      return {
        loading: true,
      };

    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        loading: false,
        provinces: action.payload,
      };

    case GET_PROVINCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const districtListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_DISTRICT_REQUEST:
      return {
        loading: true,
      };

    case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        districts: action.payload,
      };

    case GET_DISTRICT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
