import axioss from "axios";
import { API_BASE, BUNNY_KEY, BUNNY_KEY2, BUNNY_BASE, PANEL_API, VIDEO_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_VIDEOS_FAIL,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  GET_VIDEO_DETAIL_REQUEST,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_FAIL,
  POST_VIDEOS_FAIL,
  POST_VIDEOS_REQUEST,
  POST_VIDEOS_SUCCESS,
  SET_DUEL_VIDEOS
} from "../constants/videoContants";

const axios = axioss.create({
  // withCredentials: true,
  baseURL: API_BASE,
});

const axiosVideo = axioss.create({
  // withCredentials: true,
  baseURL: BUNNY_BASE,
});

const axiosVideoDetail = axioss.create({
  // withCredentials: true,
  baseURL: VIDEO_API,
});

export const getExampleVideos =
  (curentPage = 0, idToken) =>
  async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${idToken}` },
      };
      dispatch({ type: GET_VIDEOS_REQUEST });

      const { data } = await axios.get(
        `/example-video?page=${curentPage}`,
        config
      );

      dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VIDEOS_FAIL,
        payload: error,
      });
    }
  };
  
export const getVideoDetail =
  (videoId) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/*+json",
          AccessKey: BUNNY_KEY2
        },
      };
      dispatch({ type: GET_VIDEO_DETAIL_REQUEST });

      const { data } = await axiosVideoDetail.get(
        `/${videoId}`,
        config
      );

      dispatch({
        type: GET_VIDEO_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VIDEO_DETAIL_FAIL,
        payload: error,
      });
    }
  };

export const saveDuelditionVideo = async (fd) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "no-cache",
      },
    };

    const { data } = await axios.post(
      `${PANEL_API}/feedbacks/video`,
      fd,
      config
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadVideo = (fd, videoName) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/*+json",
        AccessKey: BUNNY_KEY,
      },
    };

    const config2 = {
      headers: {
        AccessKey: BUNNY_KEY,
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        dispatch({
          type: POST_VIDEOS_REQUEST,
          payload: Math.round((100 * data.loaded) / data.total),
        });
      },
    };

    await axiosVideo
      .post(`/videos`, { title: videoName }, config)
      .then((res) => {
        if (res && res.data && res.data.guid) {
          dispatch({
            type: SET_DUEL_VIDEOS,
            payload: {
              video_guid: res.data.guid,
              video_libraryId: res.data.videoLibraryId
            },
          });
          let formData = new FormData();
          formData.append("user_id", videoName);
          formData.append("video_guid", res.data.guid);
          formData.append("bunnynet_json", res.data);
          saveDuelditionVideo(formData);
          const { data } = axiosVideo.put(
            `/videos/${res.data.guid}`,
            fd,
            config2
          );

          dispatch({
            type: POST_VIDEOS_SUCCESS,
            payload: data,
          });
        }
      });
  } catch (error) {
    dispatch({
      type: POST_VIDEOS_FAIL,
      payload: error,
    });
  }
};

export const removeStreamVideo = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/*+json",
        AccessKey: BUNNY_KEY,
      },
    };   
    await axiosVideo
      .delete(`/videos/${id}`, config)
      .then((res) => {
        if (res) {
          console.log('ne döndü ress', res)
        }
      });
  } catch (error) {
    dispatch({
      type: POST_VIDEOS_FAIL,
      payload: error,
    });
  }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
