import React, { Component, Fragment } from "react"
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import route from "./constants/route"

const ProtectedRoute = ({ component: RouteComponent, ...rest }) => {
    const session = sessionStorage.getItem("userData")
    console.log('ünsaallllll', session)
    return (
        <Fragment>
            <Route
                {...rest}
                render={props => {

                    if (!session) {
                        return <Redirect to={route.signIn} />
                    }
                    return <RouteComponent {...props} />
                }}
            />
        </Fragment>
    )
}
export default ProtectedRoute