import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  List,
  ListItem,
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import ButtonRightIcon from "../components/buttonRighticon";
const KullanıcıSozlesmesi = ({ setState }) => {
  const stateUpdate = () => {
    setState();
  };

  return (
    <Modal size={"xl"} isOpen={true}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "100%", md: "65%" }}
        backgroundColor="#112B29"
        pointerEvents="all">
        <ModalHeader color="#fff" textAlign="center">
          KULLANICI SÖZLEŞMESİ
        </ModalHeader>
        <ModalBody px="40px" pointerEvents="all">
          <Box
            maxH="500px"
            px="10px"
            backgroundColor="#153639"
            border="1px"
            borderColor="#01B6A2">
            <Box
              maxH="500px"
              overflow="scroll"
              px="20px"
              py="30px"
              overflowX="hidden !important">
              <Text fontSize={{ base: "12px", md: "16px" }}>
                <p style={{ color: "#fff" }} dir="ltr">
                  Kullanım Koşulları
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Son güncellenme: 08/12/2021
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Sevgili ziyaretçimiz, lütfen
                  https://dueldition.cinegameart.online/web sitemizi ziyaret etmeden
                  önce işbu kullanım koşulları sözleşmesini dikkatlice okuyunuz.
                  Siteye erişiminiz tamamen bu sözleşmeyi kabulünüze ve bu
                  sözleşme ile belirlenen şartlara uymanıza bağlıdır. Şayet bu
                  sözleşmede yazan herhangi bir koşulu kabul etmiyorsanız,
                  lütfen siteye erişiminizi sonlandırınız. Siteye erişiminizi
                  sürdürdüğünüz takdirde, koşulsuz ve kısıtlamasız olarak, işbu
                  sözleşme metninin tamamını kabul ettiğinizin, tarafımızca
                  varsayılacağını lütfen unutmayınız.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  https://dueldition.cinegameart.online/ web sitesi Enigma Yapay
                  Zeka Dijital Sanat Teknolojileri Ve Film Yapım A. Ş.
                  tarafından yönetilmekte olup, bundan sonra SİTE olarak
                  anılacaktır. İşbu siteye ilişkin Kullanım Koşulları,
                  yayınlanmakla yürürlüğe girer. Değişiklik yapma hakkı, tek
                  taraflı olarak SİTE'ye aittir ve SİTE üzerinden güncel olarak
                  paylaşılacak olan bu değişiklikleri, tüm kullanıcılarımız
                  baştan kabul etmiş sayılır.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Gizlilik
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Gizlilik, ayrı bir sayfada, kişisel verilerinizin tarafımızca
                  işlenmesinin esaslarını düzenlemek üzere mevcuttur. SİTE'yi
                  kullandığınız takdirde, bu verilerin işlenmesinin gizlilik
                  politikasına uygun olarak gerçekleştiğini kabul edersiniz.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Hizmet Kapsamı
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve Film Yapım A.
                  Ş. olarak, sunacağımız hizmetlerin kapsamını ve niteliğini,
                  yasalar çerçevesinde belirlemekte tamamen serbest olup;
                  hizmetlere ilişkin yapacağımız değişiklikler, SİTE'de
                  yayınlanmakla yürürlüğe girmiş sayılacaktır.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Telif Hakları
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  SİTE'de yayınlanan tüm metin, kod, grafikler, logolar,
                  resimler, ses dosyaları ve kullanılan yazılımın sahibi (bundan
                  böyle ve daha sonra "içerik" olarak anılacaktır) Enigma Yapay
                  Zeka Dijital Sanat Teknolojileri Ve Film Yapım A. Ş. olup, tüm
                  hakları saklıdır. Yazılı izin olmaksızın site içeriğinin
                  çoğaltılması veya kopyalanması kesinlikle yasaktır.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Genel Hükümler
                </p>
                <ul>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      Kullanıcıların tamamı, SİTE'yi yalnızca hukuka uygun ve
                      şahsi amaçlarla kullanacaklarını ve üçüncü kişinin
                      haklarına tecavüz teşkil edecek nitelikteki herhangi bir
                      faaliyette bulunmayacağını taahhüt eder. SİTE dâhilinde
                      yaptıkları işlem ve eylemlerindeki, hukuki ve cezai
                      sorumlulukları kendilerine aittir. İşbu iş ve eylemler
                      sebebiyle, üçüncü kişilerin uğradıkları veya
                      uğrayabilecekleri zararlardan dolayı SİTE'nin doğrudan
                      ve/veya dolaylı hiçbir sorumluluğu yoktur.
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      SİTE'de mevcut bilgilerin doğruluk ve güncelliğini
                      sağlamak için elimizden geleni yapmaktayız. Lakin
                      gösterdiğimiz çabaya rağmen, bu bilgiler, fiili
                      değişikliklerin gerisinde kalabilir, birtakım farklılıklar
                      olabilir. Bu sebeple, site içerisinde yer alan bilgilerin
                      doğruluğu ve güncelliği ile ilgili tarafımızca, açık veya
                      zımni, herhangi bir garanti verilmemekte, hiçbir taahhütte
                      bulunulmamaktadır.
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      SİTE'de üçüncü şahıslar tarafından işletilen ve içerikleri
                      tarafımızca bilinmeyen diğer web sitelerine, uygulamalara
                      ve platformlara köprüler (hyperlink) bulunabilir. SİTE,
                      işlevsellik yalnızca bu sitelere ulaşımı sağlamakta olup,
                      içerikleri ile ilgili hiçbir sorumluluk kabul
                      etmemekteyiz.
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      SİTE'yi virüslerden temizlenmiş tutmak konusunda elimizden
                      geleni yapsak da, virüslerin tamamen bulunmadığı
                      garantisini vermemekteyiz. Bu nedenle veri indirirken,
                      virüslere karşı gerekli önlemi almak, kullanıcıların
                      sorumluluğundadır. Virüs vb. kötü amaçlı programlar,
                      kodlar veya materyallerin sebep olabileceği zararlardan
                      dolayı sorumluluk kabul etmemekteyiz.
                    </p>
                  </li>
                  <li dir="ltr">
                    <p style={{ color: "#fff" }} dir="ltr">
                      SİTE'de sunulan hizmetlerde, kusur veya hata olmayacağına
                      ya da kesintisiz hizmet verileceğine dair garanti
                      vermemekteyiz. SİTE'ye ve sitenin hizmetlerine veya
                      herhangi bir bölümüne olan erişiminizi önceden
                      bildirmeksizin herhangi bir zamanda sonlandırabiliriz.
                    </p>
                  </li>
                </ul>
                <p style={{ color: "#fff" }} dir="ltr">
                  Sorumluluğun Sınırlandırılması
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  SİTE'nin kullanımından doğan zararlara ilişkin sorumluluğumuz,
                  kast ve ağır ihmal ile sınırlıdır. Sözleşmenin ihlalinden
                  doğan zararlarda, talep edilebilecek toplam tazminat,
                  öngörülebilir hasarlar ile sınırlıdır. Yukarıda bahsedilen
                  sorumluluk sınırlamaları aynı zamanda insan hayatına, bedeni
                  yaralanmaya veya bir kişinin sağlığına gelebilecek zararlar
                  durumunda geçerli değildir. Hukuken mücbir sebep sayılan tüm
                  durumlarda, gecikme, ifa etmeme veya temerrütten dolayı,
                  herhangi bir tazminat yükümlülüğümüz doğmayacaktır.
                </p>
                <p style={{ color: "#fff" }} dir="ltr">
                  Uyuşmazlık Çözümü: İşbu Sözleşme'nin uygulanmasından veya
                  yorumlanmasından doğacak her türlü uyuşmazlığın çözümünde,
                  Türkiye Cumhuriyeti yasaları uygulanır; İstanbul Çağlayan Adliyesi
                  Mahkemeleri ve İcra Daireleri yetkilidir.
                </p>
                <br />
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          flexDirection="column"
          pointerEvents="all">
          <Button
            onClick={() => {
              document.getElementsByClassName(
                "chakra-portal"
              )[0].style.display = "none";
              stateUpdate();
            }}
            _hover={{ bg: colors.hover }}
            borderRadius="20px"
            color={colors.text}
            backgroundColor="#121518"
            fontSize="17px"
            textShadow="1px 2px 20px #01B6A2"
            rightIcon={<ButtonRightIcon />}>
            Tamam
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default KullanıcıSozlesmesi;
