export const GET_AUDITION_REQUEST = 'GET_AUDITION_REQUEST'
export const GET_AUDITION_SUCCESS = 'GET_AUDITION_SUCCESS'
export const GET_AUDITION_RESET = 'GET_AUDITION_RESET'
export const GET_AUDITION_FAIL = 'GET_AUDITION_FAIL'

export const GET_KVKK_REQUEST = 'GET_KVKK_REQUEST'
export const GET_KVKK_SUCCESS = 'GET_KVKK_SUCCESS'
export const GET_KVKK_RESET = 'GET_KVKK_RESET'
export const GET_KVKK_FAIL = 'GET_KVKK_FAIL'

export const POST_KVKK_REQUEST = 'POST_KVKK_REQUEST'
export const POST_KVKK_SUCCESS = 'POST_KVKK_SUCCESS'
export const POST_KVKK_RESET = 'POST_KVKK_RESET'
export const POST_KVKK_FAIL = 'POST_KVKK_FAIL'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'