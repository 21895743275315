export const GET_MENTOR_REQUEST = 'GET_MENTOR_REQUEST'
export const GET_MENTOR_SUCCESS = 'GET_MENTOR_SUCCESS'
export const GET_MENTOR_RESET = 'GET_MENTOR_RESET'
export const GET_MENTOR_FAIL = 'GET_MENTOR_FAIL'
export const GET_MENTOR_LIST_REQUEST = 'GET_MENTOR_LIST_REQUEST'
export const GET_MENTOR_LIST_SUCCESS = 'GET_MENTOR_LIST_SUCCESS'
export const GET_MENTOR_LIST_FAIL = 'GET_MENTOR_LIST_FAIL'
export const SET_SELECTED_MENTOR = 'SET_SELECTED_MENTOR'
export const GET_MENTOR_CATEGORY_REQUEST = 'GET_MENTOR_CATEGORY_LIST_REQUEST'
export const GET_MENTOR_CATEGORY_SUCCESS = 'GET_MENTOR_CATEGORY_LIST_SUCCESS'
export const GET_MENTOR_CATEGORY_FAIL = 'GET_MENTOR_CATEGORY_LIST_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CLEAR_MENTOR_LIST_ERRORS = 'CLEAR_MENTOR_LIST_ERRORS'
export const CLEAR_MENTOR_CATEGORY_ERRORS = 'CLEAR_MENTOR_LIST_ERRORS'