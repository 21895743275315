import {
  GET_VIDEOS_FAIL,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_RESET,
  GET_VIDEO_DETAIL_REQUEST,
  GET_VIDEO_DETAIL_SUCCESS,
  GET_VIDEO_DETAIL_FAIL,
  POST_VIDEOS_FAIL,
  POST_VIDEOS_REQUEST,
  POST_VIDEOS_SUCCESS,
  POST_VIDEOS_RESET,
  SET_BANNER_STATUS,
  SET_DUEL_VIDEOS,
  CLEAR_ERRORS,
} from "../constants/videoContants";

const initialState = {
  fetch: null,
  videos: {},
};
export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS_REQUEST:
    case POST_VIDEOS_REQUEST:
      return {
        loading: true,
        progress: action.payload,
        uploading: false,
      };

    case GET_VIDEOS_SUCCESS:
    case POST_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        videos: action.payload,
        progress: 0,
        uploading: true,
      };

    case GET_VIDEOS_FAIL:
    case POST_VIDEOS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        progress: 0,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
const initialStateDetail = {
  fetch: null,
  video: {},
};
export const videoDetailReducer = (state = initialStateDetail, action) => {
  switch (action.type) {
    case GET_VIDEO_DETAIL_REQUEST:
      return {
        loading: true,
        progress: action.payload,
        uploading: false,
      };

    case GET_VIDEO_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        video: action.payload,
        progress: 0,
        uploading: true,
      };

    case GET_VIDEO_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        progress: 0,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

const initialBannerStateDetail = {
  fetch: null,
  video: true,
};
export const bannerVideoReducer = (
  state = initialBannerStateDetail,
  action
) => {
  switch (action.type) {
    case SET_BANNER_STATUS:
      return {
        ...state,
        loading: false,
        video: action.payload,
      };

    default:
      return state;
  }
};

const initialDuelVideoDetail = {
  video: {},
};
export const duelVideoReducer = (
  state = initialDuelVideoDetail,
  action
) => {
  switch (action.type) {
    case SET_DUEL_VIDEOS:
      return {
        ...state,
        video: action.payload,
      };

    default:
      return state;
  }
};
