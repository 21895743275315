import React, { useState, useEffect } from 'react'
import { Box, Text } from "@chakra-ui/react"
import footericon from "../assets/images/footericon.png"
import youtube from "../assets/images/youtubeİcon.png"
import insta from "../assets/images/instaİcon.png"
import mail from "../assets/images/mailİcon.png"
import location from "../assets/images/locationİcon.png"
import { INSTAGRAM, LOCATION, MAIL, YOUTUBE } from "../config/config"
import KullanıcıSozlesmesi from "./KullanıcıSozlesmesi"
import GizlilikSozlesmesi from './GizlilikSozlesmesi'
import KvkkBilgilendirme from "./KvkkBilgilendime"
const Footericon = ({ ...props }) => {
    const [kullanıcısözlesme, setKullanıcısözlesme] = useState(false)
    const [gizliliksözleşme, setGizliliksözlesme] = useState(false)
    const [bilgilendirme, setBilgilendirme] = useState(false)
    const setState = () => {
        setKullanıcısözlesme(false)
        setGizliliksözlesme(false)
        setBilgilendirme(false)
    }

    return (
        // <Box d="flex" pt={{ base: "150px", sm: "150px", lg: "150px" }} w={{ base: "144px", sm: "144px", lg: "100%" }} margin="auto" justifyContent="center">
        //     <img alt="img" src={footericon} />
        // </Box>
        <Box id="footer" d="flex" flexDirection="row" backgroundColor="#112A2D" w="100%" mt="121px" justifyContent="center" px="10px" bottom="0 ">
            {kullanıcısözlesme && <KullanıcıSozlesmesi setState={setState} />}
            {gizliliksözleşme && <GizlilikSozlesmesi setState={setState} />}
            {bilgilendirme && <KvkkBilgilendirme setState={setState} />}
            <Box pt="20px" >
                <Box d="flex" justifyContent="space-evenly" flexDirection={{ base: "column", lg: "column", xl: "row" }} alignItems={{ base: "center", lg: "center", xl: "unset" }} >
                    <Box d="flex" alignItems={{ base: "start", lg: "end" }} flexDirection="column" maxW="430px" mb="10px">
                        <Text fontSize={{ base: "11px", lg: "15px" }} color="white" mb="8px">HER ŞEY HAKKINDA</Text>
                        <Text fontSize={{ base: "8px", lg: "10px" }} color="white">Ben 3 yıllık hazırlanma sürecinden sonra sizlerle buluşuyorum. Dünyanın ilk dijital dizi harekatının baş rolünde yer almak ve sizleri oyuncu yapabilmek için 'The A.R.G.E. of Everything' Ekibi tarafından üretildim. Oyuncu olmak için geç kalmadın, işte buradasın! Dueldition ver ve macerada sen de yerini kap! </Text>
                    </Box>
                    <Box d="flex" flexDirection="column" mx="10px">
                        <Box d="flex" w={{ base: "144px", sm: "144px", lg: "237px" }} >
                            <img alt="img" src={footericon} />
                        </Box>
                    </Box>
                    <Box d="flex" alignItems="start" flexDirection="column" mt="10px" >
                        <Box d="flex" alignItems="center" mb="5px" cursor="pointer">
                            <span style={{ display: "inline-block" }}><img alt="icon" src={insta} /></span> <a href={INSTAGRAM} target="_blank"><Text d="inline-block" fontSize={{ base: "11px", lg: "15px" }} color="white" ml="5px">@aienigmaturkey</Text></a>
                        </Box>
                        <Box d="flex" alignItems="center" mb="5px" cursor="pointer">
                            <span style={{ display: "inline-block" }}><img alt="icon" src={youtube} /></span> <a href={YOUTUBE} target="_blank"><Text d="inline-block" fontSize={{ base: "11px", lg: "15px" }} color="white" ml="5px">the arge of everythıng 2020</Text></a>
                        </Box>
                        <Box d="flex" alignItems="center" mb="5px" cursor="pointer">
                            <span style={{ display: "inline-block" }}><img alt="icon" src={mail} /></span><a href={MAIL} target="_blank"><Text d="inline-block" fontSize={{ base: "11px", lg: "15px" }} color="white" ml="5px">contact@cinegameart.online</Text></a>
                        </Box>
                    </Box>
                </Box>
                <Box d="flex" flexDirection={{ base: "column", lg: "row" }} justifyContent="center" alignItems="center" mt="26px">
                    <Text onClick={() => setGizliliksözlesme(true)} fontSize={{ base: "8px", lg: "12px" }} color="white" textDecor="underline" cursor="pointer" mr={{ base: "unset", lg: "10px" }}>GİZLİLİK SÖZLEŞMESİ</Text>
                    <Text onClick={() => setKullanıcısözlesme(true)} fontSize={{ base: "8px", lg: "12px" }} color="white" textDecor="underline" cursor="pointer">KULLANIM KOŞULLARI</Text>
                    <Text onClick={() => setBilgilendirme(true)} fontSize={{ base: "8px", lg: "12px" }} color="white" textDecor="underline" cursor="pointer" ml={{ base: "unset", lg: "10px" }}>KVKK ONAM METNİ</Text>
                </Box>
                <Box d="flex" justifyContent="center" mt="36px" mb="15px" textAlign="center">
                    <Text fontSize={{ base: "11px", lg: "15px" }} color="white">ENıGMA YAPAY ZEKA DİJİTAL SANAT TEKNOLOJİLERİ VE
                        FİLM YAPIM ANONİM ŞİRKETİ © 2021 - tüm hakları saklıdır.
                    </Text>
                </Box>
            </Box>
            <Box d={{ base: "none", lg: "flex" }} pt="50px" pr="50px" pl="10px">
                <a target="_blank" href={LOCATION}>
                    <Box d="flex" flexDirection="column" alignItems="center" maxW="266px" >
                        <Box maxW="156px"> <img alt="icon" src={location} /></Box>
                        <Text fontSize="15px" color="white">
                            Beyoğlu/İSTANBUL
                        </Text>
                    </Box>
                </a>
            </Box>
        </Box>
    )
}
export default Footericon