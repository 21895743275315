export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const GET_CODE_REQUEST = 'CODE_REQUEST'
export const GET_CODE_SUCCESS = 'CODE_SUCCESS'
export const GET_CODE_FAIL = 'CODE_FAIL'
export const GET_CODE_RESET = 'CODE_RESET'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL'

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_RESET = 'DELETE_USER_RESET'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const SET_USER_PHONE = 'SET_USER_PHONE_NUMBER'

export const SAVE_USER_ERR = 'SAVE_USER_RECORD_FAIL'

export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_LIST_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS'
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_LIST_FAIL'

export const GET_PROVINCE_REQUEST = 'GET_PROVINCE_LIST_REQUEST'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_LIST_SUCCESS'
export const GET_PROVINCE_FAIL = 'GET_PROVINCE_LIST_FAIL'

export const GET_DISTRICT_REQUEST = 'GET_DISTRICT_LIST_REQUEST'
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_LIST_SUCCESS'
export const GET_DISTRICT_FAIL = 'GET_DISTRICT_LIST_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const MAXHAK = 1