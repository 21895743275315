import axioss from "axios";
import { PANEL_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_MENTOR_LIST_REQUEST,
  GET_MENTOR_LIST_SUCCESS,
  GET_MENTOR_LIST_FAIL,
  SET_SELECTED_MENTOR,
  GET_MENTOR_CATEGORY_REQUEST,
  GET_MENTOR_CATEGORY_SUCCESS,
  GET_MENTOR_CATEGORY_FAIL,
} from "../constants/mentorContants";

const axios = axioss.create({
  // withCredentials: true,
  baseURL: PANEL_API,
});

export const getMentorList = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    dispatch({ type: GET_MENTOR_LIST_REQUEST });

    const { data } = await axios.get(`/actor-list`, config);
    dispatch({
      type: GET_MENTOR_LIST_SUCCESS,
      payload: data && data.Attr ? data.Attr : [],
    });
  } catch (error) {
    dispatch({
      type: GET_MENTOR_LIST_FAIL,
      payload: error,
    });
  }
};

export const getMentorCategoryList = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    dispatch({ type: GET_MENTOR_CATEGORY_REQUEST });

    const { data } = await axios.get(`/actor-categories/${id}`, config);
    dispatch({
      type: GET_MENTOR_CATEGORY_SUCCESS,
      payload: data && data.Attr ? data.Attr : [],
    });
  } catch (error) {
    dispatch({
      type: GET_MENTOR_CATEGORY_FAIL,
      payload: error,
    });
  }
};

export const setSelectedActor = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_MENTOR,
    payload: data,
  });
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
