import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn, clearErrors, getCode } from "../actions/userAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import enigmaLogo from "../assets/images/enigmaLogo.png";
import logo from "../assets/images/logo.png";
import phoneComp2 from "../assets/images/phoneComp.png";
import dangerComp from "../assets/images/dangerComp.png";
import verBox from "../assets/images/verBox.png";
import { Text, Box, Image, Input } from "@chakra-ui/react";
import colors from "../theme/colors";
import Timer from "../components/Backwardtimer";
import boxComp from "../assets/mobilimages/boxComp.png";
import mobilBtn from "../assets/mobilimages/mobilBtn.png";
import route from "../constants/route";
import { SET_USER_PHONE } from "../constants/userContants";
const Verification = ({ location }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [code, setCode] = useState();
  const [errors, setError] = useState({});
  const [timerStart, setTimerStart] = useState(true)
  const [timerDuration, setTimerDuration] = useState(89);
  const {
    error,
    loading,
    success,
    code: codee,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!location.state.phoneNumber) {
      history.push(route.signIn);
    }
    if (sessionStorage.getItem("userData")) {
      if(location.state.phoneNumber){
        dispatch({
          type: SET_USER_PHONE,
          payload: location.state.phoneNumber,
        });
      }
      console.log('*********sdas*ds*', sessionStorage.getItem("userData"))
      history.push(route.faceapi);
      setTimerStart(false);
      setTimerDuration(0);
    }
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
    if (success) {
      if(location.state.phoneNumber){
        dispatch({
          type: SET_USER_PHONE,
          payload: location.state.phoneNumber,
        });
      }
      history.push(route.faceapi);
    }
  }, [dispatch, error, success]);

  const valid = () => {
    const errors = {};
    if (!code) errors.code = "Doğrulama kodu boş geçilemez";
    return errors;
  };
  const onSubmit = () => {
    const errors = valid();
    setError(errors);
    if (Object.keys(errors).length === 0) {
      dispatch(signIn(location.state.phoneNumber, code));
      setTimerStart(false);
      setTimerDuration(0);
    } else {
      alert.error(errors.code);
    }
  };

  const submitPhoneVerification = (e) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };

  const resendCode = () => {
    dispatch(getCode(location.state.phoneNumber));
  };
  return (
    <Box maxW="80%" margin="auto" width="100%">
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "none", sm: "none", lg: "flex" }}>
        <Image alt="img" src={enigmaLogo} />
      </Box>
      <Text color="white" fontSize="16px">
        {codee}
      </Text>
      {/* Mobile */}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}>
        <Image maxW="125px" alt="img" src={logo} />
      </Box>
      {/* Mobile */}
      <Box mt="20px" d="flex" alignItems="center" flexDirection="column">
        <Box display={{ base: "none", sm: "none", lg: "block" }}>
          <Input
            onKeyDown={submitPhoneVerification}
            onChange={(x) => setCode(x.target.value)}
            value={code || ""}
            top="110px"
            left="230px"
            maxW="550px"
            position="relative"
            type="number"
            border="none"
            focusBorderColor="none"
            fontSize="25px"
            placeholder="Doğrulama kodunu giriniz"
            color={colors.text}
          />
          <Image maxW="1000px" alt="img" src={phoneComp2} />
        </Box>

        {/* Mobile */}
        <Box
          height={{ base: "120px", md: "180px" }}
          display={{ base: "flex", sm: "flex", lg: "none" }}
          flexDirection="column">
          <Box
            d="flex"
            position="relative"
            alignItems="center"
            top="60px"
            left="10px">
            <Input
              onKeyDown={submitPhoneVerification}
              onChange={(x) => setCode(x.target.value)}
              value={code || ""}
              fontSize="12px"
              maxW="300px"
              type="number"
              border="none"
              focusBorderColor="none"
              placeholder="Doğrulama Kodunu Giriniz"
              color={colors.text}
            />
          </Box>
          <Image maxH="71px" alt="img" src={boxComp} />
        </Box>
        {/* Mobile */}

        <Box
          height="150px"
          w={{ base: "275px", md: "unset" }}
          mt={{ base: "40px", md: "unset" }}>
          <Image alt="img" src={dangerComp} />
          <Timer
            count={timerDuration}
            videoTimer={timerStart}
            style={{ maxWidth: "180px" }}
            top={{ base: "-78px", md: "-155px" }}
            left={{ base: "110px", md: "200px" }}
          />
          <Text
            top={{ base: "-163px", md: "-300px" }}
            left={{ base: "70px", md: "205px" }}
            position="relative"
            color={colors.text}
            fontSize={{ base: "10px", md: "12px" }}
            maxW="200px"
            textAlign="center">
            DOĞRULAMA KODUNUN ONAYLANMASINA
          </Text>
        </Box>

        <Box
          className="shadow verBox"
          cursor="pointer"
          display={{ base: "none", sm: "none", lg: "block" }}>
          <Text
            onClick={onSubmit}
            cursor="pointer"
            fontSize="20px"
            position="relative"
            left="130px"
            top="95px"
            color="red">
            Telefonu Doğrula
          </Text>
          <Image onClick={onSubmit} cursor="pointer" alt="img" src={verBox} />
        </Box>
        <Box className="verBox2" d="none">
          <Box
            className="shadow"
            cursor="pointer"
            display={{ base: "none", sm: "none", lg: "block" }}>
            <Text
              onClick={onSubmit}
              cursor="pointer"
              fontSize="20px"
              position="relative"
              left="130px"
              top="95px"
              color="red">
              Tekrar Kod Yolla
            </Text>
            <Image onClick={onSubmit} cursor="pointer" alt="img" src={verBox} />
          </Box>
        </Box>
        {/* Mobile */}
        <Box
          className="verBox"
          display={{ base: "block", sm: "block", lg: "none" }}>
          <Image onClick={onSubmit} cursor="pointer" alt="img" src={mobilBtn} />
          <Text
            onClick={onSubmit}
            cursor="pointer"
            fontSize="10px"
            textShadow={colors.textShadow}
            position="relative"
            left="60px"
            top="-78px"
            maxW="200px"
            color={colors.text}>
            TELEFONU DOĞRULA
          </Text>
        </Box>
        <Box className="verBox2" d="none">
          <Box display={{ base: "block", sm: "block", lg: "none" }}>
            <Image
              onClick={resendCode}
              cursor="pointer"
              alt="img"
              src={mobilBtn}
            />
            <Text
              onClick={resendCode}
              cursor="pointer"
              fontSize="10px"
              textShadow={colors.textShadow}
              position="relative"
              left="60px"
              top="-78px"
              maxW="200px"
              color={colors.text}>
              TEKRAR KOD YOLLA
            </Text>
          </Box>
        </Box>
        {/* Mobile */}
      </Box>
    </Box>
  );
};

export default Verification;
