import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  List,
  ListItem,
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import ButtonRightIcon from "./buttonRighticon";
const KvkkModal = ({
  onClose,
  isOpen,
  kullaniciSozlesme,
  setKullaniciSozlesme,
  gizlilikSozlesme,
  setGizlilikSozlesme,
  onamContinues,
  updateKvkk,
}) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={onClose}
      size={"xl"}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "100%", md: "65%" }}
        backgroundColor="#112B29"
      >
        <ModalHeader textAlign="center">KVKK ONAM METNİ</ModalHeader>
        <ModalBody px="40px">
          <Box
            maxH="500px"
            px="10px"
            backgroundColor="#153639"
            border="1px"
            borderColor="#01B6A2"
          >
            <Box
              maxH="500px"
              overflow="scroll"
              px="20px"
              py="30px"
              overflowX="hidden !important"
            >
              <Text color="#fff" fontSize={{ base: "12px", md: "16px" }}>
                K.V.K.K. HAKKINDA BİLGİLENDİRME Enigma Yapay Zeka Dijital Sanat
                Teknolojileri Ve Film Yapım A. Ş., kişisel verilerinizin hukuka
                uygun olarak toplanması, saklanması ve paylaşılmasını sağlamak
                ve gizliliğinizi korumak amacıyla mümkün olan en üst seviyede
                güvenlik tedbirlerini almaktadır. Amacımız; 6698 sayılı “Kişisel
                Verilerin Korunması Kanunu’nun 10. maddesi gereğince ve sizlerin
                memnuniyeti doğrultusunda, kişisel verilerinizin alınma
                şekilleri, işlenme amaçları, paylaşılan kişiler, hukuki
                nedenleri ve haklarınız konularında sizi en şeffaf şekilde
                bilgilendirmektir. a) Veri Sorumlusu 6698 sayılı Kişisel
                Verilerin Korunması Kanunu (“6698 sayılı Kanun”) uyarınca,
                kişisel verileriniz; veri sorumlusu olarak Enigma Yapay Zeka
                Dijital Sanat Teknolojileri Ve Film Yapım A. Ş. (“ŞİRKET”)
                tarafından aşağıda açıklanan kapsamda toplanacak ve
                işlenebilecektir. b) Kişisel Verilerin Hangi Amaçla İşleneceği
                ŞİRKET tarafından, müşterileri, çalışanları, potansiyel
                müşterileri, çalışan adayları, iş ortakları, web sitesi ve
                mağaza ziyaretçileri ve tedarikçileri gibi taraflardan, kimlik
                bilgisi, iletişim bilgisi, müşteri bilgisi, müşteri işlem
                bilgisi, işlem güvenliği bilgisi, hukuki işlem ve uyum bilgisi
                ile pazarlama satış bilgisi gibi kategorilerde kişisel veri
                toplanabilmektedir. Toplanan kişisel verileriniz; - ŞİRKET ürün
                ve hizmetlerinin sizlere sunulabilmesi, sizlere karşı olan
                yükümlülüklerimizin yerine getirilmesi, kayıt ve belgelerin
                düzenlenebilmesi, yerel ve uluslararası yasal mevzuatın
                öngördüğü bilgi saklama, raporlama, bilgilendirme, vergi ve sair
                yükümlülüklere uymak, - Hizmet ve ürünlerin kalitesinin
                artırılmasına yönelik yapılacak olan satış ve pazarlama
                faaliyetleri için yapılacak size özel reklam, kampanya,
                avantajlar ve diğer faydaları sunmak, - Bilgi işlem
                gereksinimleri, sistemsel yapısı, alınan bilgi işlem destek
                hizmetlerinin gerekliliği, bu hizmet ve ürünlere ilişkin olarak
                sizlere gerekli bilgilerin aktarılması amacıyla iletişim kurmak,
                - Satış ve pazarlama faaliyetleri için yapılacak trafik
                ölçümleme, istatistiki analizler, Segmentasyon/profilleme ve CRM
                çalışmalarını yürütmek, - Müşteri memnuniyetinin ölçümlenmesi ve
                artırılması, şikâyet yönetimi, yeni hizmet ve ürünler ile ilgili
                görüş ve önerilerinizi almak, sorun-hata bildirimlerinizi almak,
                ürün ve hizmetlere, şikayet ve taleplerinize yönelik tarafınıza
                bilgi vermek, - Online satış ile ilgili üyeliğinizi yönetmek,
                siparişlerinizi almak, ödeme işlemlerinizi gerçekleştirmek, 3.
                kişiler ile lojistik iş birliği sağlayıp ürün gönderimini
                sağlamak, ilginizi çekebilecek ürün ve hizmetleri önermek,
                online davranışsal reklamcılık ve pazarlama, müşteri portföy
                yönetimi, hizmet kalitesinin ölçülmesi ve geliştirilmesi,
                iletişim, optimizasyon, denetim, risk yönetimi ve kontrol,
                promosyon, analiz, ilgi alanları belirleme, skorlama,
                profilleme, pazarlama, satış, reklam, iletişim - Karşılaştırmalı
                ürün ve/veya hizmet teklifi, modelleme, mevcut veya yeni ürün
                çalışmaları ve/veya geliştirmeleri, kişisel verilerinizi
                ŞİRKET’e açıklamanıza konu olan ŞİRKET ana sözleşmesinde yazılı
                olan işleri düzenleyen kanun ve ilgili mevzuat kapsamında
                sizlere sunulacak her türlü ürün ve hizmetlerde kullanılmak, -
                Resmî kurumlarca öngörülen bilgi saklama, raporlama,
                bilgilendirme yükümlülüklerine uymak, sözleşmelerin
                gerekliliklerini yerine getirmek ve bu hizmetlerden
                faydalanılmasına ilişkin olarak ŞİRKET’in tabi olduğu yasal
                yükümlülükleri ifa etmek, - ŞİRKET’in ticari ve iş
                stratejilerinin belirlenmesi ve uygulanması amacı doğrultusunda;
                ŞİRKET tarafından yürütülen finans operasyonları, iletişim,
                pazar araştırması ve sosyal sorumluluk aktiviteleri, satın alma
                operasyonları (talep, teklif, değerlendirme, sipariş,
                bütçelendirme, sözleşme), şirket içi sistem ve uygulama yönetimi
                operasyonları, hukuki operasyonları yönetmek - Resmi makamlardan
                veya sizlerden gelen talepleri incelemek, değerlendirmek ve
                yanıtlamak, amaçlarıyla 6698 sayılı Kanun’un 5. ve 6.
                maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
                dahilinde işlenecektir. c) İşlenen Kişisel Verilerin Kimlere ve
                Hangi Amaçla Aktarılabileceği Toplanan kişisel verileriniz;
                yukarıda belirtilen amaçların gerçekleştirilmesi ile sınırlı
                olmak üzere; - ŞİRKET’in iş ortaklarına, hissedarlarına,
                iştiraklerine, - Türk Ticaret Kanunu, Türk Borçlar Kanunu ve
                diğer mevzuat hükümlerinin izin verdiği kişi veya kuruluşlara, -
                Kanunen yetkili kamu kurum ve kuruluşları, idari merciler ve
                yasal mercilere, - Ürün/hizmet karşılaştırma, analiz,
                değerlendirme, reklam ve yukarıda belirtilen amaçların
                gerçekleştirilmesinde hizmet aldığımız, işbirliği yaptığımız
                gerçek veya tüzel kişilere, program ortağı kurum ve kuruluşlara,
                müşterilerimize gönderdiğimiz iletilerin gönderilmesi konusunda
                anlaşmalı olduğumuz kurumlara, online mağazamızdan verilen
                siparişlerin size teslimini gerçekleştiren kargo şirketlerine
                6698 sayılı Kanun’un 8. ve 9. maddelerinde belirtilen kişisel
                veri işleme şartları ve amaçları çerçevesinde
                aktarılabilecektir. ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki
                Sebebi Kişisel verileriniz, mağazalarımız, internet sitemiz ve
                mobil uygulamamız, çağrı merkezlerimiz, sosyal medya
                hesaplarımız gibi mecralardan sözlü, yazılı veya elektronik
                ortamda veya ilerde kurulacak/oluşabilecek diğer kanallar başta
                olmak üzere; ŞİRKET tarafından yasal mevzuat çerçevesinde
                yukarıda belirtilen amaçlarla ve sözleşmenin ifa edilmesi,
                kanundaki açıkça öngörülmüş olması, kişisel verilerinizin
                tarafınızca alenileştirilmiş olması, tarafınıza tanınacak olan
                hakkın tesisi, kullanılması veya korunması için zorunlu olması;
                sizin temel hak ve özgürlüklerinize zarar vermeyecek şekilde,
                ŞİRKET’in meşru menfaatleri ve hukuki yükümlülüğünü yerine
                getirebilmesi için zorunlu olması şeklindeki hukuki sebeplerin
                varlığıyla sınırlı olarak toplanmaktadır. d) Kişisel Veri
                Sahibi’nin 6698 sayılı Kanun’un 11. maddesinde Sayılan Hakları
                Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
                aşağıda düzenlenen yöntemlerle ŞİRKET’e iletmeniz durumunda
                ŞİRKET talebin niteliğine göre talebi en kısa sürede ve en geç
                otuz gün içinde sonuçlandıracaktır. Verilecek cevapta on sayfaya
                kadar ücret alınmayacaktır. On sayfanın üzerindeki her sayfa
                için 1 Türk Lirası işlem ücreti alınacaktır. Başvuruya cevabın
                CD, flash bellek gibi bir kayıt ortamında verilmesi halinde
                ŞİRKETİMİZ tarafından talep edilebilecek ücret kayıt ortamının
                maliyetini geçmeyecektir. Bu kapsamda kişisel veri sahipleri;
                Kişisel verilerinin işlenip işlenmediğini öğrenme, Kişisel
                verileri işlenmişse buna ilişkin bilgi talep etme, Kişisel
                verilerin işlenme amacını ve bunların amacına uygun kullanılıp
                kullanılmadığını öğrenme, Yurt içinde veya yurt dışında kişisel
                verilerin aktarıldığı üçüncü kişileri bilme, Kişisel verilerin
                eksik veya yanlış işlenmiş olması hâlinde bunların
                düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                6698 sayılı Kanun ve ilgili diğer kanun hükümlerine uygun olarak
                işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin
                ortadan kalkması halinde kişisel verilerin silinmesini veya yok
                edilmesini isteme ve bu kapsamda yapılan işlemin kişisel
                verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme, Kişisel verilerin kanuna aykırı
                olarak işlenmesi sebebiyle zarara uğraması halinde zararın
                giderilmesini talep etme haklarına sahiptir. Yukarıda belirtilen
                haklarınızı kullanma ile ilgili talebinizi, 6698 sayılı
                Kanunu’nun 13. maddesinin 1. fıkrası ve 30356 sayılı ve
                10.03.2018 tarihli Veri Sorumlusuna Başvuru Usul ve Esasları
                Hakkında Tebliğ gereğince Türkçe ve yazılı olarak veya kayıtlı
                elektronik posta (KEP) adresi, güvenli elektronik imza, mobil
                imza ya da ŞİRKET’e daha önce bildirilen ve sistemimizde kayıtlı
                bulunan elektronik posta adresini kullanmak suretiyle
                iletebilirsiniz. ŞİRKET’in cevap vermeden önce kimliğinizi
                doğrulama hakkı saklıdır. Başvurunuzda; a) Adınızın, soyadınızın
                ve başvuru yazılı ise imzanızın, b) Türkiye Cumhuriyeti
                vatandaşları için T.C. kimlik numaranızın, yabancı iseniz
                uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
                c) Tebligata esas yerleşim yeri veya iş yeri adresinizin, ç)
                Varsa bildirime esas elektronik posta adresi, telefon ve faks
                numaranızın, d) Talep konunuzun, bulunması zorunlu olup varsa
                konuya ilişkin bilgi ve belgelerin de başvuruya eklenmesi
                gerekmektedir. E-posta yoluyla yapmak istediğiniz
                başvurularınızı conatact@enigmaturkey.com e-posta adresine
                yapabilirsiniz. Talebinizin niteliğine göre bilgi ve belgelerin
                eksiksiz ve doğru olarak tarafımıza sağlanması gerekmektedir.
                İstenilen bilgi ve belgelerin gereği gibi sağlanmaması
                durumunda, ŞİRKET tarafından talebinize istinaden yapılacak
                araştırmaların tam ve nitelikli şekilde yürütülmesinde
                aksaklıklar yaşanabilecektir. Bu durumda, ŞİRKET kanuni
                haklarını saklı tuttuğunu beyan eder. Bu nedenle, başvurunuzun
                talebinizin niteliğine göre eksiksiz ve istenilen bilgileri ve
                belgeleri içerecek şekilde gönderilmesi gerekmektedir. Kullanım
                Koşulları Son güncellenme: 08/12/2021 Sevgili ziyaretçimiz,
                lütfen https://audition.cinegameart.online/web sitemizi ziyaret
                etmeden önce işbu kullanım koşulları sözleşmesini dikkatlice
                okuyunuz. Siteye erişiminiz tamamen bu sözleşmeyi kabulünüze ve
                bu sözleşme ile belirlenen şartlara uymanıza bağlıdır. Şayet bu
                sözleşmede yazan herhangi bir koşulu kabul etmiyorsanız, lütfen
                siteye erişiminizi sonlandırınız. Siteye erişiminizi
                sürdürdüğünüz takdirde, koşulsuz ve kısıtlamasız olarak, işbu
                sözleşme metninin tamamını kabul ettiğinizin, tarafımızca
                varsayılacağını lütfen unutmayınız.
                https://audition.cinegameart.online/ web sitesi Enigma Yapay Zeka
                Dijital Sanat Teknolojileri Ve Film Yapım A. Ş. tarafından
                yönetilmekte olup, bundan sonra SİTE olarak anılacaktır. İşbu
                siteye ilişkin Kullanım Koşulları, yayınlanmakla yürürlüğe
                girer. Değişiklik yapma hakkı, tek taraflı olarak SİTE'ye aittir
                ve SİTE üzerinden güncel olarak paylaşılacak olan bu
                değişiklikleri, tüm kullanıcılarımız baştan kabul etmiş sayılır.
                Gizlilik Gizlilik, ayrı bir sayfada, kişisel verilerinizin
                tarafımızca işlenmesinin esaslarını düzenlemek üzere mevcuttur.
                SİTE'yi kullandığınız takdirde, bu verilerin işlenmesinin
                gizlilik politikasına uygun olarak gerçekleştiğini kabul
                edersiniz. Hizmet Kapsamı Enigma Yapay Zeka Dijital Sanat
                Teknolojileri Ve Film Yapım A. Ş. olarak, sunacağımız
                hizmetlerin kapsamını ve niteliğini, yasalar çerçevesinde
                belirlemekte tamamen serbest olup; hizmetlere ilişkin
                yapacağımız değişiklikler, SİTE'de yayınlanmakla yürürlüğe
                girmiş sayılacaktır. Telif Hakları SİTE'de yayınlanan tüm metin,
                kod, grafikler, logolar, resimler, ses dosyaları ve kullanılan
                yazılımın sahibi (bundan böyle ve daha sonra "içerik" olarak
                anılacaktır) Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve
                Film Yapım A. Ş. olup, tüm hakları saklıdır. Yazılı izin
                olmaksızın site içeriğinin çoğaltılması veya kopyalanması
                kesinlikle yasaktır. Genel Hükümler Kullanıcıların tamamı,
                SİTE'yi yalnızca hukuka uygun ve şahsi amaçlarla
                kullanacaklarını ve üçüncü kişinin haklarına tecavüz teşkil
                edecek nitelikteki herhangi bir faaliyette bulunmayacağını
                taahhüt eder. SİTE dâhilinde yaptıkları işlem ve eylemlerindeki,
                hukuki ve cezai sorumlulukları kendilerine aittir. İşbu iş ve
                eylemler sebebiyle, üçüncü kişilerin uğradıkları veya
                uğrayabilecekleri zararlardan dolayı SİTE'nin doğrudan ve/veya
                dolaylı hiçbir sorumluluğu yoktur. SİTE'de mevcut bilgilerin
                doğruluk ve güncelliğini sağlamak için elimizden geleni
                yapmaktayız. Lakin gösterdiğimiz çabaya rağmen, bu bilgiler,
                fiili değişikliklerin gerisinde kalabilir, birtakım farklılıklar
                olabilir. Bu sebeple, site içerisinde yer alan bilgilerin
                doğruluğu ve güncelliği ile ilgili tarafımızca, açık veya zımni,
                herhangi bir garanti verilmemekte, hiçbir taahhütte
                bulunulmamaktadır. SİTE'de üçüncü şahıslar tarafından işletilen
                ve içerikleri tarafımızca bilinmeyen diğer web sitelerine,
                uygulamalara ve platformlara köprüler (hyperlink) bulunabilir.
                SİTE, işlevsellik yalnızca bu sitelere ulaşımı sağlamakta olup,
                içerikleri ile ilgili hiçbir sorumluluk kabul etmemekteyiz.
                SİTE'yi virüslerden temizlenmiş tutmak konusunda elimizden
                geleni yapsak da, virüslerin tamamen bulunmadığı garantisini
                vermemekteyiz. Bu nedenle veri indirirken, virüslere karşı
                gerekli önlemi almak, kullanıcıların sorumluluğundadır. Virüs
                vb. kötü amaçlı programlar, kodlar veya materyallerin sebep
                olabileceği zararlardan dolayı sorumluluk kabul etmemekteyiz.
                SİTE'de sunulan hizmetlerde, kusur veya hata olmayacağına ya da
                kesintisiz hizmet verileceğine dair garanti vermemekteyiz.
                SİTE'ye ve sitenin hizmetlerine veya herhangi bir bölümüne olan
                erişiminizi önceden bildirmeksizin herhangi bir zamanda
                sonlandırabiliriz. Sorumluluğun Sınırlandırılması SİTE'nin
                kullanımından doğan zararlara ilişkin sorumluluğumuz, kast ve
                ağır ihmal ile sınırlıdır. Sözleşmenin ihlalinden doğan
                zararlarda, talep edilebilecek toplam tazminat, öngörülebilir
                hasarlar ile sınırlıdır. Yukarıda bahsedilen sorumluluk
                sınırlamaları aynı zamanda insan hayatına, bedeni yaralanmaya
                veya bir kişinin sağlığına gelebilecek zararlar durumunda
                geçerli değildir. Hukuken mücbir sebep sayılan tüm durumlarda,
                gecikme, ifa etmeme veya temerrütten dolayı, herhangi bir
                tazminat yükümlülüğümüz doğmayacaktır. Uyuşmazlık Çözümü: İşbu
                Sözleşme'nin uygulanmasından veya yorumlanmasından doğacak her
                türlü uyuşmazlığın çözümünde, Türkiye Cumhuriyeti yasaları
                uygulanır; İstanbul Çağlayan Mahkemesi ve İcra Daireleri
                yetkilidir. Gizlilik Politikası Son güncellenme: 08/12/2021
                Güvenliğiniz bizim için önemli. Bu sebeple bizimle
                paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır.
                Biz, Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve Film Yapım
                A. Ş., veri sorumlusu olarak, bu gizlilik ve kişisel verilerin
                korunması politikası ile, hangi kişisel verilerinizin hangi
                amaçla işleneceği, işlenen verilerin kimlerle ve neden
                paylaşılabileceği, veri işleme yöntemimiz ve hukuki sebeplerimiz
                ile; işlenen verilerinize ilişkin haklarınızın neler olduğu
                hususunda sizleri aydınlatmayı amaçlıyoruz. Toplanan Kişisel
                Verileriniz, Toplanma Yöntemi ve Hukuki Sebebi IP adresiniz ve
                kullanıcı aracısı bilgileriniz, sadece analiz yapmak amacıyla ve
                çerezler (cookies) vb. teknolojiler vasıtasıyla, otomatik veya
                otomatik olmayan yöntemlerle ve bazen de analitik sağlayıcılar,
                reklam ağları, arama bilgi sağlayıcıları, teknoloji
                sağlayıcıları gibi üçüncü taraflardan elde edilerek,
                kaydedilerek, depolanarak ve güncellenerek, aramızdaki hizmet ve
                sözleşme ilişkisi çerçevesinde ve süresince, meşru menfaat
                işleme şartına dayanılarak işlenecektir. Kişisel Verilerinizin
                İşlenme Amacı Bizimle paylaştığınız kişisel verileriniz sadece
                analiz yapmak suretiyle; sunduğumuz hizmetlerin gerekliliklerini
                en iyi şekilde yerine getirebilmek, bu hizmetlere sizin
                tarafınızdan ulaşılabilmesini ve maksiumum düzeyde
                faydalanılabilmesini sağlamak, hizmetlerimizi, ihtiyaçlarınız
                doğrultusunda geliştirebilmek ve sizleri daha geniş kapsamlı
                hizmet sağlayıcıları ile yasal çerçeveler içerisinde
                buluşturabilmek ve kanundan doğan zorunlulukların (kişisel
                verilerin talep halinde adli ve idari makamlarla paylaşılması)
                yerine getirilebilmesi amacıyla, sözleşme ve hizmet süresince,
                amacına uygun ve ölçülü bir şekilde işlenecek ve
                güncellenecektir. Toplanan Kişisel Verilerin Kimlere ve Hangi
                Amaçlarla Aktarılabileceği Bizimle paylaştığınız kişisel
                verileriniz; faaliyetlerimizi yürütmek üzere hizmet aldığımız
                ve/veya verdiğimiz, sözleşmesel ilişki içerisinde
                bulunduğumuz,iş birliği yaptığımız, yurt içi ve yurt dışındaki
                3. şahıslar ile kurum ve kuruluşlara ve talep halinde adli ve
                idari makamlara, gerekli teknik ve idari önlemler alınması
                koşulu ile aktarılabilecektir. Kişisel Verileri İşlenen Kişi
                Olarak Haklarınız KVKK madde 11 uyarınca herkes, veri
                sorumlusuna başvurarak aşağıdaki haklarını kullanabilir: Kişisel
                veri işlenip işlenmediğini öğrenme, Kişisel verileri işlenmişse
                buna ilişkin bilgi talep etme, Kişisel verilerin işlenme amacını
                ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme, Kişisel verilerin eksik veya yanlış
                işlenmiş olması hâlinde bunların düzeltilmesini isteme, Kişisel
                verilerin silinmesini veya yok edilmesini isteme, (e) ve (f)
                bentleri uyarınca yapılan işlemlerin, kişisel verilerin
                aktarıldığı üçüncü kişilere bildirilmesini isteme, İşlenen
                verilerin münhasıran otomatik sistemler vasıtasıyla analiz
                edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
                çıkmasına itiraz etme, Kişisel verilerin kanuna aykırı olarak
                işlenmesi sebebiyle zarara uğraması hâlinde zararın
                giderilmesini talep etme, haklarına sahiptir. Yukarıda sayılan
                haklarınızı kullanmak üzere contact@enigmaturkey.com üzerinden
                bizimle iletişime geçebilirsiniz. İletişim Sizlere hizmet
                sunabilmek amaçlı analizler yapabilmek için, sadece gerekli olan
                kişisel verilerinizin, işbu gizlilik ve kişisel verilerin
                işlenmesi politikası uyarınca işlenmesini, kabul edip etmemek
                hususunda tamamen özgürsünüz. Siteyi kullanmaya devam ettiğiniz
                takdirde kabul etmiş olduğunuz tarafımızca varsayılacak olup,
                daha ayrıntılı bilgi için bizimle contact@enigmaturkey.com
                e-mail adresi üzerinden iletişime geçmekten lütfen çekinmeyiniz.
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent="center" flexDirection="column">
          <Box mb="20px">
            <Text d="flex" color="#fff">
              <Checkbox
                pr="10px"
                outline="none"
                defaultChecked={kullaniciSozlesme}
                onChange={setKullaniciSozlesme}
              />{" "}
              Kullanıcı sözleşmesini okudum
            </Text>
            <Text d="flex" color="#fff">
              <Checkbox
                pr="10px"
                outline="none"
                defaultChecked={gizlilikSozlesme}
                onChange={setGizlilikSozlesme}
              />{" "}
              Gizlilik sözleşmesini okudum
            </Text>
          </Box>
          <Button
            className={
              onamContinues && kullaniciSozlesme && gizlilikSozlesme
                ? ""
                : "disabledbutton"
            }
            onClick={() => {
              onClose();
              updateKvkk();
            }}
            _hover={{ bg: colors.hover }}
            borderRadius="20px"
            color={colors.text}
            backgroundColor="#121518"
            fontSize="17px"
            textShadow="1px 2px 20px #01B6A2"
            rightIcon={<ButtonRightIcon />}
          >
            ONAYLIYORUM
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default KvkkModal;
