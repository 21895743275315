import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authReducer, userPhoneNumberReducer } from "./userReducer";
import {
  videoReducer,
  videoDetailReducer,
  bannerVideoReducer,
  duelVideoReducer,
} from "./videoReducer";
import { auditionReducer, kvkkReducer } from "./auditionReducer";
import {
  mentorReducer,
  mentorListReducer,
  selMentorReducer,
  mentorCategoryListReducer,
} from "./mentorReducer";
import { chatReducer } from "./chatReducer";
import {
  countryListReducer,
  districtListReducer,
  provinceListReducer,
} from "./generalReducer";
import {
  artiztReducer,
  artiztListReducer,
  artiztCategoryListReducer,
  selArtiztReducer
} from './artiztReducer'
import { advertListReducer, advertReducer, selAdvertReducer } from "./advertReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: "",
};

const rootReducer = combineReducers({
  auth: authReducer,
  videos: videoReducer,
  videoDetail: videoDetailReducer,
  bannerVideo: bannerVideoReducer,
  audition: auditionReducer,
  mentor: mentorReducer,
  mentorList: mentorListReducer,
  mentorCat: mentorCategoryListReducer,
  activeMentor: selMentorReducer,
  advert: advertReducer,
  advertList: advertListReducer,
  activeAdvert: selAdvertReducer,
  artizt: artiztReducer,
  artiztList: artiztListReducer,
  artiztCat: artiztCategoryListReducer,
  activeArtizt: selArtiztReducer,
  kvkk: kvkkReducer,
  chatApi: chatReducer,
  userPhone: userPhoneNumberReducer,
  duelVideoDetail: duelVideoReducer,
  countryList: countryListReducer,
  provinceList: provinceListReducer,
  districtList: districtListReducer,
});

export default persistReducer(persistConfig, rootReducer);

// export default combineReducers({

// })
