import {
    POST_CHAT_SUCCESS,
    POST_CHAT_REQUEST,
    POST_CHAT_FAIL,
    POST_CHAT_RESET,
    CLEAR_ERRORS
} from '../constants/chatContants'

const initialState = {
    fetch: null,
    chatBotText: {}
}
export const chatReducer = (state = initialState, action) => {
    switch (action.type) {

        case POST_CHAT_REQUEST:
            return {
                loading: true,
            }

        case POST_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                chatBotText: action.payload
            }

        case POST_CHAT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case POST_CHAT_RESET:
            return {
                ...state,
                chatBotText: null
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}