export const GET_COUNTRY_REQUEST = 'GET_COUNTRY_LIST_REQUEST'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS'
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_LIST_FAIL'

export const GET_PROVINCE_REQUEST = 'GET_PROVINCE_LIST_REQUEST'
export const GET_PROVINCE_SUCCESS = 'GET_PROVINCE_LIST_SUCCESS'
export const GET_PROVINCE_FAIL = 'GET_PROVINCE_LIST_FAIL'

export const GET_DISTRICT_REQUEST = 'GET_DISTRICT_LIST_REQUEST'
export const GET_DISTRICT_SUCCESS = 'GET_DISTRICT_LIST_SUCCESS'
export const GET_DISTRICT_FAIL = 'GET_DISTRICT_LIST_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const MAXHAK = 1

export const GET_QRURL_FAIL = 'GET_QRCODE_FAIL'