import React, { Fragment, useEffect } from "react";
import { Box, Text } from "@chakra-ui/layout";
import route from "../constants/route";
const BackwardTimer = ({ count, videoTimer = false, ...props }) => {
  let timerInterval;
  let timerIntervalMs;
  let counter = count;
  let ms = 99;

  function startTimer() {
    counter = counter - 1;
    if (counter == 0 || !videoTimer) {
      clearInterval(timerInterval);
      clearInterval(timerIntervalMs);
      if (document.getElementById("counterMs")) {
        document.getElementById("counterMs").innerHTML = ",0";
      }
      if (window.location.pathname == route.verif) {
        document.getElementsByClassName("verBox")[0].style.display = "none";
        document.getElementsByClassName("verBox")[1].style.display = "none";
        document.getElementsByClassName("verBox2")[0].style.display = "block";
        document.getElementsByClassName("verBox2")[1].style.display = "block";
      }
    }
    if (document.getElementById("counter")) {
      document.getElementById("counter").innerHTML = counter;
    }
  }

  useEffect(() => {
    if(!videoTimer){
        clearInterval(timerInterval);
        clearInterval(timerIntervalMs);
    }

  }, [videoTimer]);

  function startTimerMs() {
    ms = ms - 1;
    if (ms == 0) {
      ms = 99;
    }
    if (document.getElementById("counterMs")) {
      document.getElementById("counterMs").innerHTML = "," + ms;
    }
  }
  useEffect(() => {
    if (counter && counter != 0 && videoTimer) {
      if (!timerInterval) timerInterval = setInterval(startTimer, 1000);
      setTimeout(() => {
        if (!timerIntervalMs && videoTimer) timerIntervalMs = setInterval(startTimerMs, 10);
      }, 990);
    }
    return () => {
      clearInterval(timerInterval);
      clearInterval(timerIntervalMs);
    };
  }, []);

  return (
    <Box {...props} position="relative" d="flex" flexDirection="column">
      <Box
        d="flex"
        flexDirection="row"
        alignItems={{ base: "baseline", md: videoTimer ? "baseline" : "end" }}
      >
        {!videoTimer ? (
          <Fragment>
            <Text
              id="counter"
              height={{ base: "10px", md: "115px" }}
              fontSize={{ base: "45px", md: "93px" }}
              color="#269E8B"
            >
              {counter}
            </Text>
            <Text
              id="counterMs"
              fontSize={{ base: "11px", md: "27px" }}
              color="#269E8B"
            >
              {ms}
            </Text>
          </Fragment>
        ) : (
          <Fragment>
            <Text
              id="counter"
              height={{ base: "10px", md: "115px" }}
              fontSize={{ base: "45px", md: "50px" }}
              color="#269E8B"
            >
              {counter}
            </Text>
            {
              <Text
                id="counterMs"
                fontSize={{ base: "11px", md: "17px" }}
                color="#269E8B"
              >
                {ms}
              </Text>
            }
          </Fragment>
        )}
      </Box>
      {!videoTimer && (
        <Box d="flex" justifyContent={{ base: "center", md: "end" }}>
          <Text fontSize={{ base: "11px", md: "13px" }} color="#269E8B">
            SANİYE KALDI
          </Text>
        </Box>
      )}
    </Box>
  );
};
export default BackwardTimer;
