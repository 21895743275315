import {
  GET_ADVERT_FAIL,
  GET_ADVERT_REQUEST,
  GET_ADVERT_SUCCESS,
  CLEAR_ERRORS,
  GET_ADVERT_LIST_REQUEST,
  GET_ADVERT_LIST_SUCCESS,
  GET_ADVERT_LIST_FAIL,
  CLEAR_ADVERT_LIST_ERRORS,
  SET_SELECTED_ADVERT,
  GET_ADVERT_CATEGORY_REQUEST,
  GET_ADVERT_CATEGORY_SUCCESS,
  GET_ADVERT_CATEGORY_FAIL,
  CLEAR_ADVERT_CATEGORY_ERRORS,
} from "../constants/advertContants";

const initialState = {
  fetch: null,
  adverts: {},
};
export const advertReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADVERT_REQUEST:
      return {
        loading: true,
      };

    case GET_ADVERT_SUCCESS:
      return {
        ...state,
        loading: false,
        adverts: action.payload,
      };

    case GET_ADVERT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const advertListReducer = (state = [], action) => {
  switch (action.type) {
    case GET_ADVERT_LIST_REQUEST:
      return {
        loading: true,
      };

    case GET_ADVERT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adverts: action.payload,
      };

    case GET_ADVERT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ADVERT_LIST_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};



export const selAdvertReducer = (state = "", action) => {
  switch (action.type) {
    case SET_SELECTED_ADVERT:
      return {
        ...state,
        loading: false,
        activeAdvert: action.payload,
      };

    default:
      return state;
  }
};
