import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMentors, clearErrors } from "../actions/mentorAction"
import { useAlert } from 'react-alert'
import enigmaLogo from "../assets/images/enigmaLogo.png"
import verBox2 from "../assets/images/verBox2.png"
import box1 from "../assets/images/box1.png"
import { Text, Box, Image } from "@chakra-ui/react"
import colors from "../theme/colors"
import boxComp from "../assets/mobilimages/boxComp.png"
import logo from "../assets/images/logo.png"
import InfiniteScroll from "react-infinite-scroll-component";
const RequestForm = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const { mentors, error } = useSelector(state => state.mentor);

    const [allmentors, setAllmentor] = useState(mentors && mentors.content)
    const [hasMore, sethasMore] = useState(true)
    const user = JSON.parse(sessionStorage.getItem("userData"))
    useEffect(() => {
        if (user.idToken) {
            dispatch(getMentors(0, user.idToken))
        }
    }, [dispatch])

    useEffect(() => {
        if (error) {
            alert.error(error.message);
            dispatch(clearErrors());
        }
    }, [dispatch, error])

    const fetchMoreData = () => {
        if (allmentors.length >= 500) {
            sethasMore(false)
            return;
        }
        setTimeout(() => {
            for (let i = 0; i < mentors.content.length; i++) {
                setAllmentor(oldArray => [...oldArray, mentors.content])
            }
        }, 500);
    };


    return (
        <Box>
            <Box pt="20px" d="flex" justifyContent="center" display={{ base: "none", sm: "none", lg: "flex" }} >
                <Image alt="img" src={enigmaLogo} />
            </Box>

            {/* Mobile */}
            <Box pt="20px" d="flex" justifyContent="center" display={{ base: "flex", sm: "flex", lg: "none" }}>
                <Image maxW="125px" alt="img" src={logo} />
            </Box>
            {/* Mobile */}

            <Box display={{ base: "none", sm: "none", lg: "flex" }} height="668" w="861px" borderRadius="30px" margin="auto" mt="50px" background='linear-gradient(#06b8a354, #020202 150%)' alignItems="center" flexDirection="column">
                <Box d="flex" height="200px">
                    <Box>
                        <Image alt="img" src={verBox2} />
                        <Text maxW="480px" fontSize="28px" position="relative" left="140px" bottom="160px" color={colors.text}>Kendi Duellonu Yarat</Text>
                    </Box>
                </Box>
                <Box d="flex" mt="48px">
                    <Box d="flex" overflowX="hidden !important" flexFlow="wrap" maxW="650px" maxH="400px" overflow="auto">
                        {mentors && mentors.content && mentors.content.length > 0 ?
                            <InfiniteScroll
                                style={{ display: "flex", flexFlow: "wrap", }}
                                dataLength={mentors.length}
                                next={fetchMoreData}
                                hasMore={hasMore}
                                loader={<Text w="600px" color="wheat">Loading...</Text>}
                                height={400}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Hepsi Bu kadar</b>
                                    </p>
                                }
                            >
                                {allmentors.map && allmentors.map((i, index) => (
                                    <Box mb="20px" minW="100px">
                                        <Image alt="img" src={box1} />
                                    </Box>
                                ))}
                            </InfiniteScroll>
                            :
                            <Text color="white" fontSize="25px">ÇOK </Text>
                        }
                    </Box>
                </Box>
            </Box>

            {/* Mobile */}
            <Box
                d="flex"
                background="linear-gradient(#3FC2B3, #000000 50%)"
                borderRadius="20px"
                justifyContent="center"
                display={{ base: "flex", sm: "flex", lg: "none" }}
            >
                <Box
                    maxW="90%"
                    margin="auto"
                    width="100%"
                >
                    <Box background="black" borderRadius="20px" mt="50px" alignItems="center" d="flex" flexDirection="column">
                        <Box mt="20px" d="flex" flexDirection="column" maxW="80%">
                            <Image height="91px" alt="img" src={boxComp} />
                            <Text top="-55px" left="90px" position="relative" maxW="200px" fontSize="15px" color={colors.text} >Mentor Eğiitmleri</Text>
                        </Box>
                        <Box d="flex" maxW="390px">
                            <Box d="flex" flexFlow="wrap" >
                                {mentors && mentors.content && mentors.content.length > 0 ?
                                    <InfiniteScroll
                                        style={{ display: "flex", flexFlow: "wrap", }}
                                        dataLength={mentors.length}
                                        next={fetchMoreData}
                                        hasMore={hasMore}
                                        loader={<Text w="600px" color="wheat">Loading...</Text>}
                                        height={400}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Hepsi Bu kadar</b>
                                            </p>
                                        }
                                    >
                                        {allmentors.map && allmentors.map((i, index) => (
                                            <Box mb="20px" maxW="130px">
                                                <Image alt="img" src={box1} />
                                            </Box>
                                        ))}
                                    </InfiniteScroll> :
                                    <Text ccolor={colors.text} fontSize="25px">ÇOK </Text>

                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Mobile */}

           
        </Box >


    )
}
export default RequestForm