import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import logo2 from "../assets/images/logo2.png";
import famous from "../assets/images/oyuncular.PNG";
import artizt from "../assets/images/artiztler.PNG";
import banner from "../assets/images/reklam.PNG";
import {
  Text,
  Box,
  Image,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import route from "../constants/route";
import { artiztId } from "../config/config";

import ButtonRightIcon from "../components/buttonRighticon";
const HomePage = () => {
  const history = useHistory();
  const [loop, setLoop] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (window.location.pathname == route.homepage) {
      if (loop) {
        const text = () => {
          speech("hoş geldin");
          document.querySelector("html").onclick = () => undefined;
        };
        document.querySelector("html").onclick = () => text();
      } else {
        document.querySelector("html").onclick = () => undefined;
      }
    }
    return () => console.log();
  }, [loop]);

  const speech = (text) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);
    const startFunction = () => {
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      window.removeEventListener("start", startFunction);
      window.removeEventListener("end", stopFunction);
    };
  };

  const asSoonModal = () => {
    return (
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        size={"xl"}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW="55%" backgroundColor="#112B29" py="10px">
          <ModalHeader
            fontSize={{ base: "10px", lg: "20px" }}
            style={{ textShadow: "1px 2px 2px white" }}
            textAlign="center"
          >
            ÇOK YAKINDA SİZLERLEYİZ...
          </ModalHeader>
          <ModalFooter
            justifyContent="center"
            flexDirection={{ base: "column", lg: "row" }}
          >
            <Button
              onClick={() => {
                onClose();
              }}
              _hover={{ bg: colors.hover }}
              mr={{ base: "unset", lg: "20px" }}
              mb={{ base: "10px", lg: "unset" }}
              borderRadius="20px"
              color={colors.text}
              backgroundColor="#121518"
              fontSize={{ base: "10px", lg: "17px" }}
              textShadow="1px 2px 20px #01B6A2"
              rightIcon={<ButtonRightIcon />}
            >
              KAPAT
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <Box maxW="80%" margin="auto" width="100%">
      {asSoonModal()}
      <Box mb="15px" d="flex" justifyContent="center">
        <Image width={{ base: "10%" }} alt="img" src={logo2} />
      </Box>
      <Box mb="15px" d="flex" justifyContent="center">
        <Text
          color={colors.text}
          textShadow={colors.textShadow}
          fontSize="20px"
          onClick={() => history.push(route.homepage)}
        >
          DUELLONU SEÇ
        </Text>
      </Box>
      <Box
        display={{ base: "none", sm: "none", lg: "flex" }}
        maxW="100%"
        className="row"
        justifyContent="space-between"
      >
        <Box
          display={{ base: "none", sm: "none", lg: "block" }}
          maxW="100%"
          className="row"
          justifyContent="center"
          mr="20px"
        >
          <Box mb="35px" className="row" d="inline" justifyContent="center">
            <Image
              width={{ base: "100%" }}
              minHeight="499.078px"
              alt="img"
              src={famous}
            />
          </Box>
          <Box mt="35px" d="flex" className="row" justifyContent="center">
            <Button
              onClick={() => history.push(route.actors)}
              _hover={{ bg: colors.hover }}
              justifyContent="center"
              color={colors.text}
              backgroundColor="transparent"
              borderColor="#6be4c8"
              border="2px solid #6be4c8"
              borderRadius="20px"
              fontSize="20px"
              textShadow="1px 2px 20px #01B6A2"
              w="80%"
              h="60px"
              // rightIcon={<ButtonRightIcon />}
            >
              ÜNLÜLERİMİZ
            </Button>
          </Box>
        </Box>

        <Box
          display={{ base: "none", sm: "none", lg: "block" }}
          maxW="100%"
          className="row"
          justifyContent="center"
          mr="20px"
        >
          <Box mb="35px" className="row" d="inline" justifyContent="center">
            <Image
              width={{ base: "100%" }}
              minHeight="499.078px"
              alt="img"
              src={artizt}
            />
          </Box>
          <Box mt="35px" d="flex" className="row" justifyContent="center">
            <Button
              onClick={() => history.push(route.artizts)}
              _hover={{ bg: colors.hover }}
              justifyContent="center"
              color={colors.text}
              backgroundColor="transparent"
              borderColor="#6be4c8"
              border="2px solid #6be4c8"
              borderRadius="20px"
              fontSize="20px"
              textShadow="1px 2px 20px #01B6A2"
              w="80%"
              h="60px"
              // rightIcon={<ButtonRightIcon />}
            >
              ARTİ"Z"TLERİMİZ
            </Button>
          </Box>
        </Box>
        <Box
          display={{ base: "none", sm: "none", lg: "block" }}
          maxW="100%"
          className="row"
          justifyContent="center"
          mr="20px"
        >
          <Box mb="35px" className="row" d="inline" justifyContent="center">
            <Image
              width={{ base: "100%" }}
              minHeight="499.078px"
              alt="img"
              src={banner}
            />
          </Box>
          <Box mt="35px" d="flex" className="row" justifyContent="center">
            <Button
              onClick={() => history.push(route.brands)}
              _hover={{ bg: colors.hover }}
              justifyContent="center"
              color={colors.text}
              backgroundColor="transparent"
              borderColor="#6be4c8"
              border="2px solid #6be4c8"
              borderRadius="20px"
              fontSize="20px"
              textShadow="1px 2px 20px #01B6A2"
              w="80%"
              h="60px"
              // rightIcon={<ButtonRightIcon />}
            >
              MARKALARIMIZ
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Mobil */}
      <Box
        alignItems="center"
        d="flex"
        flexDirection="column"
        display={{ base: "flex", sm: "flex", lg: "none" }}
        maxW="100%"
        justifyContent="center"
      >
        <Box
          overflowX="hidden !important"
          overflow="scroll"
          maxW="100%"
          maxH="230px"
          // left="320px"
          position="relative"
        >
          <Button
            onClick={() => history.push(route.actors)}
            _hover={{ bg: colors.hover }}
            justifyContent="center"
            color={colors.text}
            backgroundColor="transparent"
            borderColor="#6be4c8"
            border="2px solid #6be4c8"
            borderRadius="20px"
            fontSize="14px"
            textShadow="1px 2px 20px #01B6A2"
            w="90%"
            h="50px"
            mb="10px"
            // rightIcon={<ButtonRightIcon />}
          >
            ÜNLÜLERİMİZ
          </Button>

          <Button
            onClick={() => history.push(route.artizts)}
            _hover={{ bg: colors.hover }}
            justifyContent="center"
            color={colors.text}
            backgroundColor="transparent"
            borderColor="#6be4c8"
            border="2px solid #6be4c8"
            borderRadius="20px"
            fontSize="14px"
            textShadow="1px 2px 20px #01B6A2"
            w="90%"
            h="50px"
            mb="10px"
          >
            ARTİ"Z"TLERİMİZ
          </Button>

          <Button
            onClick={() => history.push(route.brands)}
            _hover={{ bg: colors.hover }}
            justifyContent="center"
            color={colors.text}
            backgroundColor="transparent"
            borderColor="#6be4c8"
            border="2px solid #6be4c8"
            borderRadius="20px"
            fontSize="14px"
            textShadow="1px 2px 20px #01B6A2"
            w="90%"
            h="50px"
            mb="10px"
          >
            MARKALARIMIZ
          </Button>
        </Box>
      </Box>
      {/* Mobil */}
    </Box>
  );
};
export default HomePage;
