export const GET_ADVERT_REQUEST = 'GET_ADVERT_REQUEST'
export const GET_ADVERT_SUCCESS = 'GET_ADVERT_SUCCESS'
export const GET_ADVERT_RESET = 'GET_ADVERT_RESET'
export const GET_ADVERT_FAIL = 'GET_ADVERT_FAIL'
export const GET_ADVERT_LIST_REQUEST = 'GET_ADVERT_LIST_REQUEST'
export const GET_ADVERT_LIST_SUCCESS = 'GET_ADVERT_LIST_SUCCESS'
export const GET_ADVERT_LIST_FAIL = 'GET_ADVERT_LIST_FAIL'
export const SET_SELECTED_ADVERT = 'SET_SELECTED_ADVERT'
export const GET_ADVERT_CATEGORY_REQUEST = 'GET_ADVERT_CATEGORY_LIST_REQUEST'
export const GET_ADVERT_CATEGORY_SUCCESS = 'GET_ADVERT_CATEGORY_LIST_SUCCESS'
export const GET_ADVERT_CATEGORY_FAIL = 'GET_ADVERT_CATEGORY_LIST_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const CLEAR_ADVERT_LIST_ERRORS = 'CLEAR_ADVERT_LIST_ERRORS'
export const CLEAR_ADVERT_CATEGORY_ERRORS = 'CLEAR_ADVERT_LIST_ERRORS'