import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCode, clearErrors } from "../actions/userAction";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import btnimage from "../assets/images/btn.png";
import banner from "../assets/images/homeBanner.jpeg";
import phoneComp from "../assets/images/phoneComp.png";
import boxComp from "../assets/mobilimages/boxComp.png";
import mobilBtn from "../assets/mobilimages/mobilBtn.png";
import { Text, Box, Image } from "@chakra-ui/react";
import colors from "../theme/colors";
import font from "../theme/font";
import route from "../constants/route";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { GET_CODE_RESET } from "../constants/userContants";
const Signin = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errors, setError] = useState({});
  const [loop, setLoop] = useState(true);
  const { error, loading, success } = useSelector((state) => state.auth);
  const [spoken, setSpoken] = useState(false)
  let obj = document.querySelector("html");


  useEffect(() => {
    if (
      window.location.pathname == route.signIn ||
      window.location.pathname == "/"
    ) {
      if (loop) {
        const text = () => {
          if(!spoken){
            obj.onmousemove = () => undefined
            setSpoken(true)
            speech(
              "Yeni bir maceraya hoş geldin. Başlamak için telefon numaranı gir."
              );
          }
          
        };
        obj.onmousemove  = () => text();
      } else {
        document.querySelector("html").onmousemove = () => undefined;
      } 
      if (loop) {
        const textMobil = () => {
          if(!spoken){
            obj.ontouchstart = () => undefined
            setSpoken(true)
            speech(
              "Yeni bir maceraya hoş geldin. Başlamak için telefon numaranı gir."
              );
          }
          
        };
        obj.ontouchstart  = () => textMobil();
      } else {
        document.querySelector("html").ontouchstart = () => undefined;
      }
    }
  }, [loop]);

  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
    if (sessionStorage.getItem("userData")) {
      history.push(route.homepage);
    }
    if (success) {
      // history.push({ pathname: route.verif, state: { phoneNumber } });
      history.push({ pathname: route.homepage, state: { phoneNumber } });
      // dispatch({ type: GET_CODE_RESET });
    }
  }, [dispatch, error, success]);

  const onSubmit = () => {
    const errors = valid();
    setError(errors);

    if (Object.keys(errors).length === 0) {
      // dispatch(getCode(phoneNumber));
      sessionStorage.setItem("userData", JSON.stringify({phoneNumber}));
      history.push({ pathname: route.faceapi, state: { phoneNumber } });
    }
  };

  const valid = () => {
    const errors = {};
    if (!phoneNumber) errors.phoneNumber = "Telefon Numarası Gereklidir";
    return errors;
  };

  const speech = (text) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);
    const startFunction = () => {
      // document.querySelector("html").style.pointerEvents = "none"
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.add("btn");
      }
      setLoop(false);
    };
    const stopFunction = () => {
      document.querySelector("html").style.pointerEvents = "unset";
      //chatbotIsık
      if (document.getElementById("chatBot")) {
        document.getElementById("chatBot").classList.remove("btn");
      }
    };
    document.querySelector("html").ontouchcancel = () => stopFunction();
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      window.removeEventListener("start", startFunction);
      window.removeEventListener("end", stopFunction);
    };
  };

  const err = { ...errors };
  return (
    <Box maxW="80%" margin="auto" width="100%">
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "none", sm: "none", lg: "flex" }}>
        <Image width="40%" height="60%" alt="img" src={banner} />
      </Box>

      {/* Mobile */}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none" }}>
        <Image width="50%" height="80%" alt="img" src={banner} />
      </Box>
      {/* Mobile */}

      <Box
        mt={{ base: "10px", sm: "15px", lg: "38px" }}
        d="flex"
        justifyContent="center"
        textAlign="center">
        <Text
          fontSize={{ base: "25px", sm: "30px", lg: "50px" ,xl:"75px","2xl":"5px"}}
          color={colors.text}>
          DUELDITION VER DİZİDE YER AL
        </Text>
      </Box>
      <Box d="flex" justifyContent="center">
        <Box height="180px" display={{ base: "none", sm: "none", lg: "block" }}>
          <Box
            maxW="500px"
            d="flex"
            position="relative"
            alignItems="center"
            top="110px"
            left="160px">
            {/* <Image alt="img" src={trflag} /> */}
            <Text ml="35px" color={colors.text} fontSize="15px">
              Telefon Numarası:
            </Text>
            {/* <Input onChange={(x) => setPhoneNumber(x.target.value)} maxW="300px" type="number" border="none" focusBorderColor="none" placeholder="Örn: 05xx xx xx xx" color={colors.text} /> */}
            <PhoneInput
              style={{
                color: "black",
                height: "45px",
                background: "transparent",
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
              name="profil_number"
              country={"tr"}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </Box>
          <Image maxW="1000px" alt="img" src={phoneComp} />
          {err.phoneNumber && (
            <Text
              fontSize="14px"
              maxW="400px"
              pos="relative"
              left="375px"
              bottom="95px"
              color="red">
              {err.phoneNumber}
            </Text>
          )}
        </Box>

        {/* Mobile */}
        <Box
          height={{ base: "120px", sm: "120px", lg: "180px" }}
          display={{ base: "flex", sm: "flex", lg: "none" }}
          flexDirection="column">
          <Box
            d="flex"
            position="relative"
            alignItems="center"
            top="60px"
            left="20px"
            maxW="400px">
            {/* <Image alt="img" src={trflag} w={{ base: "24px", sm: "24px", lg: "100%" }} mr={{ base: "10px", sm: "10px", lg: "0px" }} /> */}
            <Text
              ml="5px"
              color={colors.text}
              fontSize={{ base: "9px", sm: "9px", lg: "15px" }}>
              Telefon Numarası:
            </Text>
            {/* <Input fontSize="9px" onChange={(x) => setPhoneNumber(x.target.value)} maxW="300px" type="number" border="none" focusBorderColor="none" placeholder="Örn: 05xx xx xx xx" color={colors.text} /> */}
            <PhoneInput
              style={{
                color: "black",
                height: "45px",
                background: "transparent",
              }}
              name="profil_number"
              country={"tr"}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </Box>
          <Image maxH="71px" alt="img" src={boxComp} />
          {err.phoneNumber && (
            <Text
              fontSize={{ base: "6px", sm: "10px" }}
              maxW={{ base: "140px", sm: "250px" }}
              pos="relative"
              left="150px"
              bottom="20px"
              color="red">
              {err.phoneNumber}
            </Text>
          )}
        </Box>
        {/* Mobile */}
      </Box>
      <Box mt="10px" d="flex" justifyContent="center">
        <Box
          mr="10px"
          display={{ base: "none", sm: "none", lg: "block" }}
          className="shadow">
          <Text
            onClick={onSubmit}
            cursor="pointer"
            fontSize={font.btnFont}
            position="relative"
            left="50px"
            top="60px"
            color={colors.text}>
            GİRİŞ YAP
          </Text>
          <Image onClick={onSubmit} cursor="pointer" alt="img" src={btnimage} />
        </Box>
        <Box
          ml="10px"
          display={{ base: "none", sm: "none", lg: "block" }}
          className="shadow">
          <a href="https://www.theargeofeverything.com" target="_blank">
            <Text
              cursor="pointer"
              fontSize={font.btnFont}
              position="relative"
              left="35px"
              top="60px"
              color={colors.text}>
              ÇOCUKLUĞUM
            </Text>
            <Image cursor="pointer" alt="img" src={btnimage} />
          </a>
        </Box>

        {/* Mobile */}
        <Box
          display={{ base: "flex", sm: "flex", lg: "none" }}
          flexDirection="column">
          <Box className="shadow" maxH={{ base: "100px", sm: "100px" }}>
            <Text
              onClick={onSubmit}
              cursor="pointer"
              fontSize={{ base: "14px", md: "14px", lg: "16px" }}
              textShadow={colors.textShadow}
              position="relative"
              left={{ base: "80px", md: "80px" }}
              top={{ base: "85px", md: "86px" }}
              maxW="200px"
              color={colors.text}>
              GİRİŞ YAP
            </Text>
            <Image
              onClick={onSubmit}
              cursor="pointer"
              alt="img"
              src={mobilBtn}
            />
          </Box>
          <Box className="shadow">
            <a href="https://www.theargeofeverything.com" target="_blank">
              <Text
                cursor="pointer"
                fontSize={{ base: "14px", md: "14px", lg: "16px" }}
                textShadow={colors.textShadow}
                position="relative"
                left={{ base: "65px", md: "80px" }}
                top={{ base: "85px", md: "86px" }}
                maxW="200px"
                color={colors.text}>
                ÇOCUKLUĞUM
              </Text>
              <Image cursor="pointer" alt="img" src={mobilBtn} />
            </a>
          </Box>
        </Box>
        {/* Mobile */}
      </Box>
    </Box>
  );
};
export default Signin;
