import React, {
  useState,
  useEffect,
} from "react";
import logo from "../assets/images/logo.png";
import {
  Text,
  Box,
  Image,
  Input,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import { useDispatch, useSelector } from "react-redux";
import ButtonRightIcon from "../components/buttonRighticon";
import { useAlert } from "react-alert";
import { Field, Form, Formik } from "formik";
import {
  getCountryList,
  getProvinceList,
  getDistrictList,
  saveUserCharacter,
  saveUserCharacterLauncher,
} from "../actions/generalAction";
import Select from "react-select";

const validateEmail = (value) => {
  let error;
  if (!value) {
    error = "Lütfen e-posta adresinizi giriniz";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Lütfen geçerli bir e-posta adresi giriniz";
  }
  return error;
};

function validateName(value) {
  let error;
  if (!value) {
    error = "Lütfen İsminizi giriniz!!!";
  }
  return error;
}

function validateSurname(value) {
  let error;
  if (!value) {
    error = "Lütfen Soyisminizi giriniz!!!";
  }
  return error;
}

function validateRequired(value) {
  let error;
  if (!value) {
    error = "Lütfen Seçim yapınız!!!";
  }
  return error;
}

const validatePassword = (value) => {
  let error;
  if (!value) {
    error = "Lütfen şifrenizi belirleyiniz";
  } else if (value.length < 8) {
    error = "Şifrenizin en az 8 karakter içermesi gerekmektedir";
  }
  return error;
};

const UserRecord = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const userPhone = useSelector((state) => state.userPhone);
  const duelVideo = useSelector((state) => state.duelVideoDetail);
  const countryList = useSelector((state) => state.countryList);
  const provinceList = useSelector((state) => state.provinceList);
  const districtList = useSelector((state) => state.districtList);

  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  const recordUser = (values) => {
    const fd = new FormData();
    fd.append("name", values.name);
    fd.append("surname", values.surname);
    fd.append("email", values.email);
    fd.append("phone", values.phone.substring(1));
    fd.append("password", values.password);

    saveUserCharacterLauncher(fd).then((res) => {
      if (res.status == 200) {
        const fd2 = new FormData();
        fd2.append("name", values.name);
        fd2.append("surname", values.surname);
        fd2.append("birthdate", values.birthdate);
        fd2.append("email", values.email);
        fd2.append("phone", values.phone);
        fd2.append("password", values.password);
        fd2.append("country", values.country.value);
        fd2.append("province", values.province.value);
        fd2.append("district", values.district.value);
        fd2.append("description", values.description);
        if (
          duelVideo &&
          duelVideo.video &&
          Object.keys(duelVideo.video).length > 0
        ) {
          fd2.append("video_id", duelVideo.video.video_guid);
          fd2.append("library_id", duelVideo.video.video_libraryId);
        } else {
          alert.error("Üzgünüz deneme çekiminiz kaydedilmedi...");
        }
        saveUserCharacter(fd2).then((response) => {
          if (response.status == 200) {
            document.getElementById("redirectLink").click()
          }else{
            setSubmitBtnStatus(false);
            Object.keys(response.data.Message).forEach(function (key, index) {
              alert.error(`${key} : ${response.data.Message[key]}`);
            });
          }
        });
      } else {
        setSubmitBtnStatus(false);
        alert.error(`${res.data.Message}`);
      }
    });
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      // margin: 5,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "transparent",
      borderColor: "white",
      color: "white",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      color: "white",
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      color: "white",
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      color: "white",
      height: 45,
    }),
    placeholder: (styles) => ({ ...styles, color: "white" }),
    singleValue: (styles, { data }) => ({ ...styles, color: "white" }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <Box>
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex" }}
      >
        <Image maxW="125px" alt="img" src={logo} />
      </Box>
      <a id="redirectLink" href='https://launcher.theargeofeverything.com/user/login?duelditionSubmited' ></a>
      <Box maxW="80%" margin="auto" width="100%">
        <Text
          mb="10px"
          fontSize={{ base: "16px", sm: "16px", lg: "37px" }}
          color={colors.text}
          textAlign="center"
        >
          Kaydını Oluştur ve Karakterini Geliştirmeye Başla..
        </Text>
      </Box>
      <Box maxW="50%" margin="auto" width="50%">
        <Formik
          initialValues={{
            name: "",
            surname: "",
            birthdate: new Date(),
            email: "",
            password: "",
            phone:
              userPhone && userPhone.number && userPhone.number.substring(1),
            description: "",
            country: "",
            province: "",
            district: "",
          }}
          onSubmit={(values, actions) => {
            recordUser(values);
          }}
        >
          {(props) => (
            <Form>
              <Field name="name" validate={validateName}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.name && form.touched.name}
                  >
                    <FormLabel color="white" mb={0}>
                      İSMİNİZ
                    </FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Lütfen isminizi giriniz"
                      style={{ color: "white" }}
                    />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="surname" validate={validateSurname}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.surname && form.touched.surname}
                  >
                    <FormLabel color="white" mb={0}>
                      SOYİSMİNİZ
                    </FormLabel>
                    <Input
                      {...field}
                      type="text"
                      placeholder="Lütfen soyisminizi giriniz"
                      style={{ color: "white" }}
                    />
                    <FormErrorMessage>{form.errors.surname}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="birthdate" validate={validateRequired}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.birthdate && form.touched.birthdate}
                  >
                    <FormLabel color="white" mb={0}>
                      DOĞUM TARİHİNİZ
                    </FormLabel>
                    <Input
                      {...field}
                      type="date"
                      placeholder="Lütfen doğum tarihinizi giriniz"
                      style={{ color: "white" }}
                      timezone="[[timezone]]"
                    />
                    <FormErrorMessage>{form.errors.birthdate}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="phone" validate={validateSurname}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.phone && form.touched.phone}
                  >
                    <FormLabel color="white" mb={0}>
                      TELEFON NUMARANIZ
                    </FormLabel>
                    <Input
                      {...field}
                      disabled
                      placeholder="Lütfen telefon numaranızı giriniz"
                      style={{ color: "white" }}
                    />
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email" validate={validateEmail}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel color="white" mb={0}>
                      E-POSTA ADRESİNİZ
                    </FormLabel>
                    <Input
                      {...field}
                      type="email"
                      placeholder="Lütfen e-posta adresinizi giriniz"
                      style={{ color: "white", textTransform: "lowercase" }}
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="country" validate={validateRequired}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.country && form.touched.country}
                  >
                    <FormLabel color="white" mb={0}>
                      ÜLKE
                    </FormLabel>
                    <Select
                      className=""
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Lütfen ülkenizi belirtiniz"
                      name="country"
                      options={
                        countryList &&
                        countryList.countries &&
                        countryList.countries.Attr &&
                        countryList.countries.Attr.map((i) => ({
                          label: i.name,
                          value: i.id,
                        }))
                      }
                      value={form.values.country}
                      onChange={(e) => {
                        if (e && e.value) {
                          dispatch(getProvinceList(e.value));
                        }
                        form.setFieldValue("country", e);
                        form.setFieldValue("province", "");
                        form.setFieldValue("district", "");
                      }}
                      styles={customStyles}
                    />
                    <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="province" validate={validateRequired}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.province && form.touched.province}
                  >
                    <FormLabel color="white" mb={0}>
                      İL
                    </FormLabel>
                    <Select
                      className=""
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Lütfen ilinizi seçiniz"
                      name="province"
                      options={
                        provinceList &&
                        provinceList.provinces &&
                        provinceList.provinces.Attr &&
                        provinceList.provinces.Attr.map((i) => ({
                          label: i.name,
                          value: i.id,
                        }))
                      }
                      value={form.values.province}
                      onChange={(e) => {
                        if (e && e.value) {
                          dispatch(getDistrictList(e.value));
                        }
                        form.setFieldValue("province", e);
                        form.setFieldValue("district", "");
                      }}
                      styles={customStyles}
                    />
                    <FormErrorMessage>{form.errors.province}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="district" validate={validateRequired}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.district && form.touched.district}
                  >
                    <FormLabel color="white" mb={0}>
                      İL
                    </FormLabel>
                    <Select
                      className=""
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Lütfen ilçenizi seçiniz"
                      name="district"
                      options={
                        districtList &&
                        districtList.districts &&
                        districtList.districts.Attr &&
                        districtList.districts.Attr.map((i) => ({
                          label: i.name,
                          value: i.id,
                        }))
                      }
                      value={form.values.district}
                      onChange={(e) => {
                        form.setFieldValue("district", e);
                      }}
                      styles={customStyles}
                    />
                    <FormErrorMessage>{form.errors.district}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="password" validate={validatePassword}>
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={form.errors.password && form.touched.password}
                  >
                    <FormLabel color="white" mb={0}>
                      ŞİFRE
                    </FormLabel>
                    <Input
                      {...field}
                      type="password"
                      placeholder="Lütfen şifrenizi giriniz"
                      style={{ color: "white" }}
                    />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="description">
                {({ field, form }) => (
                  <FormControl
                    style={{ marginTop: "10px" }}
                    isInvalid={
                      form.errors.description && form.touched.description
                    }
                  >
                    <FormLabel color="white" mb={0}>
                      NEDEN OYUNCU OLMAK İSTİYORSUN?
                    </FormLabel>
                    <Textarea
                      {...field}
                      placeholder="Lütfen neden oyuncu olmak istediğini kısaca açıkla"
                      style={{ color: "white" }}
                    />
                    <FormErrorMessage>
                      {form.errors.description}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  isLoading={props.isSubmitting && submitBtnStatus}
                  type="submit"
                  _hover={{ bg: colors.hover }}
                  mr={{ base: "unset", lg: "20px" }}
                  mb={{ base: "10px", lg: "unset" }}
                  mt={5}
                  borderRadius="20px"
                  color={colors.text}
                  backgroundColor="#121518"
                  fontSize={{ base: "10px", lg: "17px" }}
                  textShadow="1px 2px 20px #01B6A2"
                  rightIcon={<ButtonRightIcon />}
                  onClick={() => setSubmitBtnStatus(true)}
                >
                  KAYDET
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default UserRecord;
