import React from "react";


import {
  Box,
  Flex,
  AspectRatio,
  Image,
  Text,
  Link,
  Button,
  Stack,
  border,

} from "@chakra-ui/react";

function Card(props) {
  const { data } = props;

  console.log('nedir data', data)

  return (
    <Box
      bgGradient="112A2D"
      _hover={{ bg: "#0F3D3E", boxShadow: " rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;" }}
      p={4}
      display={{ md: "grid" }}
      // maxWidth="20rem"
      // width="200px"
      // height="100%"
      borderWidth={1}
      margin={0}
      // padding={2}
      style={{ borderRadius: "5%", boxShadow: "12px 12px 2px 1px rgba(0, 0, 0, 0.5)", border: " solid #377D71", paddingLeft:"10px", paddingRight:"10px"}}

    >
      <Text
        textAlign={{ base: "left", md: "left" }}
        fontWeight="bold"
        textTransform="uppercase"
        // fontSize="lg"
        fontSize="10px"
        letterSpacing="wide"
        color="white"
      >
        {data.name}
      </Text>


      {/* 
 <Text
         textAlign={{ base: "center", md: "center" }}
          fontWeight="bold"
          textTransform="uppercase"
          // fontSize="lg"
          fontSize="10px"
          letterSpacing="wide"
          color="white"
        >
          Oyuncu adı
          {product}
        </Text> */}


      <div
        // bgGradient="linear(to-l,#010d03,#071202)"

        borderWidth={1}

        style={{ borderRadius: "5%", border: " solid #377D71", }}
      >

        <AspectRatio ratio={1 / 1} style={{ height: "100%" }}>
          <Image
            //  maxWidth="200px"
            margin="auto"
            // width="70%"
            // height="70px"
            src={data.image_url}
            alt="gamer card "
            style={{ width: "100%", height: "100%", padding: 0 }}
            padding={1}
          //  mt={1}
          />
        </AspectRatio>
      </div>

      <Stack
        align={{ base: "left", md: "left" }}
        textAlign={{ base: "left", md: "left", sm: "left" }}
        mt={3}
      >
        <Text my={-1} color="white"
          fontSize="10px"
        >
          Toplam Video: {data.video_count}
        </Text>
        <Text my={1} color="white" fontSize="10px">
          Toplam Kategori: {data.cat_count}
        </Text>
        <Button maxWidth="100%" my={2}
          bgGradient="linear(to-l,#377D71,#00FFF6)"
          _hover={{ bg: "#377D71", boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px" }}
          fontSize="10px"
          onClick={() => props.setSelActor(data.id)}
        >
          Düello Yap
        </Button>
      </Stack>
    </Box>
  );
}

export default Card;
