import axioss from "axios";
import { MANAGE_API, LAUNCHER_API, GEN_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAIL,
  GET_PROVINCE_REQUEST,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAIL,
  GET_DISTRICT_REQUEST,
  GET_DISTRICT_SUCCESS,
  GET_DISTRICT_FAIL,
  GET_QRURL_FAIL
} from "../constants/generalContants";

const axiosManage = axioss.create({
  baseURL: MANAGE_API,
});

const axiosGen = axioss.create({
  baseURL: GEN_API,
});

const axiosLauncher = axioss.create({
  baseURL: LAUNCHER_API,
});

const config = {
  headers: {
    Authorization:
      "Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2Mjc2YjI4MDkzYjhlOTMxNDE0YmQyNTciLCJzdWIiOiIrOTA1MzI2OTQ0NjkzIiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2NjAzNzc1OTksImV4cCI6MTY5MTkzNDUyNX0.54UtXG0vtivFhI-DePRTrsgdfyU6DktXPSKfgaqI_k4",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const getCountryList = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRY_REQUEST });

    const { data } = await axiosManage.get("/countries", config);
    if (data.Attr) {
      dispatch({
        type: GET_COUNTRY_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_COUNTRY_FAIL,
        payload: data.Message,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COUNTRY_FAIL,
      payload: error,
    });
  }
};

export const getProvinceList = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_PROVINCE_REQUEST });

    const { data } = await axiosManage.get(`/provinces/${id}`, config);
    if (data.Attr) {
      dispatch({
        type: GET_PROVINCE_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_PROVINCE_FAIL,
        payload: data.Message,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_PROVINCE_FAIL,
      payload: error,
    });
  }
};

export const getDistrictList = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DISTRICT_REQUEST });

    const { data } = await axiosManage.get(`/districts/${id}`, config);
    if (data.Attr) {
      dispatch({
        type: GET_DISTRICT_SUCCESS,
        payload: data,
      });
    } else {
      dispatch({
        type: GET_DISTRICT_FAIL,
        payload: data.Message,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_DISTRICT_FAIL,
      payload: error,
    });
  }
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
      type: CLEAR_ERRORS
  })
}

export const saveUserCharacter = async (fd) => {
  let res = await axiosManage.post('/user-register', fd, config )
    .then((response) => response)
    .catch((error) => error.response);
  return res;
};
export const saveUserCharacterLauncher = async (fd) => {
  let res = await axiosLauncher.post('/register', fd)
    .then((response) => response)
    .catch((error) => error.response);
  return res;
};


export const getQrcodeURL = async (fd) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI2Mjc2YjI4MDkzYjhlOTMxNDE0YmQyNTciLCJzdWIiOiIrOTA1MzI2OTQ0NjkzIiwicm9sZXMiOiJST0xFX1VTRVIiLCJpYXQiOjE2NjAzNzc1OTksImV4cCI6MTY5MTkzNDUyNX0.54UtXG0vtivFhI-DePRTrsgdfyU6DktXPSKfgaqI_k4",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    const data = await axiosGen
      .get(`/get-code`, config)
      .then((response) => response)
      .catch((error) => error.response.data);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
