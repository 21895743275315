import { ChakraProvider, theme } from "@chakra-ui/react";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Dueldition from "./pages/Dueldition";
import Homepage from "./pages/Homepage";
import Signin from "./pages/Signin";
import Verification from "./pages/Verification";
import Ornekdeneme from "./pages/Ornekdeneme";
import Faceapi from "./pages/Faceapi";
import Mentor from "./pages/Mentor";
import Select from "./pages/Select";
import RequestForm from "./pages/RequestForm";
import { Text, Box, Image } from "@chakra-ui/react";
import VoiceAssisttant from "./components/Voiceassistant";
import route from "./constants/route";
import ProtectedRoute from "./ProtectedRoute";
import Progress from "./pages/Progress";
import Footericon from "./components/footerIcon";
import UserRecord from "./pages/UserRecord";
import BrandSelect from "./pages/BrandSelect";
import ActorSelect from "./pages/ActorSelect";
import ArtiztSelect from "./pages/ArtiztSelect";
import Select2 from "./pages/Select2";
import AdvertSelect from "./pages/AdvertSelect";
const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" component={Signin} exact />
          <ProtectedRoute path={route.denemecekimi} component={Dueldition} exact />
          <ProtectedRoute path={route.homepage} component={Homepage} exact />
          <ProtectedRoute path={route.brands} component={AdvertSelect} exact />
          <ProtectedRoute path={route.actors} component={ActorSelect} exact />
          <ProtectedRoute path={route.artizts} component={ArtiztSelect} exact />
          <Route path={route.signIn} component={Signin} exact />
          <Route path={route.verif} component={Verification} exact />
          <ProtectedRoute
            path={route.ornekdeneme}
            component={Ornekdeneme}
            exact
          />
          <ProtectedRoute
            path={route.requestForm}
            component={RequestForm}
            exact
          />
          <ProtectedRoute path={route.faceapi} component={Faceapi} exact />
          <ProtectedRoute path={route.select} component={Select} exact />
          <ProtectedRoute path={route.select2} component={Select2} exact />
          <ProtectedRoute path={route.mentor} component={Mentor} exact />
          <ProtectedRoute path={route.userRecord} component={UserRecord} exact />
          <ProtectedRoute path={route.progress} component={Progress} exact />
        </Switch>
      </Router>
      <Box>
        <VoiceAssisttant />
      </Box>

      <Footericon d="flex" pt={{ base: "150px", sm: "150px", lg: "150px" }} w={{ base: "144px", sm: "144px", lg: "100%" }} margin="auto" />
    </ChakraProvider>
  );
};

export default App;
