import axioss from "axios";
import { PANEL_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_ARTIZT_LIST_REQUEST,
  GET_ARTIZT_LIST_SUCCESS,
  GET_ARTIZT_LIST_FAIL,
  SET_SELECTED_ARTIZT,
  GET_ARTIZT_CATEGORY_REQUEST,
  GET_ARTIZT_CATEGORY_SUCCESS,
  GET_ARTIZT_CATEGORY_FAIL,
} from "../constants/artiztContants";

const axios = axioss.create({
  baseURL: PANEL_API,
});

export const getArtiztList = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    dispatch({ type: GET_ARTIZT_LIST_REQUEST });

    const { data } = await axios.get(`/artizt-list`, config);
    dispatch({
      type: GET_ARTIZT_LIST_SUCCESS,
      payload: data && data.Attr ? data.Attr : [],
    });
  } catch (error) {
    dispatch({
      type: GET_ARTIZT_LIST_FAIL,
      payload: error,
    });
  }
};

export const getArtiztCategoryList = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    dispatch({ type: GET_ARTIZT_CATEGORY_REQUEST });

    const { data } = await axios.get(`/artizt-categories/${id}`, config);
    dispatch({
      type: GET_ARTIZT_CATEGORY_SUCCESS,
      payload: data && data.Attr ? data.Attr : [],
    });
  } catch (error) {
    dispatch({
      type: GET_ARTIZT_CATEGORY_FAIL,
      payload: error,
    });
  }
};

export const setSelectedArtizt = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ARTIZT,
    payload: data,
  });
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
