import axioss from "axios";
import { API_BASE } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_MENTOR_FAIL,
  GET_MENTOR_REQUEST,
  GET_MENTOR_SUCCESS,
} from "../constants/mentorContants";

const axios = axioss.create({
  // withCredentials: true,
  baseURL: API_BASE,
});

export const getMentors =
  (curentPage = 0, idToken) =>
  async (dispatch) => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${idToken}` },
      };
      dispatch({ type: GET_MENTOR_REQUEST });

      const { data } = await axios.get(`/mentors?page=${curentPage}`, config);

      dispatch({
        type: GET_MENTOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_MENTOR_FAIL,
        payload: error,
      });
    }
  };


// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
