import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERRORS,
  GET_CODE_REQUEST,
  GET_CODE_SUCCESS,
  GET_CODE_FAIL,
  GET_CODE_RESET,
  SET_USER_PHONE,
  SAVE_USER_ERR
} from "../constants/userContants";

const initialState = {
  fetch: null,
  user: {},
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case GET_CODE_REQUEST:
    case LOAD_USER_REQUEST:
      return {
        loading: true,
      };

    case LOGIN_SUCCESS:
    case LOAD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        success: true,
      };

    case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
      };

    case GET_CODE_SUCCESS:
      return {
        loading: false,
        user: null,
        success: true,
        code: action.payload,
      };
    case GET_CODE_RESET:
      return {
        ...state,
        success: false,
      };
    case LOAD_USER_FAIL:
      return {
        loading: false,
        user: null,
        error: action.payload,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case SAVE_USER_ERR:
      return {
        ...state,
        error: action.payload,
      };

    case LOGIN_FAIL:
    case GET_CODE_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


const initialPhoneNumber = {
  number: '',
};
export const userPhoneNumberReducer = (
  state = initialPhoneNumber,
  action
) => {
  switch (action.type) {
    case SET_USER_PHONE:
      return {
        ...state,
        loading: false,
        number: action.payload,
      };

    default:
      return state;
  }
};

