import React from "react";


import {
  Box,
  AspectRatio,
  Image,
  Text,
  Button,
  Stack,

} from "@chakra-ui/react";

function Card(props) {
  const { data } = props;
  return (
    <Box
      bgGradient="112A2D"
      _hover={{ bg: "#0F3D3E", boxShadow: " rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;" }}
      p={4}
      display={{ md: "grid" }}
      borderWidth={1}
      margin={0}
      style={{ borderRadius: "5%", boxShadow: "12px 12px 2px 1px rgba(0, 0, 0, 0.5)", border: " solid #377D71", paddingLeft:"10px", paddingRight:"10px"}}
    >
      <Text
        textAlign={{ base: "left", md: "left" }}
        fontWeight="bold"
        textTransform="uppercase"
        fontSize="10px"
        letterSpacing="wide"
        color="white"
      >
        {data.title}
      </Text>
      <div
        borderWidth={1}
        style={{ borderRadius: "5%", border: " solid #377D71", }}
      >
        <AspectRatio ratio={1 / 1} style={{ height: "100%" }}>
          <Image
            margin="auto"
            src={data.image_url}
            alt="gamer card "
            style={{ width: "100%", height: "100%", padding: 0 }}
            padding={1}
          />
        </AspectRatio>
      </div>

      <Stack
        align={{ base: "left", md: "left" }}
        textAlign={{ base: "left", md: "left", sm: "left" }}
        mt={3}
      >
        <Button maxWidth="100%" my={2}
          bgGradient="linear(to-l,#377D71,#00FFF6)"
          _hover={{ bg: "#377D71", boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px" }}
          fontSize="10px"
          onClick={() => props.selectAuditions(data.video_id)}
        >
          {`${data.actor_name} ile Düello Yap`}
        </Button>

        
      </Stack>
    </Box>
  );
}

export default Card;
