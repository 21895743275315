export const API_BASE = "https://api.cinegameart.online"
export const CHAT_API = "https://vc.cinegameart.online"
export const VIDEO_API = "https://video.bunnycdn.com/library/19535/videos"
// export const PANEL_API =  "https://admin.enigmaturkey.com/api/v1"
export const PANEL_API =  "https://manage.cinegameart.online/api/user"
// export const PANEL_API = "http://127.0.0.1:8000/api/user"
export const BUNNY_BASE = "https://video.bunnycdn.com/library/50950"
export const BUNNY_KEY = "16012a45-0e02-4570-b99ffc109ddd-d707-4f4b"
export const BUNNY_KEY2 = "9594f4bc-363d-409a-a7ff3452ca6f-fc73-4ba0"

export const INSTAGRAM = "https://www.instagram.com/aienigmaturkey/"
export const YOUTUBE = "https://www.youtube.com/channel/UC4wVwFViUyAOam-khfBrm2g"
export const MAIL = "mailto:contact@enigmaturkey.com"
export const LOCATION = "https://goo.gl/maps/Ed4mMNJcNYVXRFmQ9"

export const MANAGE_API = "https://manage.cinegameart.online/api/admin"
export const GEN_API = "https://manage.cinegameart.online/api/user"
// export const MANAGE_API = "http://127.0.0.1:8000/api/admin"
// export const GEN_API = "http://127.0.0.1:8000/api/user"

export const LAUNCHER_API = "https://launcher.theargeofeverything.com/index.php/api"


export const advertId = "bcc4ad6b-6087-4fa4-b26c-64016199ae17"
// export const advertId = "e2122cb7-8fa8-48d9-84a5-ee39c00fc315"

export const artiztId = "58ed5666-e63e-46f9-9972-18df3af06f65"