export const GET_VIDEOS_REQUEST = 'GET_VIDEOS_REQUEST'
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS'
export const GET_VIDEOS_RESET = 'GET_VIDEOS_RESET'
export const GET_VIDEOS_FAIL = 'GET_VIDEOS_FAIL'

export const GET_VIDEO_DETAIL_REQUEST = 'GET_VIDEO_DETAIL_REQUEST'
export const GET_VIDEO_DETAIL_SUCCESS = 'GET_VIDEO_DETAIL_SUCCESS'
export const GET_VIDEO_DETAIL_FAIL = 'GET_VIDEO_DETAIL_FAIL'

export const POST_VIDEOS_REQUEST = 'POST_VIDEOS_REQUEST'
export const POST_VIDEOS_SUCCESS = 'POST_VIDEOS_SUCCESS'
export const POST_VIDEOS_RESET = 'POST_VIDEOS_RESET'
export const POST_VIDEOS_FAIL = 'POST_VIDEOS_FAIL'

export const SET_BANNER_STATUS = 'SET_INITIAL_BANNER_STATUS'

export const SET_DUEL_VIDEOS = 'SET_DUEL_VIDEOS_DATA'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'