import React, {
  useState,
  useEffect,
} from "react";
import btnimage from "../assets/images/btn.png";
import camBox2 from "../assets/images/camBox2.png";
import logo from "../assets/images/logo.png";
import mobilBtn from "../assets/mobilimages/mobilBtn.png";
import { Text, Box, Image } from "@chakra-ui/react";
import colors from "../theme/colors";
import font from "../theme/font";
import ReactHlsPlayer from "react-hls-player";

const DetectModal = ({ detectStatus, setDetectStatus }) => {

  const [btnStatus, setBtnStatus] = useState(false)

  const detectVideo = () => {
    setTimeout(() => {
      setBtnStatus(true);
    }, Math.floor(37) * 1000);
  };

  useEffect(() => {
    if(detectStatus){
      let video = document.getElementById('videoPlayer');
      let video2 = document.getElementById('videoPlayer2');
      sessionStorage.setItem("listenText", false);
      if(video){
        video.autoplay = true;
        video.play()
        detectVideo();
      }else if(video2){
        video2.autoplay = true;
        video2.play()
        detectVideo();
      }
    }
  }, [detectStatus])


  return (
    <Box>
      {/* {totorialModal()} */}
      <Box pt="50px" display={{ base: "block", sm: "block", lg: "block" }}>
        <Text mb="10px" fontSize={{ base:"16px", sm:"16px", lg:"37px"}} color={colors.text} textAlign="center">
          Merhaba, Kendini hazır hissettiğinde Dueldition a başlayabilirsin.
        </Text>
      </Box>

      {/* Mobile */}
      <Box
        pt="20px"
        d="flex"
        justifyContent="center"
        display={{ base: "flex", sm: "flex", lg: "none", "2xl": "none" }}
      >
        <Image maxW="125px" alt="img" src={logo} />
      </Box>
      {/* Mobile */}
      {window.innerWidth > 991 && (
        <Box display={"block"}>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box d="flex" zIndex="1" alignItems="center">
              <Box
                d="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <Box d="flex" zIndex="1" alignItems="center">
                  <Box
                    d="flex"
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                    h="440px"
                  >
                    <Box
                      d="flex"
                      flexDirection="column"
                      position="relative"
                      alignItems="center"
                      h="auto"
                      w={{ lg: "434px", xl: "434px" }}
                      top="0px"
                    >
                      <ReactHlsPlayer
                        id="videoPlayer"
                        src="https://vz-f1e685a6-9a4.b-cdn.net/614cfcc7-e9cd-487e-b823-00ee9902bcdd/playlist.m3u8"
                        style={{
                          position: "relative",
                          borderRadius: "10px",
                          maxHeight: "325px",
                        }}
                        controls={false}
                        autoPlay={false}
                      />
                    </Box>
                    <Image
                      maxH="461px"
                      w="1020px"
                      position="absolute"
                      id="camBox2"
                      alt="img"
                      zIndex="-1"
                      src={camBox2}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={btnStatus ? "" : "disabledbutton"}>
            <Box d="flex" justifyContent="center" ml="160px">
              <Box id="tryagain" d="flex" className="shadow">
                <Image
                  height="103px"
                  alt="img"
                  src={btnimage}
                  cursor="pointer"
                />
                <Text
                  w="150px"
                  fontSize={{ lg: "12", xl: font.btnFont }}
                  position="relative"
                  right={{ sm: "115px", xl: "140px" }}
                  top={{ sm: "43px", xl: "38px" }}
                  color={colors.text}
                  cursor="pointer"
                  onClick={() => {
                    setDetectStatus()
                  }}
                >
                  HAZIRIM
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {
        //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      }
      {/* Mobile */}
      {window.innerWidth < 992 && (
        <Box
          d="flex"
          background="linear-gradient(#3FC2B3, #000000 50%)"
          borderRadius="20px"
          justifyContent="center"
          display={"block"}
          w={{ sm: "100%", md: "80%" }}
          margin="auto"
        >
          <Box maxW={{ sm: "90%", md: "60%" }} margin="auto" width="100%">
            <Box
              background="black"
              borderRadius="20px"
              mt="50px"
              alignItems="center"
              d="flex"
              flexDirection="column"
            >
              <Box
                d="flex"
                maxW="80%"
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  d="flex"
                  w="300px"
                  height="205px"
                  mt="20px"
                  justifyContent="center"
                >
                  <Box
                    d="flex"
                    h="200px"
                    position="relative"
                    top={{ md: "40px", xl: "49px" }}
                  >
                    <ReactHlsPlayer
                      id="videoPlayer2"
                      src="https://vz-f1e685a6-9a4.b-cdn.net/614cfcc7-e9cd-487e-b823-00ee9902bcdd/playlist.m3u8"
                      style={{
                        position: "relative",
                        borderRadius: "10px",
                        height: "200px",
                        width: "575px",
                      }}
                      playsInline={true}
                      controls={false}
                      autoPlay={false}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={btnStatus ? "" : "disabledbutton"} mt="10px">
              <Box id="tryagain" d="flex" justifyContent="center">
                <Box>
                  <Image
                    maxW="150px"
                    alt="img"
                    src={mobilBtn}
                    cursor="pointer"
                  />
                  <Text
                    w="75px"
                    fontSize="8px"
                    position="relative"
                    top="-48px"
                    left="45px"
                    color={colors.text}
                    cursor="pointer"
                    onClick={() => setDetectStatus()}
                  >
                    HAZIRIM
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* Mobile */}
    </Box>
  );
};

export default DetectModal;
