import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  List,
  ListItem,
  Button,
  Modal,
  Text,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Checkbox,
} from "@chakra-ui/react";
import colors from "../theme/colors";
import ButtonRightIcon from "../components/buttonRighticon";
const KvkkBilgilendirme = ({ setState }) => {
  const stateUpdate = () => {
    setState();
  };

  return (
    <Modal size={"xl"} isOpen={true}>
      <ModalOverlay />
      <ModalContent
        maxW={{ base: "100%", md: "65%" }}
        backgroundColor="#112B29"
        pointerEvents="all">
        <ModalHeader color={"#fff"} textAlign="center">
          K.V.K.K BiLGİLENDİRME METNİ
        </ModalHeader>
        <ModalBody px="40px" pointerEvents="all">
          <Box
            maxH="500px"
            px="10px"
            backgroundColor="#153639"
            border="1px"
            borderColor="#01B6A2">
            <Box
              maxH="500px"
              overflow="scroll"
              px="20px"
              py="30px"
              overflowX="hidden !important">
              <Text fontSize={{ base: "12px", md: "16px" }}>
                <div>
                  <p style={{ color: "#fff" }} dir="ltr">
                    K.V.K.K. HAKKINDA BİLGİLENDİRME
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve Film Yapım
                    A. Ş., kişisel verilerinizin hukuka uygun olarak toplanması,
                    saklanması ve paylaşılmasını sağlamak ve gizliliğinizi
                    korumak amacıyla mümkün olan en üst seviyede güvenlik
                    tedbirlerini almaktadır.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Amacımız; 6698 sayılı “Kişisel Verilerin Korunması
                    Kanunu’nun 10. maddesi gereğince ve sizlerin memnuniyeti
                    doğrultusunda, kişisel verilerinizin alınma şekilleri,
                    işlenme amaçları, paylaşılan kişiler, hukuki nedenleri ve
                    haklarınız konularında sizi en şeffaf şekilde
                    bilgilendirmektir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    a) Veri Sorumlusu
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    6698 sayılı Kişisel Verilerin Korunması Kanunu (“6698 sayılı
                    Kanun”) uyarınca, kişisel verileriniz; veri sorumlusu olarak
                    Enigma Yapay Zeka Dijital Sanat Teknolojileri Ve Film Yapım
                    A. Ş. (“ŞİRKET”) tarafından aşağıda açıklanan kapsamda
                    toplanacak ve işlenebilecektir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    b) Kişisel Verilerin Hangi Amaçla İşleneceği
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    ŞİRKET tarafından, müşterileri, çalışanları, potansiyel
                    müşterileri, çalışan adayları, iş ortakları, web sitesi ve
                    mağaza ziyaretçileri ve tedarikçileri gibi taraflardan,
                    kimlik bilgisi, iletişim bilgisi, müşteri bilgisi, müşteri
                    işlem bilgisi, işlem güvenliği bilgisi, hukuki işlem ve uyum
                    bilgisi ile pazarlama satış bilgisi gibi kategorilerde
                    kişisel veri toplanabilmektedir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Toplanan kişisel verileriniz;
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - ŞİRKET ürün ve hizmetlerinin sizlere sunulabilmesi,
                    sizlere karşı olan yükümlülüklerimizin yerine getirilmesi,
                    kayıt ve belgelerin düzenlenebilmesi, yerel ve uluslararası
                    yasal mevzuatın öngördüğü bilgi saklama, raporlama,
                    bilgilendirme, vergi ve sair yükümlülüklere uymak,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Hizmet ve ürünlerin kalitesinin artırılmasına yönelik
                    yapılacak olan satış ve pazarlama faaliyetleri için
                    yapılacak size özel reklam, kampanya, avantajlar ve diğer
                    faydaları sunmak,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Bilgi işlem gereksinimleri, sistemsel yapısı, alınan bilgi
                    işlem destek hizmetlerinin gerekliliği, bu hizmet ve
                    ürünlere ilişkin olarak sizlere gerekli bilgilerin
                    aktarılması amacıyla iletişim kurmak,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Satış ve pazarlama faaliyetleri için yapılacak trafik
                    ölçümleme, istatistiki analizler, Segmentasyon/profilleme ve
                    CRM çalışmalarını yürütmek,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Müşteri memnuniyetinin ölçümlenmesi ve artırılması,
                    şikâyet yönetimi, yeni hizmet ve ürünler ile ilgili görüş ve
                    önerilerinizi almak, sorun-hata bildirimlerinizi almak, ürün
                    ve hizmetlere, şikayet ve taleplerinize yönelik tarafınıza
                    bilgi vermek,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Online satış ile ilgili üyeliğinizi yönetmek,
                    siparişlerinizi almak, ödeme işlemlerinizi gerçekleştirmek,
                    3. kişiler ile lojistik iş birliği sağlayıp ürün gönderimini
                    sağlamak, ilginizi çekebilecek ürün ve hizmetleri önermek,
                    online davranışsal reklamcılık ve pazarlama, müşteri portföy
                    yönetimi, hizmet kalitesinin ölçülmesi ve geliştirilmesi,
                    iletişim, optimizasyon, denetim, risk yönetimi ve kontrol,
                    promosyon, analiz, ilgi alanları belirleme, skorlama,
                    profilleme, pazarlama, satış, reklam, iletişim
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Karşılaştırmalı ürün ve/veya hizmet teklifi, modelleme,
                    mevcut veya yeni ürün çalışmaları ve/veya geliştirmeleri,
                    kişisel verilerinizi ŞİRKET’e açıklamanıza konu olan ŞİRKET
                    ana sözleşmesinde yazılı olan işleri düzenleyen kanun ve
                    ilgili mevzuat kapsamında sizlere sunulacak her türlü ürün
                    ve hizmetlerde kullanılmak,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Resmî kurumlarca öngörülen bilgi saklama, raporlama,
                    bilgilendirme yükümlülüklerine uymak, sözleşmelerin
                    gerekliliklerini yerine getirmek ve bu hizmetlerden
                    faydalanılmasına ilişkin olarak ŞİRKET’in tabi olduğu yasal
                    yükümlülükleri ifa etmek,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - ŞİRKET’in ticari ve iş stratejilerinin belirlenmesi ve
                    uygulanması amacı doğrultusunda; ŞİRKET tarafından yürütülen
                    finans operasyonları, iletişim, pazar araştırması ve sosyal
                    sorumluluk aktiviteleri, satın alma operasyonları (talep,
                    teklif, değerlendirme, sipariş, bütçelendirme, sözleşme),
                    şirket içi sistem ve uygulama yönetimi operasyonları, hukuki
                    operasyonları yönetmek
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Resmi makamlardan veya sizlerden gelen talepleri
                    incelemek, değerlendirmek ve yanıtlamak,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    amaçlarıyla 6698 sayılı Kanun’un 5. ve 6. maddelerinde
                    belirtilen kişisel veri işleme şartları ve amaçları
                    dahilinde işlenecektir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    c) İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla
                    Aktarılabileceği
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Toplanan kişisel verileriniz; yukarıda belirtilen amaçların
                    gerçekleştirilmesi ile sınırlı olmak üzere;
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - ŞİRKET’in iş ortaklarına, hissedarlarına, iştiraklerine,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Türk Ticaret Kanunu, Türk Borçlar Kanunu ve diğer mevzuat
                    hükümlerinin izin verdiği kişi veya kuruluşlara,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Kanunen yetkili kamu kurum ve kuruluşları, idari merciler
                    ve yasal mercilere,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    - Ürün/hizmet karşılaştırma, analiz, değerlendirme, reklam
                    ve yukarıda belirtilen amaçların gerçekleştirilmesinde
                    hizmet aldığımız, işbirliği yaptığımız gerçek veya tüzel
                    kişilere, program ortağı kurum ve kuruluşlara,
                    müşterilerimize gönderdiğimiz iletilerin gönderilmesi
                    konusunda anlaşmalı olduğumuz kurumlara, online mağazamızdan
                    verilen siparişlerin size teslimini gerçekleştiren kargo
                    şirketlerine 6698 sayılı Kanun’un 8. ve 9. maddelerinde
                    belirtilen kişisel veri işleme şartları ve amaçları
                    çerçevesinde aktarılabilecektir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    ç) Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Kişisel verileriniz, mağazalarımız, internet sitemiz ve
                    mobil uygulamamız, çağrı merkezlerimiz, sosyal medya
                    hesaplarımız gibi mecralardan sözlü, yazılı veya elektronik
                    ortamda veya ilerde kurulacak/oluşabilecek diğer kanallar
                    başta olmak üzere;
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    ŞİRKET tarafından yasal mevzuat çerçevesinde yukarıda
                    belirtilen amaçlarla ve sözleşmenin ifa edilmesi, kanundaki
                    açıkça öngörülmüş olması, kişisel verilerinizin tarafınızca
                    alenileştirilmiş olması, tarafınıza tanınacak olan hakkın
                    tesisi, kullanılması veya korunması için zorunlu olması;
                    sizin temel hak ve özgürlüklerinize zarar vermeyecek
                    şekilde, ŞİRKET’in meşru menfaatleri ve hukuki yükümlülüğünü
                    yerine getirebilmesi için zorunlu olması şeklindeki hukuki
                    sebeplerin varlığıyla sınırlı olarak toplanmaktadır.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    d) Kişisel Veri Sahibi’nin 6698 sayılı Kanun’un 11.
                    maddesinde Sayılan Hakları
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Kişisel veri sahipleri olarak, haklarınıza ilişkin
                    taleplerinizi aşağıda düzenlenen yöntemlerle ŞİRKET’e
                    iletmeniz durumunda ŞİRKET talebin niteliğine göre talebi en
                    kısa sürede ve en geç otuz gün içinde sonuçlandıracaktır.
                    Verilecek cevapta on sayfaya kadar ücret alınmayacaktır. On
                    sayfanın üzerindeki her sayfa için 1 Türk Lirası işlem
                    ücreti alınacaktır. Başvuruya cevabın CD, flash bellek gibi
                    bir kayıt ortamında verilmesi halinde ŞİRKETİMİZ tarafından
                    talep edilebilecek ücret kayıt ortamının maliyetini
                    geçmeyecektir. Bu kapsamda kişisel veri sahipleri;
                  </p>
                  <ul>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Kişisel verilerinin işlenip işlenmediğini öğrenme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Kişisel verileri işlenmişse buna ilişkin bilgi talep
                        etme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Kişisel verilerin işlenme amacını ve bunların amacına
                        uygun kullanılıp kullanılmadığını öğrenme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Yurt içinde veya yurt dışında kişisel verilerin
                        aktarıldığı üçüncü kişileri bilme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Kişisel verilerin eksik veya yanlış işlenmiş olması
                        hâlinde bunların düzeltilmesini isteme ve bu kapsamda
                        yapılan işlemin kişisel verilerin aktarıldığı üçüncü
                        kişilere bildirilmesini isteme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        6698 sayılı Kanun ve ilgili diğer kanun hükümlerine
                        uygun olarak işlenmiş olmasına rağmen, işlenmesini
                        gerektiren sebeplerin ortadan kalkması halinde kişisel
                        verilerin silinmesini veya yok edilmesini isteme ve bu
                        kapsamda yapılan işlemin kişisel verilerin aktarıldığı
                        üçüncü kişilere bildirilmesini isteme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        İşlenen verilerin münhasıran otomatik sistemler
                        vasıtasıyla analiz edilmesi suretiyle kişinin kendisi
                        aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                      </p>
                    </li>
                    <li dir="ltr">
                      <p style={{ color: "#fff" }} dir="ltr">
                        Kişisel verilerin kanuna aykırı olarak işlenmesi
                        sebebiyle zarara uğraması halinde zararın giderilmesini
                        talep etme haklarına sahiptir.
                      </p>
                    </li>
                  </ul>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Yukarıda belirtilen haklarınızı kullanma ile ilgili
                    talebinizi, 6698 sayılı Kanunu’nun 13. maddesinin 1. fıkrası
                    ve 30356 sayılı ve 10.03.2018 tarihli Veri Sorumlusuna
                    Başvuru Usul ve Esasları Hakkında Tebliğ gereğince Türkçe ve
                    yazılı olarak veya kayıtlı elektronik posta (KEP) adresi,
                    güvenli elektronik imza, mobil imza ya da ŞİRKET’e daha önce
                    bildirilen ve sistemimizde kayıtlı bulunan elektronik posta
                    adresini kullanmak suretiyle iletebilirsiniz. ŞİRKET’in
                    cevap vermeden önce kimliğinizi doğrulama hakkı saklıdır.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Başvurunuzda;
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    a) Adınızın, soyadınızın ve başvuru yazılı ise imzanızın,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    b) Türkiye Cumhuriyeti vatandaşları için T.C. kimlik
                    numaranızın, yabancı iseniz uyruğunuzun, pasaport
                    numaranızın veya varsa kimlik numaranızın,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    c) Tebligata esas yerleşim yeri veya iş yeri adresinizin,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    ç) Varsa bildirime esas elektronik posta adresi, telefon ve
                    faks numaranızın,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    d) Talep konunuzun,
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    bulunması zorunlu olup varsa konuya ilişkin bilgi ve
                    belgelerin de başvuruya eklenmesi gerekmektedir.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    E-posta yoluyla yapmak istediğiniz başvurularınızı
                    conatact@enigmaturkey.com e-posta adresine yapabilirsiniz.
                  </p>
                  <p style={{ color: "#fff" }} dir="ltr">
                    Talebinizin niteliğine göre bilgi ve belgelerin eksiksiz ve
                    doğru olarak tarafımıza sağlanması gerekmektedir. İstenilen
                    bilgi ve belgelerin gereği gibi sağlanmaması durumunda,
                    ŞİRKET tarafından talebinize istinaden yapılacak
                    araştırmaların tam ve nitelikli şekilde yürütülmesinde
                    aksaklıklar yaşanabilecektir. Bu durumda, ŞİRKET kanuni
                    haklarını saklı tuttuğunu beyan eder. Bu nedenle,
                    başvurunuzun talebinizin niteliğine göre eksiksiz ve
                    istenilen bilgileri ve belgeleri içerecek şekilde
                    gönderilmesi gerekmektedir.
                  </p>
                  <div>
                    <br />
                  </div>
                </div>
              </Text>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          flexDirection="column"
          pointerEvents="all">
          <Button
            onClick={() => {
              document.getElementsByClassName(
                "chakra-portal"
              )[0].style.display = "none";
              stateUpdate();
            }}
            _hover={{ bg: colors.hover }}
            borderRadius="20px"
            color={colors.text}
            backgroundColor="#121518"
            fontSize="17px"
            textShadow="1px 2px 20px #01B6A2"
            rightIcon={<ButtonRightIcon />}>
            Tamam
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default KvkkBilgilendirme;
