import axioss from "axios";
import { PANEL_API } from "../config/config";
import {
  CLEAR_ERRORS,
  GET_ADVERT_LIST_REQUEST,
  GET_ADVERT_LIST_SUCCESS,
  GET_ADVERT_LIST_FAIL,
  SET_SELECTED_ADVERT,
  GET_ADVERT_CATEGORY_REQUEST,
  GET_ADVERT_CATEGORY_SUCCESS,
  GET_ADVERT_CATEGORY_FAIL,
} from "../constants/advertContants";

const axios = axioss.create({
  // withCredentials: true,
  baseURL: PANEL_API,
});

export const getAdvertList = () => async (dispatch) => {
  try {
    const config = {
      headers: {
        Authorization: "Bearer 3107d09d2ce86eb5fddc8308659905e7",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*"
      },
    };

    dispatch({ type: GET_ADVERT_LIST_REQUEST });

    const { data } = await axios.get(`/advert-list`, config);
    dispatch({
      type: GET_ADVERT_LIST_SUCCESS,
      payload: data && data.Attr ? data.Attr : [],
    });
  } catch (error) {
    dispatch({
      type: GET_ADVERT_LIST_FAIL,
      payload: error,
    });
  }
};


export const setSelectedAdvert = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ADVERT,
    payload: data,
  });
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};
