import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { MdSend } from "react-icons/all";
import logo from "../assets/images/logo.png";
import boxComp from "../assets/mobilimages/boxComp.png";
import face from "../assets/images/facescan.svg";
import voiceassist from "../assets/images/Voiceasist.png";
import voiceassist2 from "../assets/images/voiceasist2.png";
import { useDispatch, useSelector } from "react-redux";
import { getMentors, clearErrors } from "../actions/mentorAction";
import { useAlert } from "react-alert";
import { POST_CHAT_RESET } from "../constants/chatContants";

import {
  Text,
  Box,
  Image,
  Input,
  // Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  // ModalFooter,
  // ModalCloseButton,
  useDisclosure,
  TabList,
  Tab,
  Tabs,
  TabPanels,
  TabPanel,
  Tooltip,
} from "@chakra-ui/react";
import colors from "../theme/colors";
// import Footericon from "../components/footerIcon";
// import font from "../theme/font";
// import route from "../constants/route";
import { postChat } from "../actions/chatAction";
const VoiceAssisttant = () => {
  // const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  // const [loop, setLoop] = useState(true);
  const [visibility, setVisibility] = useState(false);
  const [text, setText] = useState("");
  const [question, setQuestion] = useState("");
  const [selectedButton, setSelectedButton] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { chatBotText, error } = useSelector((state) => state.chatApi);

  useEffect(() => {
    if (error) {
      alert.error(error.message);
      dispatch(clearErrors());
    }
  }, [dispatch, error]);
  useEffect(() => {
    if (chatBotText && chatBotText.message && question == "nerede") {
      document.getElementById("locationLink").click();
    } else {
      chatBotText && chatBotText.message && speech(chatBotText.message);
    }
    dispatch({ type: POST_CHAT_RESET });
    return () => console.log();
  }, [chatBotText]);

  const speech = (text) => {
    const ut = new SpeechSynthesisUtterance(text);
    speechSynthesis.speak(ut);

    const startFunction = () => {
      setVisibility(true);
    };
    const stopFunction = () => {
      setVisibility(false);
    };
    ut.addEventListener("start", startFunction);
    ut.addEventListener("end", stopFunction);
    return () => {
      window.removeEventListener("start", startFunction);
      window.removeEventListener("end", stopFunction);
    };
  };

  const onSubmitText = (e, question) => {
    console.log(question + " " + text);
    if (e.key == "Enter") {
      dispatch(postChat(question + " " + text));
    }
    if (e.key == undefined) {
      dispatch(postChat(question + " " + text));
    }
  };
  const testmodal = () => {
    return (
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <a
          id="locationLink"
          target="_blank"
          href={
            chatBotText && chatBotText.message ? chatBotText.message : ""
          }></a>
        <ModalOverlay />
        <ModalContent pointerEvents="all" backgroundColor="black">
          <ModalHeader>
            <Box
              //onClick={() => speech("Hey sen! Yeteneğine güveniyorsan ve ben başarırım diyorsan, DUELDİŞINI kaçırma derim!")}
              d="flex"
              justifyContent="center"
              className={visibility ? "btn3 btn2" : "btn3 "}>
              <Image
                w={{ base: "unset", md: "unset" }}
                className=" "
                cursor="pointer"
                src={voiceassist2}
              />
            </Box>
          </ModalHeader>
          {/* <ModalCloseButton fontSize={{ base: "25px", md: "40px" }} mr="20px" mt="20px" /> */}
          <ModalBody textAlign="center" mt={{ base: "150px", md: "250px" }}>
            <Box mb="20px">
              <Text fontSize={{ base: "18px", md: "24px" }} textAlign="center">
                MERHABA BEN ENIGMA
              </Text>
            </Box>
            <Tabs
              d="flex"
              maxW="800px"
              alignItems="baseline"
              margin="auto"
              flexDirection={{ base: "column", md: "row" }}>
              <Box w={{ base: "100%", lg: "400px" }}>
                <TabList
                  style={{ overflow: "hidden" }}
                  d="flex"
                  flexDirection="column"
                  w="100%"
                  backgroundColor="#225D56"
                  borderLeftRadius={{ base: "none", md: "20px" }}
                  border="rgba(0, 255, 225, 0.4) solid 2px">
                  <Tab
                    _selected={{ bg: "#00E9CE" }}
                    borderTopLeftRadius={{ base: "none", md: "20px" }}
                    fontSize={{ base: "14px", md: "16px" }}>
                    merhaba
                  </Tab>
                  <Tab
                    _selected={{ bg: "#00E9CE" }}
                    fontSize={{ base: "14px", md: "16px" }}>
                    s.s.s
                  </Tab>
                  <Tab
                    _selected={{ bg: "#00E9CE" }}
                    fontSize={{ base: "14px", md: "16px" }}>
                    yardım
                  </Tab>
                  <a
                    href="https://www.theargeofeverything.com"
                    textAlign="center"
                    target="_blank"
                    style={{
                      marginTop: "5px",
                      textDecoration: "none",
                      outline: "none",
                      padding: "5px",
                    }}>
                    Çocukluğum
                  </a>
                  <Tab
                    onClick={() => {
                      dispatch(postChat("teşekkürler"));
                      setTimeout(() => {
                        onClose();
                      }, 2000);
                    }}
                    _selected={{ bg: "#00E9CE" }}
                    _active={{ bg: "#fff", color: "#000" }}
                    bg="#ff0000"
                    color={"#fff"}
                    fontSize={{ base: "14px", md: "16px" }}>
                    teşekkürler
                  </Tab>
                </TabList>
              </Box>
              <TabPanels backgroundColor="#225D56" borderBottomRadius="30px">
                <TabPanel>
                  <Tag
                    click={setText}
                    children2="nasıl gidiyor"
                    dispatch={dispatch}>
                    Nasıl Gidiyor?{" "}
                  </Tag>
                  <Tag click={setText} children2="efsane" dispatch={dispatch}>
                    Efsane!
                  </Tag>
                  <Tag click={setText} children2="naber" dispatch={dispatch}>
                    Naber?
                  </Tag>
                  <Tag click={setText} children2="nasılsın" dispatch={dispatch}>
                    Nasılsın?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="mükemmelsin"
                    dispatch={dispatch}>
                    Mükemmelsin
                  </Tag>
                  <Tag click={setText} children2="çok iyi" dispatch={dispatch}>
                    Çok iyisin
                  </Tag>
                </TabPanel>
                <TabPanel>
                  <Tag
                    click={setText}
                    children2="dueldition-nedir"
                    dispatch={dispatch}>
                    Dueldition nedir?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="neden-dueldition"
                    dispatch={dispatch}>
                    Neden Dueldition vermeliyim?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="hak-artirimi"
                    dispatch={dispatch}>
                    Dueldition hakkımı nasıl artırabilirim?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="enigma-kim"
                    dispatch={dispatch}>
                    Enigma kim?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="arti-nedir"
                    dispatch={dispatch}>
                    ARTI nedir?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="sonra-ne-olacak"
                    dispatch={dispatch}>
                    Dueldition'dan sonra ne olacak?
                  </Tag>
                  <Tag
                    click={setText}
                    children2="tel-ihtiyaci"
                    dispatch={dispatch}>
                    Telefon numarama neden ihtiyaç var?
                  </Tag>
                </TabPanel>
                <TabPanel p="0">
                  <Box p="4">
                    {["nedir", "kimdir", "nasıl", "nerede"].map((key) => (
                      <Tag
                        className={key === selectedButton ? "selected" : ""}
                        click={setText}
                        clickQuestion={setQuestion}
                        children2={key}
                        setSelect={setSelectedButton}>
                        {key}
                      </Tag>
                    ))}
                    {/* <Tag click={setText} children2="nedir" >Nedir</Tag>
                                        <Tag click={setText} children2="kimdir" >Kimdir</Tag>
                                        <Tag click={setText} children2="nasıl" >Nasıl</Tag>
                                        <Tag click={setText} children2="" ></Tag> */}
                  </Box>
                  <Box
                    color="black"
                    backgroundColor="white"
                    borderBottomRadius="30px"
                    d="flex">
                    <Input
                      onKeyPress={(e) => onSubmitText(e, question)}
                      onChange={(e) => setText(e.target.value)}
                      value={text}
                      borderRadius="60px"
                      fontSize="11px"
                      type="text"
                      _focus={{ outline: "none" }}
                      _placeholder={{ color: "gray" }}
                      placeholder="Enigmaya mesaj yaz..."></Input>
                    <Box
                      onClick={(e) => onSubmitText(e, question)}
                      backgroundColor={colors.secondary}
                      borderBottomRadius="30px"
                      w="30%"
                      d="flex"
                      justifyContent="end"
                      cursor="pointer">
                      <Text
                        fontSize="8px"
                        d="flex"
                        alignItems="center"
                        mr="5px">
                        Gönder
                      </Text>
                      <MdSend
                        style={{
                          fontSize: "34px",
                          marginTop: "2px",
                          marginRight: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };
  return (
    <Box>
      {testmodal()}
      <Box>
        <Tooltip
          label="SİZE NASIL YARDIMCI OLABİLİRİM?"
          width="36"
          background={"#fff"}
          color="#000"
          border={"2px"}
          borderColor="#4fd1c5"
          borderRadius="14px"
          placement="top"
          defaultIsOpen>
          <div
            id="chatBot"
            onClick={() => onOpen()}
            className={visibility ? "btn1 btn" : "btn1 "}>
            <Image
              w={{ base: "100%", md: "unset" }}
              className=" "
              cursor="pointer"
              src={voiceassist}
            />
          </div>
        </Tooltip>
      </Box>
    </Box>
    // <Box>
    //     <Popover placement="left-start" gutter={true} closeOnBlur={false}>
    //         <PopoverTrigger>
    //             <div onClick={() => setVisibility(!visibility)} className={visibility ? "btn1 btn" : "btn1 "}><Image w={{ base: "16%", md: "unset" }} className=" " cursor="pointer" src={voiceassist} position="fixed" right="50px" bottom="50px" /></div>

    //         </PopoverTrigger>
    //         <PopoverContent backgroundColor="transparent" border="none" _focus={{ outline: "none", boxShadow: "none" }}>
    //             <PopoverArrow />
    //             <Box onClick={() => setVisibility(false)}> <PopoverCloseButton /></Box>
    //             <PopoverBody>
    //                 <Box
    //                     d="flex"
    //                     background="linear-gradient(#3FC2B3, #000000 50%)"
    //                     borderRadius="20px"
    //                     borderBottomRadius="40px"
    //                     justifyContent="center"
    //                     display="flex"
    //                     flexDirection="column"
    //                 >
    //                     <Box
    //                         maxW="90%"
    //                         margin="auto"
    //                         width="100%"
    //                     >
    //                         <Box maxH="100px" height="100%">
    //                             <Box mt="10px" d="flex" flexDirection="column" alignItems="center" >
    //                                 <Image height="91px" alt="img" src={boxComp} width="600px" />
    //                                 <Image src={logo} top="-85px" left="10px" position="relative" maxW="110px" />
    //                             </Box>
    //                         </Box>
    //                         <Box background="black" borderRadius="20px" alignItems="center" d="flex" flexDirection="column">
    //                             <Box d="flex" justifyContent="center" mt="10px">
    //                                 <Image src={face} w="60px" p="12px" borderRadius="50%" backgroundColor={colors.secondary} />
    //                             </Box>
    //                             <Box d="flex" justifyContent="center" mt="10px" maxW="75%">
    //                                 <Box backgroundColor={colors.secondary} borderRadius="10px">
    //                                     <Text fontSize="13px" textAlign="center">MERHABA BEN ENIGMA</Text>
    //                                 </Box>
    //                             </Box>
    //                             <Box d="flex" mt="10px" maxW="90%" flexFlow="wrap">
    //                                 <Tag click={setText} children2="bugün günlerden ne" dispatch={dispatch}>Tarihi söyler misin Enigma?</Tag>
    // <Tag click={setText} children2="nasıl gidiyor" dispatch={dispatch}>Keyifler nasıl Enigma? </Tag>
    // <Tag click={setText} children2="efsane" dispatch={dispatch}>Efsane</Tag>
    // <Tag click={setText} children2="naber" dispatch={dispatch}>Naber Enigma?</Tag>
    // <Tag click={setText} children2="nasılsın" dispatch={dispatch}>Nasılsın Enigma?</Tag>
    // <Tag click={setText} children2="mükemmelsin" dispatch={dispatch}>Mükemmelsin</Tag>
    // <Tag click={setText} children2="müzik" >Müzik Çal</Tag>
    // <Tag click={setText} children2="nedir" >Nedir</Tag>
    // <Tag click={setText} children2="çok iyi" dispatch={dispatch}>Çok iyi</Tag>

    //                                 <Tag click={setText} children2="teşekkürler" dispatch={dispatch}>Teşekkürler</Tag>

    //                                 <Tag click={setText} children2="kimdir" >Kimdir</Tag>
    //                                 <Tag click={setText} children2="ne" >Ne</Tag>
    //                                 <Tag click={setText} children2="nasıl" >Nasıl</Tag>
    //                                 <Tag click={setText} children2="" ></Tag>
    //                             </Box>
    //                             <Box mt="10px" maxW="80%" h="30px">

    //                             </Box>
    //                         </Box>
    //                     </Box>
    // <Box color="black" backgroundColor="white" h="40px" w="100%" borderBottomRadius="30px" d="flex">
    //     <Input onKeyPress={(e) => onSubmitText(e)} onChange={(e) => setText(e.target.value)} value={text} borderRadius="60px" fontSize="11px" type="text" maxW="230px" _focus={{ outline: "none" }} _placeholder={{ color: "gray" }} placeholder="Enigmaya mesaj yaz..."></Input>
    //     <Box onClick={(e) => onSubmitText(e)} backgroundColor={colors.secondary} borderBottomRadius="30px" w="40%" d="flex" justifyContent="end" cursor="pointer">
    //         <Text fontSize="5px" d="flex" alignItems="center" mr="5px">Gönder</Text><MdSend style={{ fontSize: "34px", marginTop: "2px", marginRight: "5px" }} />
    //     </Box>
    // </Box>
    //                 </Box>
    //             </PopoverBody>
    //         </PopoverContent>
    //     </Popover>

    // </Box>
  );
};
// const Tag = ({ children, click, children2, dispatch, ...props }) => {
//     return (
//         <Box {...props} onClick={dispatch ? () => dispatch(postChat(children2)) : () => click(children2)} backgroundColor={colors.secondary} borderRadius="10px" p="8px" mr="5px" mt="5px" cursor="pointer" _hover={{ bg: colors.hover }} >
//             <Text fontSize="8px" textAlign="center"> {children}</Text>
//         </Box >
//     );
// };
const Tag = ({
  children,
  click,
  clickQuestion,
  setSelect,
  children2,
  dispatch,
  ...props
}) => {
  return (
    <Box
      {...props}
      onClick={
        dispatch
          ? () => dispatch(postChat(children2))
          : () => {
              clickQuestion(children2);
              setSelect(children2);
            }
      }
      backgroundColor="#00E9CE"
      borderRadius="20px"
      p="8px"
      mx="20px"
      mt="5px"
      cursor="pointer"
      _hover={{ bg: colors.hover }}>
      <Text fontSize={{ base: "10px", md: "12px" }} textAlign="center">
        {" "}
        {children}
      </Text>
    </Box>
  );
};
export default VoiceAssisttant;
